export const PathName = {
  Main: "/",
  Login: "/login",
  Logout: "/logout",
  Admin: "/admin",
  Home: "/home",
  Detail: "/detail",
  OrgChart: "/orgchart",
  Etc: "*",
};

export const ViewType = {
  Home: "Home",
  Detail: "Detail",
};

export const PathType = {
  Report: "report",
  DataManagement: "datamanagement",
  UserManagement: "usermanagement",
  Menu: "menu",
  Genealogy: "genealogy",
};

export const MenuType = {
  Report: 0,
  DataManagement: 1,
  UserManagement: 2,
  Normal: 3,
  Genealogy: 10,
};

export const UserManagementType = {
  User: "user",
  Group: "group",
};

export const MenuShowType = {
  All: 0,
  OnlyDeskTop: 1,
  OnlyMobile: 2,
};

export const ReportMenuType = {
  All: 0,
  OnlyDeskTop: 1,
  OnlyMobile: 2,
};

export const RenderType = {
  Report: 0,
  Loading: 1,
  Wrong: 2,
  Error: 3,
  OrgChart: 4,
};

export const ReportType = {
  DesktopLayout: 0,
  MobilePortraitLayout: 1,
  MobileLandscapeLayout: 2,
  AutoMobilePortraitLayout: 3,
  AutoMobileLandscapeLayout: 4,
  DesktopSize: 5,
  MobileSize: 6,
};

export const HeaderMinHeight = 40;
export const HeaderMaxHeight = 320;
export const DrawerWidth = 260;

export const ChartType = {
  Normal: 0,
  Filter: 1,
};
