import { ACTION_TYPE_DATA_MANAGEMENT_PAGE } from "../actions/type";
import produce from "immer";

const initialState = { menuData: {} };

export default function r_dataManagementPage(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPE_DATA_MANAGEMENT_PAGE:
        if (!action.status.menuData) {
          draft.menuData = {};
        } else {
          draft.menuData = action.status.menuData;
        }
        break;
      default:
        break;
    }
  });
}
