import { ACTION_TYPE_HEADER_PAGE } from "../actions/type";
import produce from "immer";

const initialState = { waitingAppData: false };

export default function r_headerPage(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPE_HEADER_PAGE:
        draft.waitingAppData = action.status.waitingAppData ? true : false;
        break;
      default:
        break;
    }
  });
}
