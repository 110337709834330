import React, { useEffect, useState, useRef } from "react";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { useIsAuthenticated } from "@azure/msal-react";

import { Grid, Typography, Button, Menu, Divider } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";

import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import SaveIcon from "@material-ui/icons/Save";

import { useTranslation } from "react-i18next";

import {
  BASIC,
  REDUCTION,
  REDUCTION_MATERIAL,
  REDUCTION_PLASTIC,
} from "../formats";

import { PanelGroup } from "../../../lib";
import { DrawerWidth, HeaderMinHeight } from "../../../define";
import { CustomTooltip, DataTable, PlasticListDlg } from "../components";
import { MessageDialog, ConfirmDialog } from "../../../components";
import { sendBackend } from "../../../utils/backend";
import { onClearTable } from "../utils/Utils";
import { getUserCheck, isEmpty, datetimeFormat } from "../../../utils/Utils";
import { TableSettings } from "../utils/TableSettings";

import moment from "moment-timezone";
import "moment/min/locales.min";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${DrawerWidth}px`,
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${DrawerWidth}px)`,
    marginLeft: 0,
  },
  searchLabel: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#565656",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    background: "#f3f3f3",
    borderRadius: 2,
    position: "relative",
    border: "1px solid #dfdfdf",
    margin: "0px",
    padding: "0px",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#00b5ef",
    "&:hover": {
      backgroundColor: "#00a0ef",
    },
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    borderRadius: "3px",
    padding: "3px 6px",
  },
}))(Button1);

const ToolbarBtn = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    margin: "0 1px 0",
    minWidth: "26px",
    maxWidth: "26px",
    minHeight: "26px",
    maxHeight: "26px",
    color: "inherit",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

let r_user;
let r_headerPage;

let hotTableRef;
let hotTableRef1;
let hotTableID = "content-hot-table";
let hotTableID1 = "content-hot-table-1";

let openProgessbar = false;

let apiCallTime;
function ReductionGrid(props) {
  const classes = useStyles();
  const searchRef = useRef(null);
  const tableRef = useRef(null);
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const basicText = t("DataManagement.basic");
  const reductionText = t("DataManagement.reduction");
  const CodeTxt = t("DataManagement.Code");
  const NameTxt = t("DataManagement.Name");
  const TypeTxt = t("DataManagement.Type");
  const materialCode = t("DataManagement.materialCode");
  const plasticType = t("DataManagement.plasticType");
  const search = t("DataManagement.search");
  const plastic_edit = t("DataManagement.plastic_type_edit");

  const undoTooltip = t("DataManagement.toolbar.undo");
  const redoTooltip = t("DataManagement.toolbar.redo");
  const saveTooltip = t("DataManagement.toolbar.save");
  const findReplaceTooltip = t("DataManagement.toolbar.find");

  const insertRowAboveText = t("DataManagement.contextMenu.Insert_row_above");
  const insertRowBelowText = t("DataManagement.contextMenu.Insert_row_below");

  const saveBT = t("DataManagement.saveBT");
  const saveRT = t("DataManagement.saveRT");
  const saveQ = t("MessageBox.saveQ");
  const searching = t("DataManagement.backdrop.searching");
  const saving = t("DataManagement.backdrop.saving");
  const unknown = t("DataManagement.unknown");

  const [materialList, setMaterialList] = useState(undefined);
  const [plasticList, setPlasticList] = useState(undefined);

  const [selectedMaterial, setSelectedMaterial] = useState(undefined);
  const [selectedPlasticType, setSelectedPlasticType] = useState(undefined);

  const [openPlasticListDlg, setOpenPlasticListDlg] = useState(false);
  const plasticListDlgCloseName = t("MessageBox.close");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgType, setConfirmDlgType] = useState(-1);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const [panelWidths, setPanelWidths] = useState(undefined);

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);
  const [progressData, setProgressData] = useState({
    open: false,
    content: "",
  });

  hotTableRef = useRef(undefined);
  hotTableRef1 = useRef(undefined);
  r_user = useSelector((state) => state.user, shallowEqual);
  r_headerPage = useSelector((state) => state.headerPage, shallowEqual);

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }

    const onResize = () => {
      if (!tableRef || !tableRef.current) return;

      const tableMaxHeight = tableRef.current.clientHeight;

      setPanelWidths([
        {
          maxSize: 500,
          minSize: 100,
          resize: "stretch",
          size: 200,
          snap: [],
          style: {},
        },
        {
          maxSize: tableMaxHeight - 100,
          minSize: tableMaxHeight - 500,
          resize: "dynamic",
          size: tableMaxHeight - 200,
          snap: [],
          style: {},
        },
      ]);

      onRefreshTable(hotTableRef, 1);
      onRefreshTable(hotTableRef1, 1);
    };

    const instance = hotTableRef.current
      ? hotTableRef.current.hotInstance
      : undefined;
    const instance1 = hotTableRef1.current
      ? hotTableRef1.current.hotInstance
      : undefined;

    const onBeforePaste = (datas, coords) => {
      for (let d_row = 0; d_row < datas.length; d_row++) {
        const data = datas[d_row];
        for (let d_col = 0; d_col < data.length; d_col++) {
          data[d_col] = data[d_col].toString().trim();
          let isMinus = data[d_col].toString().indexOf("-") !== -1;
          let t_item = data[d_col]
            .toString()
            .replace(/\$/gi, "")
            .replace(/,/gi, "")
            .replace(/-/gi, "")
            .trim();
          if (isEmpty(t_item)) {
            data[d_col] = "";
          } else {
            let regex = new RegExp(/^[-]?\d+(?:[.]\d+)?$/, "g");
            if (regex.test(t_item)) {
              t_item = parseFloat(t_item);
              if (!isNaN(t_item)) {
                data[d_col] = `${t_item * (isMinus ? -1 : 1)}`;
              }
            }
          }
        }
      }
    };

    const onBeforeContextMenuSetItems = (menuItems) => {
      if (!instance) return;
      const selectedLast = instance.getSelectedLast();
      const row = selectedLast[0] < 0 ? 0 : selectedLast[0];
      const col = selectedLast[1] < 0 ? 0 : selectedLast[1];
      let firstRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[0] : selectedLast[2];
      firstRow = firstRow < 0 ? 0 : firstRow;
      let lastRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[2] : selectedLast[0];
      lastRow = lastRow < 0 ? 0 : lastRow;
      const createCount = lastRow - firstRow + 1;
      for (let loop1 = 0; loop1 < menuItems.length; loop1++) {
        let menuItem = menuItems[loop1];
        if (menuItem.key === "row_above" || menuItem.key === "row_below") {
          let t_Count = `${createCount}`;
          if (menuItem.key === "row_above") {
            t_Count = `${row === 0 ? 0 : createCount}`;
            menuItem.name = insertRowAboveText.replace("xx", t_Count);
          } else if (menuItem.key === "row_below") {
            menuItem.name = insertRowBelowText.replace("xx", t_Count);
          }
        }
      }
    };

    const onBeforeContextMenuSetItems1 = (menuItems) => {
      if (!instance1) return;
      const selectedLast = instance1.getSelectedLast();
      const row = selectedLast[0] < 0 ? 0 : selectedLast[0];
      const col = selectedLast[1] < 0 ? 0 : selectedLast[1];
      let firstRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[0] : selectedLast[2];
      firstRow = firstRow < 0 ? 0 : firstRow;
      let lastRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[2] : selectedLast[0];
      lastRow = lastRow < 0 ? 0 : lastRow;
      const createCount = lastRow - firstRow + 1;
      for (let loop1 = 0; loop1 < menuItems.length; loop1++) {
        let menuItem = menuItems[loop1];
        if (menuItem.key === "row_above" || menuItem.key === "row_below") {
          let t_Count = `${createCount}`;
          if (menuItem.key === "row_above") {
            t_Count = `${row === 0 ? 0 : createCount}`;
            menuItem.name = insertRowAboveText.replace("xx", t_Count);
          } else if (menuItem.key === "row_below") {
            menuItem.name = insertRowBelowText.replace("xx", t_Count);
          }
        }
      }
    };

    const isTableInputClosest = (event) => {
      const target = event.target;
      if (target.id === "find-text-field") {
        return false;
      }
      if (target.closest("#find-text-field") !== null) {
        return false;
      }
      return true;
    };

    const onBeforeKeyDown = (event) => {
      if (event.keyCode === 27) {
        return;
      }
      if (!isTableInputClosest(event) || openProgessbar) {
        event.stopImmediatePropagation();
        return;
      }
    };

    if (instance) {
      instance.addHook("beforePaste", onBeforePaste);
      instance.addHook(
        "beforeContextMenuSetItems",
        onBeforeContextMenuSetItems
      );
      instance.addHook("beforeKeyDown", onBeforeKeyDown);
    }

    if (instance1) {
      instance1.addHook("beforePaste", onBeforePaste);
      instance1.addHook(
        "beforeContextMenuSetItems",
        onBeforeContextMenuSetItems1
      );
      instance1.addHook("beforeKeyDown", onBeforeKeyDown);
    }

    window.addEventListener("resize", onResize);

    return () => {
      if (instance && !instance.isDestroyed) {
        instance.removeHook("beforePaste", onBeforePaste);
        instance.removeHook(
          "beforeContextMenuSetItems",
          onBeforeContextMenuSetItems
        );
        instance.removeHook("beforeKeyDown", onBeforeKeyDown);
      }

      if (instance1 && !instance1.isDestroyed) {
        instance1.removeHook("beforePaste", onBeforePaste);
        instance1.removeHook(
          "beforeContextMenuSetItems",
          onBeforeContextMenuSetItems1
        );
        instance1.removeHook("beforeKeyDown", onBeforeKeyDown);
      }

      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "handleSearch") {
          handleSearch(item.params, item.reload);
        } else if (item.key === "handleSaveBasic") {
          handleSaveBasic(item.params);
        } else if (item.key === "handleSaveReduction") {
          handleSaveReduction(item.params);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    if (tableRef && tableRef.current) {
      const tableMaxHeight = tableRef.current.clientHeight;
      setPanelWidths([
        {
          maxSize: 500,
          minSize: 100,
          resize: "stretch",
          size: 200,
          snap: [],
          style: {},
        },
        {
          maxSize: tableMaxHeight - 100,
          minSize: tableMaxHeight - 500,
          resize: "dynamic",
          size: tableMaxHeight - 200,
          snap: [],
          style: {},
        },
      ]);
    }

    const startDate = moment("2009-06-01");
    const nowDate = moment();
    const startYear = parseInt(startDate.format("YYYY"));
    const nowYear = parseInt(nowDate.format("YYYY"));
    let nowMonth = parseInt(nowDate.format("MM"));

    const newYearData = [];
    for (let loop1 = 0; loop1 <= nowYear - startYear; loop1++) {
      newYearData.push(nowYear - loop1);
    }

    const newMonthData = [];
    for (let loop1 = 0; loop1 < 12; loop1++) {
      newMonthData.push(loop1 + 1);
    }

    if (hotTableRef && hotTableRef.current) {
      const instance = hotTableRef.current.hotInstance;
      if (instance) {
        onClearTable(instance);
        instance.updateSettings(TableSettings);
      }
    }

    if (hotTableRef1 && hotTableRef1.current) {
      const instance1 = hotTableRef1.current.hotInstance;
      if (instance1) {
        onClearTable(instance1);
        instance1.updateSettings(TableSettings);
      }
    }

    let _params = { year: nowYear, month: nowMonth };
    setTimeout(() => {
      if (!hotTableRef && !hotTableRef.current) return;
      if (!hotTableRef1 && !hotTableRef1.current) return;
      handleSearch(_params, false);
    });
  }, []);

  useEffect(() => {
    onRefreshTable(hotTableRef, 500);
    onRefreshTable(hotTableRef1, 500);
  }, [props.refreshTable]);

  const handleSearch = (repload) => {
    if (!r_user) return;

    if (hotTableRef && hotTableRef.current) {
      const instance = hotTableRef.current.hotInstance;
      if (instance) {
        onClearTable(instance);
        instance.updateSettings(TableSettings);
      }
    }

    if (hotTableRef1 && hotTableRef1.current) {
      const instance = hotTableRef1.current.hotInstance;
      if (instance) {
        onClearTable(instance);
        instance.updateSettings(TableSettings);
      }
    }

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({
        key: "handleSearch",
        kindType: 1,
        repload,
      });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/search";
    const queryData = {
      tokenUser: r_user,
      kindType: 1,
      material: selectedMaterial,
      plastic: selectedPlasticType,
    };

    handleOpenProgress(searching);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return handleCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return handleCloseProgress();

      if (!hotTableRef) return handleCloseProgress();
      if (!hotTableRef.current) return handleCloseProgress();
      if (!hotTableRef.current.hotInstance) return handleCloseProgress();

      if (!hotTableRef1) return handleCloseProgress();
      if (!hotTableRef1.current) return handleCloseProgress();
      if (!hotTableRef1.current.hotInstance) return handleCloseProgress();

      const instance = hotTableRef.current.hotInstance;
      const instance1 = hotTableRef1.current.hotInstance;
      if (instance.isDestroyed) return handleCloseProgress();
      if (instance1.isDestroyed) return handleCloseProgress();

      onClearTable(instance);
      onClearTable(instance1);

      if (!success) {
        instance.updateSettings(TableSettings);
        instance1.updateSettings(TableSettings);
      } else if (res && res.data) {
        if (res.data.last_edit) {
          const editor = res.data.last_edit.editor;
          const updatedTime = moment
            .utc(res.data.last_edit.logged_date)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");

          if (props.handleEditor) props.handleEditor(editor);
          if (props.handleUpdatedTime) props.handleUpdatedTime(updatedTime);
        } else {
          if (props.handleEditor) props.handleEditor(unknown);
          if (props.handleUpdatedTime) props.handleUpdatedTime(unknown);
        }

        const material_list = res.data.material_list;
        const plastic_list = res.data.plastic_list;

        if (Array.isArray(material_list)) setMaterialList(material_list);
        if (Array.isArray(plastic_list)) setPlasticList(plastic_list);

        if (res.data.basic_list) BASIC.initView(instance, res.data.basic_list);
        else instance.updateSettings(TableSettings);

        if (res.data.reduction_list)
          REDUCTION.initView(instance1, res.data.reduction_list);
        else instance1.updateSettings(TableSettings);
      }

      setTimeout(() => {
        handleCloseProgress();
      });
    });
    apiCallTime = runTime;
  };

  const handleSaveBasic = () => {
    if (!r_user) return;

    const instance = hotTableRef.current.hotInstance;
    const activeEditor = instance.getActiveEditor();
    if (activeEditor) {
      if (activeEditor.isOpened()) {
        activeEditor.finishEditing(false, false, () => {
          if (activeEditor.isOpened()) {
            activeEditor.close();
          }
        });
        return;
      }
    }

    // basic
    const basic_info = {};
    // const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    const weight_regexp = /^[0-9]+(.[0-9]+)?$/;

    let sourceData = instance.getSourceData();
    for (let row = 0; row < sourceData.length; row++) {
      if (row > 0) {
        const material_code = sourceData[row][0]
          ? sourceData[row][0]
          : undefined;
        const plastic_code = sourceData[row][2]
          ? sourceData[row][2]
          : undefined;
        let weight = sourceData[row][4] ? sourceData[row][4] : 0;

        if (!material_code || material_code.length < 6) continue;
        if (!plastic_code || plastic_code.length < 5) continue;
        if (!weight_regexp.test(weight)) continue;

        weight = parseFloat(weight).toFixed(2);
        if (!basic_info[material_code]) basic_info[material_code] = {};
        if (!basic_info[material_code][plastic_code])
          basic_info[material_code][plastic_code] = weight;
      }
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/saveBasic";
    const queryData = {
      tokenUser: r_user,
      kindType: 1,
      basic_info,
    };

    handleOpenProgress(saving);

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "handleSaveBasic" });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return handleCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return handleCloseProgress();

      if (!hotTableRef) return handleCloseProgress();
      if (!hotTableRef.current) return handleCloseProgress();
      if (!hotTableRef.current.hotInstance) return handleCloseProgress();

      const instance = hotTableRef.current.hotInstance;
      if (instance.isDestroyed) return handleCloseProgress();

      setTimeout(() => handleSearch());
      handleCloseProgress();
    });

    apiCallTime = runTime;
  };

  const handleSaveReduction = () => {
    if (!r_user) return;

    const instance = hotTableRef1.current.hotInstance;
    const activeEditor = instance.getActiveEditor();
    if (activeEditor) {
      if (activeEditor.isOpened()) {
        activeEditor.finishEditing(false, false, () => {
          if (activeEditor.isOpened()) {
            activeEditor.close();
          }
        });
        return;
      }
    }

    // const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    const weight_regexp = /^[0-9]+(.[0-9]+)?$/;
    const datetime_regexp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

    const reduction_info = {};

    // reduction
    const sourceData = instance.getSourceData();
    for (let row = 0; row < sourceData.length; row++) {
      if (row > 0) {
        const apply_date = sourceData[row][7];
        let weight = sourceData[row][5] ? sourceData[row][5] : 0;
        const contents = sourceData[row][8]
          ? sourceData[row][8].substr(0, 500)
          : "";

        if (!datetime_regexp.test(apply_date)) continue;
        if (!weight_regexp.test(weight)) continue;
        if (contents.length < 1) continue;

        const material_code = sourceData[row][0]
          ? sourceData[row][0]
          : undefined;
        const plastic_code = sourceData[row][2]
          ? sourceData[row][2]
          : undefined;

        if (!material_code || material_code.length < 6) continue;
        if (!plastic_code || plastic_code.length < 5) continue;

        weight = parseFloat(weight).toFixed(2);
        if (!reduction_info[material_code]) reduction_info[material_code] = {};
        if (!reduction_info[material_code][plastic_code])
          reduction_info[material_code][plastic_code] = {};
        if (!reduction_info[material_code][plastic_code][apply_date])
          reduction_info[material_code][plastic_code][apply_date] = {
            weight,
            contents,
          };
      }
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/saveReduction";
    const queryData = {
      tokenUser: r_user,
      kindType: 1,
      material: selectedMaterial,
      plastic: selectedPlasticType,
      reduction_info,
    };

    handleOpenProgress(saving);

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "handleSaveReduction" });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return handleCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return handleCloseProgress();

      if (!hotTableRef1) return handleCloseProgress();
      if (!hotTableRef1.current) return handleCloseProgress();
      if (!hotTableRef1.current.hotInstance) return handleCloseProgress();

      const instance = hotTableRef1.current.hotInstance;
      if (instance.isDestroyed) return handleCloseProgress();

      setTimeout(() => handleSearch());
      handleCloseProgress();
    });

    apiCallTime = runTime;
  };

  const handleUndoClick = (_hotTableRef) => {
    if (!_hotTableRef.current) return;
    const instance = _hotTableRef.current.hotInstance;
    const plugin = instance.getPlugin("UndoRedo");
    if (plugin.isUndoAvailable()) {
      plugin.undo();
    }
    // onUndoRedoCheck(false);
  };

  const handleRedoClick = (_hotTableRef) => {
    if (!_hotTableRef.current) return;
    const instance = _hotTableRef.current.hotInstance;
    const plugin = instance.getPlugin("UndoRedo");
    if (plugin.isRedoAvailable()) {
      plugin.redo();
    }
    // onUndoRedoCheck(false);
  };

  const onResizeEnd = (panels) => {
    setPanelWidths(panels);

    onRefreshTable(hotTableRef, 1);
    onRefreshTable(hotTableRef1, 1);
  };

  const onRefreshTable = (_hotTableRef, delayTime) => {
    setTimeout(() => {
      if (isEmpty(_hotTableRef) || isEmpty(_hotTableRef.current)) return;
      const instance = _hotTableRef.current.hotInstance;
      if (instance) instance.render();
    }, delayTime);
  };

  const handleMaterial = (_material) => {
    if (_material && _material.material_code) setSelectedMaterial(_material);
    else setSelectedMaterial(undefined);
  };

  const handlePlastic = (_plastic) => {
    if (_plastic && _plastic.plastic_code) setSelectedPlasticType(_plastic);
    else setSelectedPlasticType(undefined);
  };

  const handleOpenProgress = (content) => {
    setProgressData({ open: true, content: content });
  };

  const handleCloseProgress = () => {
    setProgressData({ open: false, content: "" });
  };

  const handleCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const handleOpenConfirmDlg = (title, content) => {
    setConfirmDlgTitle(title);
    setConfirmDlgContent(content);
    setOpenConfirmDlg(true);
  };

  const handleOkConfirmDlg = () => {
    if (confirmDlgTitle === saveBT) handleSaveBasic();
    if (confirmDlgTitle === saveRT) handleSaveReduction();
    handleCloseConfirmDlg();
  };

  const handleCancelConfirmDlg = () => {
    handleCloseConfirmDlg();
  };

  const handleCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  const handlePlasticListClose = () => {
    setOpenPlasticListDlg(false);

    setTimeout(() => handleSearch(false));
  };

  const getOptionLabel = (option, _type) => {
    let _title = "";
    if (_type === 1)
      _title = option && option.material_code ? option.material_code : option;
    else if (_type === 2)
      _title = option && option.plastic_code ? option.plastic_code : option;

    return _title;
  };

  const filterOptions = (options, _id, _keys) => {
    let input = document.getElementById(_id);
    let value1 = input ? input.value : "";
    let searchText = isEmpty(value1) ? "" : value1;

    if (!Array.isArray(options) || options.length < 1) return options;
    if (isEmpty(searchText)) return options.slice(0, 30);
    searchText = searchText.toLowerCase();

    let filter = options.filter((item) => {
      let _key = "";
      for (let i = 0; i < _keys.length; i++) {
        if (!isEmpty(item[_keys[i]]))
          _key = item[_keys[i]].toString().toLowerCase();

        if (!isEmpty(_key)) {
          if (_key.indexOf(searchText) !== -1) return true;
        }
      }

      return false;
    });
    return filter.slice(0, 30);
  };

  const renderInput = (params, title) => {
    const inputProps = { ...params.inputProps };
    inputProps.type = "text";
    inputProps.className = "form-control rounded-0";

    let _title = "";
    if (title && title.material_code)
      _title = `${title.material_code} / ${title.material_name}`;
    if (title && title.plastic_type) _title = title.plastic_type;

    return (
      <div className="input-group" ref={params.InputProps.ref} title={_title}>
        <input {...inputProps} />
      </div>
    );
  };

  const renderMaterialOption = (params) => {
    if (!materialList) return <></>;

    let item = materialList.find((_item) => {
      if (_item.material_code === params.material_code) return _item;
    });
    item = item ? item : selectedMaterial;

    const optionStyle = {
      width: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };
    const optionCode = `${item.material_code}`;
    const optionName = `${item.material_name}`;

    return (
      <div title={`${optionCode}\r\n${optionName}`} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{optionStyle, borderRight: '1px solid #000', paddingRight: '10px', marginRight: '10px' }}>
          <span className={classes.searchLabel}>{optionCode}</span>
        </div>
        <div style={optionStyle}>
          <span className={classes.searchLabel}>{optionName}</span>
        </div>
      </div>
    );
  };

  const renderPlasticOption = (params) => {
    let item = plasticList.find((_item) => {
      if (_item.plastic_type === params.plastic_type) return _item;
    });
    item = item ? item : selectedPlasticType;

    const optionStyle = {
      width: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };

    const optionCode = `${item.plastic_code}`;
    const optionName = `${item.plastic_type}`;

    return (
      <div title={`${optionCode}\r\n${optionName}`} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{optionStyle, borderRight: '1px solid #000', paddingRight: '10px', marginRight: '10px' }}>
          <span className={classes.searchLabel}>{optionCode}</span>
        </div>
        <div style={optionStyle}>
          <span className={classes.searchLabel}>{optionName}</span>
        </div>
      </div>
    );
  };

  const reductionToolRender = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item style={{ padding: "10px 20px 0px 0px" }}>
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ minWidth: "50px" }}>
              <span
                className="text-font"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#262626",
                  marginRight: "10px",
                }}
              >
                {`${materialCode}:`}
              </span>
            </Grid>
            <Grid item>
              <Autocomplete
                disableClearable
                freeSolo
                classes={{
                  paper: classes.searchPaper,
                  listbox: classes.searchListBox,
                }}
                id="material-combo-box"
                options={materialList ? materialList : []}
                value={selectedMaterial || ""}
                onChange={(event, newValue) => handleMaterial(newValue)}
                onInputChange={(event, newValue) =>
                  handleMaterial({ material_code: newValue })
                }
                getOptionLabel={(option) => getOptionLabel(option, 1)}
                renderOption={renderMaterialOption}
                renderInput={(params) => renderInput(params, selectedMaterial)}
                filterOptions={(options) =>
                  filterOptions(options, "material-combo-box", [
                    "material_code",
                    "material_name",
                  ])
                }
                style={{ width: "300px" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ padding: "10px 20px 0px 0px" }}>
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ minWidth: "50px" }}>
              <span
                className="text-font"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#262626",
                  marginRight: "10px",
                }}
              >
                {`${plasticType}:`}
              </span>
            </Grid>
            <Grid item>
              <Autocomplete
                disableClearable
                freeSolo
                classes={{
                  paper: classes.searchPaper,
                  listbox: classes.searchListBox,
                }}
                id="plastic-combo-box"
                size="small"
                options={plasticList ? plasticList : []}
                value={selectedPlasticType || ""}
                onChange={(event, newValue) => handlePlastic(newValue)}
                onInputChange={(event, newValue) =>
                  handlePlastic({ plastic_code: newValue })
                }
                getOptionLabel={(option) => getOptionLabel(option, 2)}
                renderOption={renderPlasticOption}
                renderInput={(params) =>
                  renderInput(params, selectedPlasticType)
                }
                filterOptions={(options) =>
                  filterOptions(options, "plastic-combo-box", [
                    "plastic_code",
                    "plastic_type",
                  ])
                }
                style={{ width: "300px" }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ padding: "10px 20px 0px 0px" }}>
          <ColorButton
            onClick={() => {
              setTimeout(() => handleSearch(false));
            }}
          >
            <Typography style={{ fontSize: "14px" }}>{search}</Typography>
          </ColorButton>
        </Grid>
        <Grid item xs style={{ padding: "10px 0px 0px 0px" }}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item>
              <ColorButton onClick={() => setOpenPlasticListDlg(true)}>
                <Typography style={{ fontSize: "14px" }}>
                  {plastic_edit}
                </Typography>
              </ColorButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const gridToolbarRender = (_hotTableRef, _title) => {
    if (!hotTableRef || !hotTableRef1) return <></>;

    const saveTitle = hotTableRef === _hotTableRef ? saveBT : saveRT;
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{
            backgroundColor: "#f3f3f3",
            padding: "0 8px",
            borderTop: "1px solid #aeaeae",
            borderBottom: "1px solid #aeaeae",
          }}
        >
          <Grid item>
            <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
              {_title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "7px 3px 7px", height: "26px" }}
                />
              </Grid>
              <Grid item>
                <CustomTooltip title={undoTooltip}>
                  <span>
                    <ToolbarBtn onClick={() => handleUndoClick(_hotTableRef)}>
                      <UndoIcon style={{ fontSize: "18px" }} />
                    </ToolbarBtn>
                  </span>
                </CustomTooltip>
              </Grid>
              <Grid item>
                <CustomTooltip title={redoTooltip}>
                  <span>
                    <ToolbarBtn onClick={() => handleRedoClick(_hotTableRef)}>
                      <RedoIcon style={{ fontSize: "18px" }} />
                    </ToolbarBtn>
                  </span>
                </CustomTooltip>
              </Grid>
              <Grid item>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "7px 3px 7px", height: "26px" }}
                />
              </Grid>
              <Grid item>
                <CustomTooltip title={saveTooltip}>
                  <span>
                    <ToolbarBtn
                      onClick={() => handleOpenConfirmDlg(saveTitle, saveQ)}
                    >
                      {<SaveIcon style={{ fontSize: "18px" }} />}
                    </ToolbarBtn>
                  </span>
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  const tableRender = () => {
    if (!tableRef || !tableRef.current) return <></>;

    return (
      <PanelGroup
        direction="column"
        panelWidths={panelWidths}
        onResizeEnd={onResizeEnd}
      >
        <div
          className="table-view-container"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #c2c2c2",
            overflow: "auto",
          }}
        >
          <div>{gridToolbarRender(hotTableRef, basicText)}</div>
          <div style={{ width: "100%", height: "calc(100% - 42px)" }}>
            <DataTable
              tableID={hotTableID}
              tableRef={hotTableRef}
              tableSettings={TableSettings}
            />
          </div>
        </div>
        <div
          className="table-view-container"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #c2c2c2",
          }}
        >
          <div>{gridToolbarRender(hotTableRef1, reductionText)}</div>
          <div style={{ width: "100%", height: "calc(100% - 42px)" }}>
            <DataTable
              tableID={hotTableID1}
              tableRef={hotTableRef1}
              tableSettings={TableSettings}
            />
          </div>
        </div>
      </PanelGroup>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ height: "100%", padding: "0px 8px" }}
    >
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={handleCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={handleOkConfirmDlg}
          onCancelConfirmDlg={handleCancelConfirmDlg}
          onCloseConfirmDlg={handleCloseConfirmDlg}
        />
      )}

      {openPlasticListDlg && (
        <PlasticListDlg
          isOpen={openPlasticListDlg}
          title={plastic_edit}
          cancelName={plasticListDlgCloseName}
          handleClose={handlePlasticListClose}
        />
      )}

      <div id="search-section" ref={searchRef} style={{ width: "100%" }} />
      <Grid item style={{ padding: "0px 0px 10px" }}>
        {reductionToolRender()}
      </Grid>
      <Grid item xs style={{ padding: "0px 0px 10px" }}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          style={{ width: "100%", height: "100%" }}
        >
          <Grid
            xs
            item
            innerRef={tableRef}
            style={{ width: "100%", minHeight: "100%" }}
          >
            <div style={{ width: "100%", height: "100%" }}>{tableRender()}</div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={progressData.open}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressData.content}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
}

export default ReductionGrid;
