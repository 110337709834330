import { combineReducers } from "redux";

import dataManagementPage from "./dataManagementPage";
import headerPage from "./headerPage";

import menuInfo from "./menuInfo";
import user from "./user";

const rootReducer = combineReducers({
  dataManagementPage,
  headerPage,
  menuInfo,
  user,
});

export default rootReducer;
