import React, { useState, useEffect, useRef } from "react";

import { Link, useLocation } from "react-router-dom";

import $ from "jquery";

import { isMobile, isTablet } from "react-device-detect";

import { PathType } from "../../define";
import { MenuType, MenuShowType, ReportMenuType } from "../../define";
import { HeaderMinHeight, HeaderMaxHeight } from "../../define";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msalConfig } from "../../authConfig";

import { RequestProfileData } from "../../utils/azure";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { r_setHeaderPage } from "../../actions/headerPage";
import { r_setUser } from "../../actions/user";
import { r_setMenuInfo } from "../../actions/menuInfo";
import { r_setDataManagementPage } from "../../actions/dataManagementPage";

import { makeStyles } from "@material-ui/core/styles";

import { Grid, Popover, Divider } from "@material-ui/core";

import Collapse from "@material-ui/core/Collapse";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { useTranslation } from "react-i18next";

import { sendBackend } from "../../utils/backend";

import { getUserCheck, jsonToString, resetAppData } from "../../utils/Utils";
import { isEmpty } from "../../utils/Utils";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  divider: {
    color: "#212529",
  },
}));

let r_menuInfo;
let r_user;
let countdown = undefined;
const timerCount = 300; // 300 * 1000

let _instance;
let _accounts;
let _isAuthenticated;

let _menuHidden = false;

let _headerMinHeight;
let _headerMaxHeight;
let _fixedMenubar = false;
let _openMenubar = false;

let _landscapeMode;
function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const location = useLocation();

  const headerRef = useRef(null);
  const { instance, accounts } = useMsal();
  _instance = instance;
  _accounts = accounts;
  _isAuthenticated = useIsAuthenticated();

  r_menuInfo = useSelector((state) => state.menuInfo, shallowEqual);
  r_user = useSelector((state) => state.user, shallowEqual);

  const [winSize, setWinSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  _menuHidden = winSize.width < 1375; // 1230 992

  const [landscapeMode, setLandscapeMode] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );
  _landscapeMode = landscapeMode;

  const mobileView = { mainMenu: false, reportMenu: false };
  if (isMobile) {
    if (!isTablet) {
      mobileView.mainMenu = true;
      mobileView.reportMenu = true;
    } else {
      if (!landscapeMode) {
        mobileView.mainMenu = true;
        mobileView.reportMenu = true;
      }
    }
  }

  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const openProfile = Boolean(profileAnchorEl);

  const [fixedMenubar, setFixedMenubar] = useState(false);
  _fixedMenubar = fixedMenubar;

  const [openMenubar, setOpenMenubar] = useState(null);
  _openMenubar = openMenubar;

  const [collapseType, setCollapseType] = useState(-1);

  const [headerHeight, setHeaderHeight] = useState({
    minHeight: HeaderMinHeight,
    maxHeight: HeaderMaxHeight,
  });
  _headerMinHeight = headerHeight.minHeight;
  _headerMaxHeight = headerHeight.maxHeight;

  useEffect(() => {
    setTimer(true);

    const onResize = () => {
      setWinSize({ width: window.innerWidth, height: window.innerHeight });
      const __landscapeMode = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      if (_landscapeMode !== __landscapeMode) {
        setLandscapeMode(__landscapeMode);
      }
    };

    const onOrientationchange = () => {
      const __landscapeMode = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      if (_landscapeMode !== __landscapeMode) {
        setLandscapeMode(__landscapeMode);
      }
    };

    window.addEventListener("resize", onResize);
    window.addEventListener("orientationchange", onOrientationchange);
    return () => {
      clearTimer();

      window.removeEventListener("resize", onResize);
      window.removeEventListener("orientationchange", onOrientationchange);
    };
  }, []);

  useEffect(() => {
    let subMenuCount = 0;
    if (r_menuInfo && Array.isArray(r_menuInfo.data)) {
      for (var loop1 in r_menuInfo.data) {
        const mainMenu = r_menuInfo.data[loop1];
        if (mainMenu.hidden) {
          continue;
        }

        if (mobileView.mainMenu) {
          if (mainMenu.showType === MenuShowType.OnlyDeskTop) {
            continue;
          }
        } else {
          if (mainMenu.showType === MenuShowType.OnlyMobile) {
            continue;
          }
        }

        let t_Count = 0;
        if (Array.isArray(mainMenu.reportMenus)) {
          for (var loop2 in mainMenu.reportMenus) {
            const reportMenu = mainMenu.reportMenus[loop2];
            if (reportMenu.hidden) {
              continue;
            }
            if (mobileView.reportMenu) {
              if (reportMenu.menuType === ReportMenuType.OnlyDeskTop) {
                continue;
              }
            } else {
              if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
                continue;
              }
            }
            t_Count++;
          }
        }
        subMenuCount = t_Count > subMenuCount ? t_Count : subMenuCount;
      }
    }
    subMenuCount = subMenuCount < 2 ? 2 : subMenuCount;
    _headerMaxHeight = subMenuCount * 34 + 40 + _headerMinHeight;
    setHeaderHeight({
      minHeight: _headerMinHeight,
      maxHeight: _headerMaxHeight,
    });

    const $depth1 = $(".depth1-li");
    const $header = $(".main-header");

    if (!_menuHidden && _fixedMenubar) {
      $header.stop().animate({ height: `${_headerMaxHeight}px` });
    }

    const onDepthEvent = (event) => {
      if (_menuHidden || _fixedMenubar) {
        return;
      }
      if (event.type === "mouseenter") {
        $header.stop().animate({ height: `${_headerMaxHeight}px` });
      } else if (event.type === "mouseleave") {
        $header.stop().animate({ height: `${_headerMinHeight}px` });
      }
    };

    const $depth2 = $(".depth2 > ul");

    const onMouseEvent = (event) => {
      let _color = "#262626";
      if (event.type === "mouseenter") {
        _color = "#00b5ef";
      } else if (event.type === "mouseleave") {
        _color = "#262626";
      }
      $(event.currentTarget)
        .parent()
        .parent()
        .find(".depth1_link")
        .css({ color: _color });
    };

    if (!isMobile) {
      $depth1.on("mouseenter", onDepthEvent);
      $depth1.on("mouseleave", onDepthEvent);

      $depth2.on("mouseenter", onMouseEvent);
      $depth2.on("mouseleave", onMouseEvent);
    }

    return () => {
      if (!isMobile) {
        $depth1.off("mouseenter", onDepthEvent);
        $depth1.off("mouseleave", onDepthEvent);

        $depth2.off("mouseenter", onMouseEvent);
        $depth2.off("mouseleave", onMouseEvent);
      }
    };
  }, [r_menuInfo]);

  useEffect(() => {
    if (!_menuHidden) {
      if (_openMenubar) {
        onCloseMenubar(0);
      }
    } else {
      if (_fixedMenubar) {
        onCancelFixedMenubar(0);
      }
    }
  }, [winSize.width]);

  const clearTimer = () => {
    if (countdown !== undefined) {
      clearInterval(countdown);
      countdown = undefined;
    }
  };

  const setTimer = (init) => {
    clearTimer();

    if (init) {
      refreshAppData();
    }

    let count = timerCount;
    countdown = setInterval(() => {
      if (parseInt(count) > 0) {
        count = parseInt(count) - 1;
      }
      if (parseInt(count) === 0) {
        refreshAppData();
        count = timerCount;
      }
    }, 1000);
  };

  const refreshAppData = () => {
    clearTimer();

    RequestProfileData(_instance, _accounts, dispatch, ({ user }) => {
      const apiPath = "v1/auth/appdata";
      const queryData = { tokenUser: r_user, user };
      dispatch(r_setHeaderPage({ waitingAppData: true }));
      sendBackend(false, apiPath, queryData, (success, res) => {
        if (!_isAuthenticated || !getUserCheck()) {
          return;
        }

        if (success && res && res.data && res.data.user) {
          let newUser = res.data.user;
          let newGroups = [];
          if (!isEmpty(res.data.groups)) {
            newGroups = res.data.groups;
          }
          newUser.groups = newGroups;

          let newMenuInfo = { data: [] };
          if (!isEmpty(res.data.mainMenus)) {
            newMenuInfo.data = res.data.mainMenus;
          }

          if (jsonToString(r_menuInfo) !== jsonToString(newMenuInfo)) {
            dispatch(r_setMenuInfo(newMenuInfo));
          }

          if (jsonToString(r_user) !== jsonToString(newUser)) {
            dispatch(r_setUser(newUser));
          }

          setTimer(false);
          dispatch(r_setHeaderPage({ waitingAppData: false }));
          return;
        }

        dispatch(r_setHeaderPage({ waitingAppData: false }));

        resetAppData(dispatch, true);
      });
    });
  };

  const handleProfile = (event) => {
    if (!openProfile) {
      onOpenProfile(event);
    } else {
      onCloseProfile();
    }
  };

  const onOpenProfile = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const onCloseProfile = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    const logoutRequest = {};

    if (Array.isArray(accounts) && accounts.length > 0) {
      const homeAccountId = accounts[0].homeAccountId;
      if (!isEmpty(homeAccountId)) {
        logoutRequest.account = instance.getAccountByHomeId(homeAccountId);
      }
    }
    logoutRequest.postLogoutRedirectUri = msalConfig.auth.postLogoutRedirectUri;

    instance.logoutRedirect(logoutRequest).catch((err) => {
      console.error(err && err.message ? err.message : err);
      resetAppData(dispatch, true);
      // window.location.reload();
    });
  };

  const getDisplayName = () => {
    let r_displayName = r_user && r_user.displayName ? r_user.displayName : "";

    return (
      <p
        title={r_displayName}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "16px",
          margin: "0px",
          color: "#212529",
        }}
      >
        {r_displayName}
      </p>
    );
  };

  const getEmail = () => {
    let r_mail = r_user && r_user.mail ? r_user.mail : "";

    return (
      <p
        title={r_mail}
        style={{
          fontSize: "0.8rem",
          margin: "0px",
          color: "#212529",
          display: !r_mail ? "none" : "",
        }}
      >
        {r_mail}
      </p>
    );
  };

  const getGroupName = () => {
    let groupName = "";
    if (r_user && Array.isArray(r_user.groups)) {
      for (var i in r_user.groups) {
        if (groupName) {
          groupName += " / ";
        }
        groupName += r_user.groups[i].name;
      }
    }

    return (
      <p
        title={groupName}
        style={{
          fontSize: "0.8rem",
          margin: "0px",
          color: "#212529",
        }}
      >
        {groupName}
      </p>
    );
  };

  const handleToggler = () => {
    if (!_menuHidden) {
      if (!_fixedMenubar) {
        onFixedMenubar();
      } else {
        onCancelFixedMenubar();
      }
    } else {
      if (!_openMenubar) {
        onOpenMenubar();
      } else {
        onCloseMenubar();
      }
    }
  };

  const onFixedMenubar = () => {
    if (!_fixedMenubar) {
      const $header = $(".main-header");
      $header.stop().animate({ height: `${_headerMaxHeight}px` });
      setFixedMenubar(true);
    }
  };

  const onCancelFixedMenubar = (speed = undefined) => {
    if (_fixedMenubar) {
      const $header = $(".main-header");
      if (speed !== undefined) {
        $header.stop().animate({ height: `${_headerMinHeight}px` }, speed);
      } else {
        $header.stop().animate({ height: `${_headerMinHeight}px` });
      }
      setFixedMenubar(false);
    } else {
      if (isMobile) {
        const $header = $(".main-header");
        if (speed !== undefined) {
          $header.stop().animate({ height: `${_headerMinHeight}px` }, speed);
        } else {
          $header.stop().animate({ height: `${_headerMinHeight}px` });
        }
      }
    }
  };

  const onOpenMenubar = () => {
    if (!_openMenubar) {
      const $header = $(".main-header");
      $header.stop().animate({ height: `100vh` });
      setOpenMenubar(true);
    }
  };

  const onCloseMenubar = (speed = undefined) => {
    if (_openMenubar) {
      const $header = $(".main-header");
      if (speed !== undefined) {
        $header.stop().animate({ height: `${_headerMinHeight}px` }, speed);
      } else {
        $header.stop().animate({ height: `${_headerMinHeight}px` });
      }
      setOpenMenubar(false);
    }
  };

  const renderUserManagementMenu1 = (mainMenu) => {
    let userMenuName = t("UserManagement.userMenu");
    let userMenuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}/user`;

    let groupMenuName = t("UserManagement.groupMenu");
    let groupMenuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}/group`;

    return (
      <li
        className="depth1-li nav-item nav-item-menu d-none d-my-inline-block"
        key={mainMenu.menu_pkey}
      >
        <div
          className={`nav-link depth1_link${
            isMobile ? "" : " depth1_link-hover"
          }`}
          style={{ cursor: "default" }}
          onClick={() => {
            if (isMobile && !_menuHidden && !_fixedMenubar) {
              onFixedMenubar();
            }
          }}
        >
          <span title={mainMenu.name}>{mainMenu.name}</span>
        </div>
        <div className={`depth2${isMobile ? "" : " depth2-hover"}`}>
          <ul style={{ height: `${_headerMaxHeight - 40}px` }}>
            <li key={userMenuName}>
              <Link
                to={userMenuUrl}
                className={`depth2_link${isMobile ? "" : " depth2_link-hover"}`}
                style={{ fontSize: "14px" }}
                title={userMenuName}
                onClick={() => {
                  onCancelFixedMenubar();
                  if (`${location.pathname}` === userMenuUrl) {
                    window.location.reload();
                  }
                }}
              >
                {userMenuName}
              </Link>
            </li>

            <li key={groupMenuName}>
              <Link
                to={groupMenuUrl}
                className={`depth2_link${isMobile ? "" : " depth2_link-hover"}`}
                style={{ fontSize: "14px" }}
                title={groupMenuName}
                onClick={() => {
                  onCancelFixedMenubar();
                  if (`${location.pathname}` === groupMenuUrl) {
                    window.location.reload();
                  }
                }}
              >
                {groupMenuName}
              </Link>
            </li>
          </ul>
        </div>
      </li>
    );
  };

  const renderUserManagementMenu2 = (mainMenu) => {
    let userMenuName = t("UserManagement.userMenu");
    let userMenuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}/user`;

    let groupMenuName = t("UserManagement.groupMenu");
    let groupMenuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}/group`;

    return (
      <li key={mainMenu.menu_pkey} className="menubar-depth1-li">
        <Link
          to="#"
          className="menubar-depth1-link"
          onClick={() => {
            if (collapseType === mainMenu.menu_pkey) {
              setCollapseType(-1);
            } else {
              setCollapseType(mainMenu.menu_pkey);
            }
          }}
        >
          <span style={{ width: "100%" }} title={mainMenu.name}>
            {mainMenu.name}
          </span>
          {collapseType === mainMenu.menu_pkey ? (
            <ExpandMoreIcon style={{ fontSize: "27px" }} />
          ) : (
            <ChevronRightIcon style={{ fontSize: "27px" }} />
          )}
        </Link>

        <Collapse
          in={collapseType === mainMenu.menu_pkey}
          timeout="auto"
          unmountOnExit
        >
          <ul>
            <li className="menubar-depth2-li" key={userMenuName}>
              <Link
                to={userMenuUrl}
                className="menubar-depth2-link"
                style={{ fontSize: "14px" }}
                title={userMenuName}
                onClick={() => {
                  onCloseMenubar();
                  if (`${location.pathname}` === userMenuUrl) {
                    window.location.reload();
                  }
                }}
              >
                <div>
                  <FiberManualRecordIcon
                    style={{
                      height: "18px",
                      fontSize: "6px",
                    }}
                  />
                  <span style={{ marginLeft: "6px" }}>{userMenuName}</span>
                </div>
              </Link>
            </li>

            <li className="menubar-depth2-li" key={groupMenuName}>
              <Link
                to={groupMenuUrl}
                className="menubar-depth2-link"
                style={{ fontSize: "14px" }}
                title={groupMenuName}
                onClick={() => {
                  onCloseMenubar();
                  if (`${location.pathname}` === groupMenuUrl) {
                    window.location.reload();
                  }
                }}
              >
                <div>
                  <FiberManualRecordIcon
                    style={{
                      height: "18px",
                      fontSize: "6px",
                    }}
                  />
                  <span style={{ marginLeft: "6px" }}>{groupMenuName}</span>
                </div>
              </Link>
            </li>
          </ul>
        </Collapse>
      </li>
    );
  };

  return (
    <header className="main-header" ref={headerRef}>
      <nav id="topNav" className="navbar navbar-expand">
        <ul className="left-navbar-nav navbar-nav">
          <li className="nav-item">
            <Link
              to="/"
              onClick={() => {
                if (_menuHidden) {
                  onCloseMenubar();
                } else {
                  onCancelFixedMenubar();
                }
              }}
            >
              <img
                src="/img/atomy_logo_35.png"
                alt="Atomy Logo"
                className="brand-image"
                style={{ height: "30px", margin: "5px 5px 5px 8px" }}
              />
              <span style={{ color: "#262626" }}></span>
            </Link>
          </li>

          {r_menuInfo &&
            Array.isArray(r_menuInfo.data) &&
            r_menuInfo.data.map((mainMenu) => {
              if (mainMenu.hidden) {
                return <li key={mainMenu.menu_pkey}></li>;
              }

              if (mobileView.mainMenu) {
                if (mainMenu.showType === MenuShowType.OnlyDeskTop) {
                  return <li key={mainMenu.menu_pkey}></li>;
                }
              } else {
                if (mainMenu.showType === MenuShowType.OnlyMobile) {
                  return <li key={mainMenu.menu_pkey}></li>;
                }
              }

              if (mainMenu.menuType === MenuType.UserManagement) {
                return renderUserManagementMenu1(mainMenu);
              }

              let menuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}`;
              if (mainMenu.menuType === MenuType.Report) {
                menuUrl = `/${PathType.Report}/${mainMenu.menu_pkey}`;
              }

              let isEmptyMenu = true;
              if (Array.isArray(mainMenu.reportMenus)) {
                for (var i in mainMenu.reportMenus) {
                  const reportMenu = mainMenu.reportMenus[i];
                  if (reportMenu.hidden) {
                    continue;
                  }

                  if (mobileView.reportMenu) {
                    if (reportMenu.menuType === ReportMenuType.OnlyDeskTop) {
                      continue;
                    }
                  } else {
                    if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
                      continue;
                    }
                  }
                  isEmptyMenu = false;
                  break;
                }
              }

              return (
                <li
                  className="depth1-li nav-item nav-item-menu d-none d-my-inline-block"
                  key={mainMenu.menu_pkey}
                >
                  {isEmptyMenu &&
                  Array.isArray(mainMenu.dataManagementMenus) &&
                  mainMenu.dataManagementMenus.length < 1 ? (
                    <Link
                      to={menuUrl}
                      className={`nav-link depth1_link${
                        isMobile ? "" : " depth1_link-hover"
                      }`}
                      title={mainMenu.name}
                      onClick={() => {
                        onCancelFixedMenubar();
                      }}
                    >
                      {mainMenu.name}
                    </Link>
                  ) : (
                    <div
                      className={`nav-link depth1_link${
                        isMobile ? "" : " depth1_link-hover"
                      }`}
                      style={{ cursor: "default" }}
                      onClick={() => {
                        if (isMobile && !_menuHidden && !_fixedMenubar) {
                          onFixedMenubar();
                        }
                      }}
                    >
                      <span title={mainMenu.name}>{mainMenu.name}</span>
                    </div>
                  )}
                  <div className={`depth2${isMobile ? "" : " depth2-hover"}`}>
                    <ul style={{ height: `${_headerMaxHeight - 40}px` }}>
                      {isEmptyMenu ? (
                        mainMenu.dataManagementMenus ? (
                          mainMenu.dataManagementMenus.map((menu) => {
                            let newMenuData = {
                              menu_pkey: menu.menu_pkey,
                              group_pkey: menu.group_pkey,
                              group_name: menu.group_name,
                              subName: menu.subName,
                              fileName: menu.fileName,
                              tableName: menu.tableName,
                              dataTitle: menu.dataTitle,
                              type: menu.type,
                            };

                            const title = `${menu.subName}`;
                            return (
                              <li key={menu.menu_pkey}>
                                <Link
                                  to={menuUrl}
                                  className={`depth2_link${
                                    isMobile ? "" : " depth2_link-hover"
                                  }`}
                                  style={{ fontSize: "14px" }}
                                  title={title}
                                  onClick={() => {
                                    dispatch(
                                      r_setDataManagementPage({
                                        menuData: newMenuData,
                                      })
                                    );
                                    onCancelFixedMenubar();
                                    if (`${location.pathname}` === menuUrl) {
                                      window.location.reload();
                                    }
                                  }}
                                >
                                  {title}
                                </Link>
                              </li>
                            );
                          })
                        ) : (
                          <li></li>
                        )
                      ) : (
                        mainMenu.reportMenus.map((reportMenu) => {
                          if (reportMenu.hidden) {
                            return <li key={reportMenu.menu_pkey}></li>;
                          }

                          if (mobileView.reportMenu) {
                            if (
                              reportMenu.menuType === ReportMenuType.OnlyDeskTop
                            ) {
                              return <li key={reportMenu.menu_pkey}></li>;
                            }
                          } else {
                            if (
                              reportMenu.menuType === ReportMenuType.OnlyMobile
                            ) {
                              return <li key={reportMenu.menu_pkey}></li>;
                            }
                          }

                          let reportUrl = `/${PathType.Report}/${reportMenu.menu_pkey}`;
                          return (
                            <li key={reportMenu.menu_pkey}>
                              <Link
                                to={reportUrl}
                                className={`depth2_link${
                                  isMobile ? "" : " depth2_link-hover"
                                }`}
                                style={{ fontSize: "14px" }}
                                title={reportMenu.name}
                                onClick={() => {
                                  onCancelFixedMenubar();
                                  if (`${location.pathname}` === reportUrl) {
                                    window.location.reload();
                                  }
                                }}
                              >
                                {reportMenu.name}
                              </Link>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </li>
              );
            })}
        </ul>

        <ul className="right-navbar-nav navbar-nav navbar-no-expand ml-auto">
          <li className="nav-item">
            <button
              type="button"
              className="nav-link btn btn-block btn-default btn-sm"
              aria-describedby="profile-popover"
              onClick={handleProfile}
            >
              <i
                className="fas fa-user-circle"
                style={{ fontSize: "24px" }}
              ></i>
            </button>
            <Popover
              id="profile-popover"
              open={openProfile}
              anchorEl={profileAnchorEl}
              onClose={onCloseProfile}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              disableScrollLock={true}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                style={{
                  minWidth: "280px",
                  maxWidth: "300px",
                }}
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{
                    padding: ".5rem 1rem",
                  }}
                >
                  <Grid item>
                    <i
                      className="fas fa-user-circle img-size-32"
                      style={{ fontSize: "32px" }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{
                      width: "calc(100% - 32px)",
                      paddingLeft: "10px",
                      fontFamily: "Source Sans Pro",
                    }}
                  >
                    <Grid item style={{ width: "100%" }}>
                      {getDisplayName()}
                    </Grid>
                    <Grid item style={{ width: "100%", marginTop: "5px" }}>
                      {getEmail()}
                    </Grid>
                    <Grid item style={{ width: "100%", marginTop: "5px" }}>
                      {getGroupName()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item style={{ width: "100%" }}>
                  <button
                    type="button"
                    className="dropdown-item dropdown-footer btn btn-block btn-default btn-sm"
                    onClick={handleLogout}
                  >
                    {t("Header.logout")}
                  </button>
                </Grid>
              </Grid>
            </Popover>
          </li>
          <li className="nav-item">
            <button
              type="button"
              className="nav-link btn btn-block btn-default btn-sm"
              style={{ minWidth: "37px" }}
              aria-describedby="menu-popover"
              onClick={handleToggler}
            >
              <i
                className={
                  (!_menuHidden && _fixedMenubar) ||
                  (_menuHidden && _openMenubar)
                    ? "fa fa-times"
                    : "fa fa-bars"
                }
                style={{ fontSize: "24px" }}
              />
            </button>
          </li>
        </ul>
      </nav>

      <div
        className="menubar d-inline-block d-my-none"
        style={{
          width: `100vw`,
          height: `calc(${winSize.height}px - ${_headerMinHeight}px)`,
        }}
      >
        <ul>
          {r_menuInfo &&
            Array.isArray(r_menuInfo.data) &&
            r_menuInfo.data.map((mainMenu) => {
              if (mainMenu.hidden) {
                return <li key={mainMenu.menu_pkey}></li>;
              }

              if (mobileView.mainMenu) {
                if (mainMenu.showType === MenuShowType.OnlyDeskTop) {
                  return <li key={mainMenu.menu_pkey}></li>;
                }
              } else {
                if (mainMenu.showType === MenuShowType.OnlyMobile) {
                  return <li key={mainMenu.menu_pkey}></li>;
                }
              }

              if (mainMenu.menuType === MenuType.UserManagement) {
                return renderUserManagementMenu2(mainMenu);
              }

              let menuUrl = `/${PathType.Menu}/${mainMenu.menu_pkey}`;
              if (mainMenu.menuType === MenuType.Report) {
                menuUrl = `/${PathType.Report}/${mainMenu.menu_pkey}`;
              }

              let isEmptyMenu = true;
              if (Array.isArray(mainMenu.reportMenus)) {
                for (var i in mainMenu.reportMenus) {
                  const reportMenu = mainMenu.reportMenus[i];
                  if (reportMenu.hidden) {
                    continue;
                  }

                  if (mobileView.reportMenu) {
                    if (reportMenu.menuType === ReportMenuType.OnlyDeskTop) {
                      continue;
                    }
                  } else {
                    if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
                      continue;
                    }
                  }
                  isEmptyMenu = false;
                  break;
                }
              }

              return (
                <li key={mainMenu.menu_pkey} className="menubar-depth1-li">
                  <Link
                    to={isEmptyMenu ? menuUrl : "#"}
                    className="menubar-depth1-link"
                    onClick={() => {
                      if (isEmptyMenu) {
                        if (collapseType !== -1) {
                          setCollapseType(-1);
                        }
                        onCloseMenubar();
                      } else {
                        if (collapseType === mainMenu.menu_pkey) {
                          setCollapseType(-1);
                        } else {
                          setCollapseType(mainMenu.menu_pkey);
                        }
                      }
                    }}
                  >
                    <span style={{ width: "100%" }} title={mainMenu.name}>
                      {mainMenu.name}
                    </span>
                    {!isEmptyMenu &&
                      (collapseType === mainMenu.menu_pkey ? (
                        <ExpandMoreIcon style={{ fontSize: "27px" }} />
                      ) : (
                        <ChevronRightIcon style={{ fontSize: "27px" }} />
                      ))}
                  </Link>

                  {!isEmptyMenu && (
                    <Collapse
                      in={collapseType === mainMenu.menu_pkey}
                      timeout="auto"
                      unmountOnExit
                    >
                      <ul>
                        {mainMenu.reportMenus.map((reportMenu) => {
                          if (reportMenu.hidden) {
                            return <li key={reportMenu.menu_pkey}></li>;
                          }

                          if (mobileView.reportMenu) {
                            if (
                              reportMenu.menuType === ReportMenuType.OnlyDeskTop
                            ) {
                              return <li key={reportMenu.menu_pkey}></li>;
                            }
                          } else {
                            if (
                              reportMenu.menuType === ReportMenuType.OnlyMobile
                            ) {
                              return <li key={reportMenu.menu_pkey}></li>;
                            }
                          }

                          let reportUrl = `/${PathType.Report}/${reportMenu.menu_pkey}`;
                          return (
                            <li
                              className="menubar-depth2-li"
                              key={reportMenu.menu_pkey}
                            >
                              <Link
                                to={reportUrl}
                                className="menubar-depth2-link"
                                style={{ fontSize: "14px" }}
                                title={reportMenu.name}
                                onClick={() => {
                                  onCloseMenubar();
                                  if (`${location.pathname}` === reportUrl) {
                                    window.location.reload();
                                  }
                                }}
                              >
                                <div>
                                  <FiberManualRecordIcon
                                    style={{
                                      height: "18px",
                                      fontSize: "6px",
                                    }}
                                  />
                                  <span style={{ marginLeft: "6px" }}>
                                    {reportMenu.name}
                                  </span>
                                </div>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </Collapse>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </header>
  );
}

export default Header;
