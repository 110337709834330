import { r_setHeaderPage } from "../actions/headerPage";
import { r_setDataManagementPage } from "../actions/dataManagementPage";
import { r_setMenuInfo } from "../actions/menuInfo";
import { r_setUser } from "../actions/user";

export const datetimeFormat = (_datetime) => {
  return _datetime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

export const isEmpty = (val) => {
  if (val === null) return true;
  if (val === undefined) return true;
  if (val === "") return true;
  if (val !== null && typeof val === "object" && !Object.keys(val).length)
    return true;
  return false;
};

export const stringToJson = (str) => {
  let result;
  try {
    result = JSON.parse(str);
  } catch (e) {
    result = "";
  }
  return result;
};

export const jsonToString = (json) => {
  let result = JSON.stringify(json);
  return result === '""' ? "" : result;
};

export const getLocale = () => {
  let defaultLocale = navigator.language || navigator.userLanguage;
  if (isEmpty(defaultLocale)) {
    defaultLocale = "en";
  }
  return defaultLocale;
};

export const setUserCheck = (value) => {
  sessionStorage.setItem("userCheck", value);
};

export const getUserCheck = () => {
  let userCheck = sessionStorage.getItem("userCheck");
  userCheck = isEmpty(userCheck) ? false : userCheck;
  if (userCheck === "true") {
    userCheck = true;
  } else {
    userCheck = false;
  }
  return userCheck;
};

export const resetAppData = (dispatch, isReload) => {
  sessionStorage.clear();
  localStorage.clear();

  const newHeaderPage = { waitingAppData: false };
  dispatch(r_setHeaderPage(newHeaderPage));

  const newManagement = { menuData: {} };
  dispatch(r_setDataManagementPage(newManagement));

  const newMenuInfo = { data: [] };
  dispatch(r_setMenuInfo(newMenuInfo));

  const newUser = {
    id: "",
    mail: "",
    displayName: "",
    groups: [],
  };
  dispatch(r_setUser(newUser));

  if (isReload) {
    window.location.reload();
  }
};
