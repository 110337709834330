import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    // color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    // backgroundColor: theme.palette.common.black,
  },
  tooltipPlacementTop: {
    margin: "3px 0",
  },
  tooltipPlacementBottom: {
    margin: "3px 0",
  },
  tooltipPlacementLeft: {
    margin: "3px 0",
  },
  tooltipPlacementRight: {
    margin: "3px 0",
  },
}));

export default function CustomTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
