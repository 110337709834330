import Handsontable from "handsontable";

export const TableSettings = {
  activeHeaderClassName: "ht__active_highlight", // 현재 활성화된 모든 헤더 CSS 클래스
  allowEmpty: true, // null 값 허용
  allowHtml: false, // source 데이터 html 처리 (XSS 취약점 발생 가능성 높음)
  allowInsertColumn: false, // 새열 삽입 메뉴
  allowInsertRow: false, // 행 삽입 메뉴
  allowInvalid: true, // 유효하지 않는 값 표시 여부
  allowRemoveColumn: false, // 열 삭제 메뉴
  allowRemoveRow: false, // 행 삭제 메뉴
  autoColumnSize: false, // 열 너비 자동 조절  플러그인 기능
  autoRowSize: false, // 행 높이 자동 조절  플러그인 기능
  autoWrapCol: false, // 마지막 행에서 아래 화살표 시 첫행으로 이동
  autoWrapRow: false, // 마지막 열에서 탭, 오른쪽 화살표 시 첫 열로 이동
  bindRowsWithHeaders: false, // 테이블 행을 헤더와 바인딩 한다?  플러그인 기능
  cell: [], // 개별 셀 구성 옵션 적용
  cells: undefined, // 셀 구성 옵션 적용
  checkedTemplate: true, // 체크 박스 확인 클릭 시 반환 유형
  className: undefined, //  현재 선택된 모든 요소에 CSS 클래스 이름
  colHeaders: true, // 열 헤더
  collapsibleColumns: false, // 축고 가능한 열? 열 붕괴 허용?  플러그인 기능
  columnHeaderHeight: undefined, // 열 머리글의 높이 구성
  columns: [
    {
      title: " ",
      readOnly: true,
      width: 500,
      height: 100,
      renderer: function (hot, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);

        td.style.fontSize = "20px";
        td.style.fontWeight = "bold";
        td.style.textAlign = "center";
        td.style.verticalAlign = "middle";
      },
    },
  ], // 열 구성 옵션 적용
  columnSorting: false, // 열 정렬 옵션  플러그인 기능
  columnSummary: undefined, // 열 요약?  플러그인 기능
  colWidths: undefined, // 기본 열 너비  ※사용할 경우 AutoColumnSize 플러그인 비활성화
  commentedCellClassName: "htCommentCell", // 주석이 있는 셀에 CSS 클래스 이름
  comments: false, // 메모  플러그인 기능
  contextMenu: false, // 컨텍스트 메뉴  플러그인 기능
  copyable: true, // 복사 가능
  copyPaste: true, // 복사/붙여넣기 기능  플러그인 기능
  // correctFormat: false, // 날짜 형식 수정  ※columns 내부 옵션
  currentColClassName: undefined, // 현재 선택된 열의 모든 셀에 CSS 클래스 이름
  currentHeaderClassName: "ht__highlight", // 현재 표시 및 선택된 모든 헤더에 CSS 클래스 이름
  currentRowClassName: undefined, // 현재 선택 및 표시된 모든 셀에 CSS 클래스 이름
  customBorders: false, // 사용자 정의 테두리  플러그인 기능
  data: undefined, // 테이블 데이터
  dataSchema: undefined, // 데이터 스키마
  // dateFormat: "DD/MM/YYYY", // 날짜 형식  ※columns 내부 옵션
  // defaultDate: undefined, //비어있는 Data 셀에 표시되는 날짜  ※columns 내부 옵션
  disableVisualSelection: false, // 선택 항목이 표시되는 방식 구성
  dragToScroll: true, // 드래그하여 스크롤 기능  플러그인 기능
  dropdownMenu: false, // 드롭 다운 메뉴  플러그인 기능
  // editor: undefined, // 편집기 구성  ※columns 내부 옵션
  enterBeginsEditing: true, // 엔터 입력 시 편집 시작
  enterMoves: { col: 0, row: 1 }, // 엔터 입력 시 셀 이동
  fillHandle: false, // 자동 채우기  플러그인 기능
  // filter: true, // 입력한 내용과 일치하는 옵션만 표시(자동 완성)  ※columns 내부 옵션
  // filteringCaseSensitive: false, // 입력한 내용의 대소문자 구분(자동 완성)  ※columns 내부 옵션
  filters: false, // 필터  플러그인 기능
  fixedColumnsLeft: 0, // 왼쪽 열 고정
  fixedRowsBottom: 0, // 아래쪽 행 고정
  fixedRowsTop: 0, // 위쪽 행 고정
  formulas: undefined, // 엑셀 함수? 같은 기능?  플러그인 기능
  fragmentSelection: false, // 셀 내부 데이터 드래그 하여 영역 선택
  height: "100%", // 테이블 높이
  hiddenColumns: false, // 열 숨기기  플러그인 기능
  hiddenRows: false, // 행 숨기기  플러그인 기능
  invalidCellClassName: "htInvalid", // 유효성 검사 통과하지 못한 셀의 CSS 클래스 이름
  // label: undefined, // checkbox 셀의 레이블 구성  ※columns 내부 옵션
  language: "ko-KR", // 언어 설정(i18n)
  licenseKey: "f828d-946f3-731a2-84125-6903b", // 라이센스 키  ※비상업적 용도: non-commercial-and-evaluation
  locale: "ko-KR", // 로케일 구성(i18n)  ※columns 내부 추가 가능
  manualColumnFreeze: false, // 열 고정/해제 수동 조절  플러그인 기능
  ManualColumnMove: false, // 열 이동 가능 하도록 설정  플러그인 기능
  manualColumnResize: true, // 열 너비 수동 조절  플러그인 기능
  manualRowMove: false, // 행 이동 가능 하도록 설정  플러그인 기능
  manualRowResize: true, // 행 높이 수동 조절 가능  플러그인 기능
  maxCols: 1, // 최대 열 개수 Infinity
  maxRows: 1, // 최대 행 개수 Infinity
  mergeCells: false, // 셀 병합  플러그인 기능
  minCols: 0, // 최소 열 개수
  minRows: 0, // 최소 행 개수
  minSpareCols: 0, // 오른쪽 끝에 Spare 열의 최소 개수
  minSpareRows: 0, // 맨 아래쪽에 Spare 행의 최소 개수
  multiColumnSorting: false, // 다중 열 정렬  플러그인 기능
  nestedHeaders: undefined, // 중첩 헤더  플러그인 기능
  nestedRows: false, // 중첩 행  플러그인 기능
  noWordWrapClassName: "htNoWrap", // wordWrap 옵션이 설정된 모든 셀에 CSS 클래스 이름
  // numericFormat: undefined, // numeric 셀의 숫자 및 통화 형식  ※columns 내부 옵션
  observeDOMVisibility: true, // DOM에 표시되었음을 감지할 때마다 다시 렌더링 하기
  outsideClickDeselects: true, // 바깥 영역을 클릭하면 테이블의 선택 영역 지우기
  persistentState: false, // 새로고침으로 인한 테이블 상태 보존  플러그인 기능
  placeholder: undefined, // 빈셀에 표시되는 hint 문자
  placeholderCellClassName: "htPlaceholder", // placeholder가 포함된 셀의 CSS 클래스 이름
  preventOverflow: false, // 수직/수평 오버 플로우 방지??
  readOnly: true, // 읽기 전용
  readOnlyCellClassName: "htDimmed", // 읽기 전용 셀 클래스 이름
  renderAllRows: false, // 행 가상화 구성
  renderer: undefined, // 셀 렌더러 설정  ※columns 내부 추가 가능
  rowHeaders: true, // 행 헤더 표시
  rowHeaderWidth: undefined, // 행 헤더 너비
  rowHeights: 100, // 행 높이  ※23px 보다 작을 경우 렌더링 오류 발생,  ManualRowResize, AutoRowSize 플러그인의 최소 행 높이 설정
  search: true, // 검색  플러그인 기능
  selectionMode: "multiple", // 셀 선택 옵션
  // selectOptions: undefined, // 셀 select 셀에서 선택할 수 있는 옵션 구성  ※columns 내부 옵션
  // skipColumnOnPaste: false, // 열 붙여넣기 기능 막기  ※columns 내부 옵션
  // skipRowOnPaste: false, // 행 붙여넣기 기능 막기  ※cells 내부 옵션
  // sortByRelevance: true,  // 자동 완성 셀 목록이 옵션에 제공된 것과 동일한 순서로 정렬되도록 설정  ※columns 내부 옵션
  // source : undefined, // 자동 완성 및 드롭다운 셀에서 사용할 옵션 설정  ※columns 내부 옵션
  startCols: 5, // data 옵션이 제공 되지 않은 경우 초기 열 수
  startRows: 5, // data 옵션이 제공 되지 않은 경우 초기 행 수
  stretchH: "none", // 모든 열 너비의 계산된 합계와 다를 경우 열 반응
  // strict: undefined, // 자동 완성 셀의 엄격/지연 모드 구성  ※columns 내부 옵션
  tableClassName: undefined, // 컨테이너 요소의 모든 테이블 인스턴스의 CSS 클래스 이름
  tabMoves: { row: 0, col: 1 }, // 탭 키 누른 후의 반응
  // title: undefined, // 열 머리글 이름  ※columns 내부 옵션
  // trimDropdown: true, // 자동 완성 및 드롭다운 목록의 너비 구성  ※columns 내부 옵션
  trimRows: false, // 특정 행 숨기기  플러그인 기능
  type: "text", // 셀 편집기 유형  ※columns 내부 추가 가능
  // uncheckedTemplate: false, // 선택하지 않은 checkbox 셀 값을 구성  ※columns 내부 옵션
  undo: false, // 실행 취소 및 다시 실행  플러그인 기능
  // validator: undefined, // 셀 유효성 검사기  ※columns 내부 옵션
  viewportColumnRenderingOffset: "auto", // 보이는 부분 외부의 렌더링 열
  viewportRowRenderingOffset: "auto", // 보이는 부분 외부의 렌더링 행
  // visibleRows: 10, // 자동 완성 및 드롭다운의 목록 높이 설정  ※columns 내부 옵션
  width: "100%", // 테이블 너비
  // wordWrap: true, // 자동 줄 바꿈  ※columns 내부 옵션
  // isEmptyCol: function (visualColumnIndex) { }, // 열이 비어 있는지 확인할 경우 사용자 지정 정의
  // isEmptyRow: function (visualRowIndex) { }, // 행이 비어 있는지 확인할 경우 사용자 지정 정의
};
