import React from "react";
import { HotTable } from "@handsontable/react";
import { useTranslation } from "react-i18next";

const DataTable = ({ tableID, tableRef, tableSettings }) => {
  const { t } = useTranslation();
  tableSettings.data = [[t("There_is_no_content_display")]];
  return <HotTable id={tableID} ref={tableRef} settings={tableSettings} />;
};

export default React.memo(DataTable);
