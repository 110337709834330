import React, { useEffect, useRef, useState } from "react";

import { isTablet, isMobile } from "react-device-detect";

import { shallowEqual, useSelector } from "react-redux";

import { Error } from "../../containers";

import { CircularProgress } from "@material-ui/core";

import { ReportContainer } from "./components";

import { service, factories, models } from "powerbi-client";

import { jsonToString } from "../../utils/Utils";

import { sendBackend } from "../../utils/backend";

import { RenderType, ReportType } from "../../define";

import "./index.scss";

const powerbi = new service.Service(
  factories.hpmFactory,
  factories.wpmpFactory,
  factories.routerFactory
);

let r_headerPage;
let r_user;
let apiCallTime;

let reportData = undefined;
let embedData = undefined;

let reportRef;

let loadedReport = undefined;

let loadCheckTimer = undefined;
let refreshTokenTimer = undefined;

let errorCode;

const isDebug = false;
function Report(props) {
  reportRef = useRef(null);

  reportData = {
    workspaceId: props.workspaceId,
    reportId: props.reportId,
    reportType: props.reportType,
    reportPage: props.reportPage,
  };

  r_headerPage = useSelector((state) => state.headerPage, shallowEqual);
  r_user = useSelector((state) => state.user, shallowEqual);

  const [_errorCode, setErrorCode] = useState(0);
  errorCode = _errorCode;

  const [renderType, setRenderType] = useState(RenderType.Loading);

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }
  }, []);

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "getEmbedToken") {
          getEmbedToken(item.value);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    reportData = {
      workspaceId: props.workspaceId,
      reportId: props.reportId,
      reportType: props.reportType,
      reportPage: props.reportPage,
    };

    clearViewData();

    if (!reportData) {
      setErrorCode(100);
      setRenderType(RenderType.Error);
      return;
    }

    initReport();
  }, [props.workspaceId, props.reportId, props.reportType, props.reportPage]);

  const initReport = () => {
    setErrorCode(0);
    setRenderType(RenderType.Loading);
    setTimeout(() => getEmbedToken(false));
  };

  const getEmbedToken = (refreshToken) => {
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "getEmbedToken", value: refreshToken });
      setDelayDatas(newArray);
      return;
    }

    if (!reportData || (refreshToken && !embedData)) {
      clearViewData();
      setErrorCode(1000);
      setRenderType(RenderType.Wrong);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/embed/token";
    const queryData = {
      tokenUser: r_user,
      reportData: JSON.parse(JSON.stringify(reportData)),
    };
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      const reportContainer = getReportContainer();
      if (!reportContainer) {
        clearViewData();
        setErrorCode(1001);
        setRenderType(RenderType.Wrong);
        return;
      }

      if (!reportData || (refreshToken && !embedData)) {
        clearViewData();
        setErrorCode(1002);
        setRenderType(RenderType.Wrong);
        return;
      }

      let jsonA = jsonToString(reportData);
      let jsonB = jsonToString(queryData.reportData);

      if (jsonA !== jsonB) {
        clearViewData();
        setErrorCode(1003);
        setRenderType(RenderType.Wrong);
        return;
      }

      if (success && res && res.data) {
        const accessToken = res.data.accessToken;
        const embedUrl = res.data.embedUrl;
        if (accessToken && Array.isArray(embedUrl) && embedUrl.length > 0) {
          embedData = {
            reportData: queryData.reportData,
            accessToken: accessToken,
            embedUrl: embedUrl,
          };

          startRefreshTokenTimer(res.data.diffMin);

          if (refreshToken) {
            updateToken(accessToken);
          } else {
            embedRender();
          }
          return;
        }
      }
      clearViewData();
      setErrorCode(102);
      setRenderType(RenderType.Error);
    });

    apiCallTime = runTime;
  };

  const getReportContainer = () => {
    return reportRef && reportRef.current ? reportRef.current : undefined;
  };

  const startRefreshTokenTimer = (_diffMin) => {
    stopRefreshTokenTimer();

    const refreshMinGap = 10;
    const diffMin =
      _diffMin && _diffMin > 0 ? _diffMin - refreshMinGap : refreshMinGap;
    let ms = diffMin * 60 * 1000;
    ms = isDebug ? 5000 : ms;

    refreshTokenTimer = setTimeout(() => {
      stopRefreshTokenTimer();
      getEmbedToken(true);
    }, ms);
  };

  const stopRefreshTokenTimer = () => {
    if (refreshTokenTimer !== undefined) {
      clearTimeout(refreshTokenTimer);
      refreshTokenTimer = undefined;
    }
  };

  const startLoadCheckTimer = () => {
    stopLoadCheckTimer();

    let ms = 5000;
    loadCheckTimer = setTimeout(() => {
      stopLoadCheckTimer();
      if (!loadedReport && reportData && embedData) {
        let jsonA = jsonToString(reportData);
        let jsonB = jsonToString(embedData.reportData);
        if (jsonA === jsonB) {
          embedRender();
        }
      }
    }, ms);
  };

  const stopLoadCheckTimer = () => {
    if (loadCheckTimer !== undefined) {
      clearTimeout(loadCheckTimer);
      loadCheckTimer = undefined;
    }
  };

  const clearViewData = () => {
    apiCallTime = 0;

    // reportData = undefined;
    embedData = undefined;

    stopLoadCheckTimer();
    stopRefreshTokenTimer();

    setDelayDatas([]);
    setDelayTimer(false);

    const reportContainer = getReportContainer();
    if (reportContainer) {
      reportContainer.className = "";
      powerbi.reset(reportContainer);
    }
  };

  const updateToken = async (accessToken) => {
    try {
      const reportContainer = getReportContainer();
      if (!reportContainer) {
        return;
      }
      let report = powerbi.get(reportContainer);
      await report.setAccessToken(accessToken);
    } catch (err) {
      clearViewData();
      setErrorCode(103);
      setRenderType(RenderType.Error);
    }
  };

  const embedRender = () => {
    const reportContainer = getReportContainer();
    if (!reportContainer || !embedData || !embedData.reportData) {
      return;
    }

    if (isMobile && !isTablet) {
      reportContainer.className = "powerbi-embed-frame-m";
    } else {
      reportContainer.className = "powerbi-embed-frame";
    }
    powerbi.reset(reportContainer);

    let reportLoadConfig = {
      type: "report",
      tokenType: models.TokenType.Embed,
      accessToken: embedData.accessToken,
      embedUrl: embedData.embedUrl[0].embedUrl,
      id: embedData.reportData.reportId,
      viewMode: models.ViewMode.View,
      settings: {
        bars: { actionBar: { visible: false } },
        bookmarksPaneEnabled: false,
        customLayout: {
          displayOption: models.DisplayOption.FitToWidth,
          reportAlignment: models.ReportAlignment.Right,
        },
        filterPaneEnabled: false,
        hideErrors: true,
        layoutType: models.LayoutType.Master, // default: Master, mobile: Custom
        navContentPaneEnabled: false,
        panes: {
          bookmarks: { visible: false },
          fields: { expanded: false, visible: false },
          filters: { expanded: false, visible: false },
          pageNavigation: { visible: false },
          selection: { visible: false },
          syncSlicers: { visible: false },
          visualizations: { expanded: false, visible: false },
        },
        persistentFiltersEnabled: false,
        personalBookmarksEnabled: false,
      },
    };

    const reportType = embedData.reportData.reportType;
    if (reportType === ReportType.DesktopLayout) {
      reportLoadConfig.settings.layoutType = models.LayoutType.Master;
    } else if (reportType === ReportType.MobilePortraitLayout) {
      reportLoadConfig.settings.layoutType = models.LayoutType.MobilePortrait;
    } else if (reportType === ReportType.MobileLandscapeLayout) {
      reportLoadConfig.settings.layoutType = models.LayoutType.MobileLandscape;
    } else if (reportType === ReportType.AutoMobilePortraitLayout) {
      if (isMobile) {
        reportLoadConfig.settings.layoutType = models.LayoutType.MobilePortrait;
      } else {
        reportLoadConfig.settings.layoutType = models.LayoutType.Master;
      }
    } else if (reportType === ReportType.AutoMobileLandscapeLayout) {
      if (isMobile) {
        reportLoadConfig.settings.layoutType =
          models.LayoutType.MobileLandscape;
      } else {
        reportLoadConfig.settings.layoutType = models.LayoutType.Master;
      }
    } else if (reportType === ReportType.DesktopSize) {
      reportLoadConfig.settings.layoutType = models.LayoutType.Master;
    } else if (reportType === ReportType.MobileSize) {
      if (isMobile) {
        reportLoadConfig.settings.layoutType = models.LayoutType.Custom;
      } else {
        reportLoadConfig.settings.layoutType = models.LayoutType.Master;
      }
    }

    if (embedData.reportData.reportPage) {
      reportLoadConfig.pageName = embedData.reportData.reportPage;
    }

    let report = powerbi.embed(reportContainer, reportLoadConfig);

    loadedReport = false;

    report.off("loaded");
    report.on("loaded", async function () {
      loadedReport = true;
      stopLoadCheckTimer();

      setErrorCode(0);
      setRenderType(RenderType.Report);
    });

    report.off("error");
    report.on("error", async function (event) {
      let errorMsg = event.detail;
      console.log(errorMsg);
    });

    startLoadCheckTimer();
  };

  const getSectionClassName = () => {
    if (renderType === RenderType.Loading) {
      return "content loading-section";
    }
    return "content";
  };

  const viewRender = () => {
    switch (renderType) {
      case RenderType.Report:
        return <></>;
      case RenderType.Loading:
        return (
          <div>
            <CircularProgress style={{ color: "#AAAAAA" }} />
          </div>
        );
      case RenderType.Wrong:
        return <Error viewType="Report.Wrong" errorCode={errorCode} />;
      case RenderType.Error:
        return <Error viewType="Report.Error" errorCode={errorCode} />;
      default:
        return <></>;
    }
  };

  return (
    <div className="content-wrapper">
      <section className={getSectionClassName()}>
        {viewRender()}
        <div
          style={{
            display: renderType === RenderType.Loading ? "none" : "block",
          }}
        >
          <ReportContainer reportRef={reportRef} />
        </div>
      </section>
    </div>
  );
}

export default Report;
