const config = {
  animationDuration: 100,

  nodeWidth: 324,
  nodeHeight: 390,

  nodeWSpacing: 80,
  nodeHSpacing: 100,

  // nodePaddingX: 16,
  // nodePaddingY: 4,

  // userImageWidth: 78,

  nodeBorderWidth: 5,
  nodeBorderRadius: 10,
};

export default config;
