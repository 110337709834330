import axios from "axios";

axios.defaults.withCredentials = true;
export const request = (method, url, data) => {
  const DOMAIN = process.env.REACT_APP_BACKEND_URL;
  return axios({
    method,
    url: DOMAIN + url,
    data,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
};
