import React, { useEffect, useState, useRef } from "react";

import { shallowEqual, useSelector } from "react-redux";

import { useIsAuthenticated } from "@azure/msal-react";

import { Grid, Button, Menu, Divider } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import SaveIcon from "@material-ui/icons/Save";

import { useTranslation } from "react-i18next";

import { COLLECTION } from "../formats";

import { DrawerWidth } from "../../../define";
import { CustomTooltip, DataTable } from "../components";
import { MessageDialog, ConfirmDialog } from "../../../components";
import { sendBackend } from "../../../utils/backend";
import { onClearTable } from "../utils/Utils";
import { getUserCheck, isEmpty } from "../../../utils/Utils";
import { TableSettings } from "../utils/TableSettings";

import moment from "moment-timezone";
import "moment/min/locales.min";

import { v4 as uuid_v4} from "uuid";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${DrawerWidth}px`,
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${DrawerWidth}px)`,
    marginLeft: 0,
  },
  searchLabel: {
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#565656",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
}));

const StyledMenu = withStyles({
  paper: {
    color: "#212529",
    margin: 0,
    padding: ".1rem 0",
    overflow: "overlay",
    fontSize: "1rem",
    borderTop: "none",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.175)",
    listStyle: "none",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    backgroundColor: "rgba(255, 255, 255, 0.8)",

    "& .MuiMenu-list": {
      padding: "0 !important",
      width: "100% !important",
    },

    "& .MuiMenuItem-root": {
      clear: "both",
      width: "100%",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "block",
      padding: 0,
      textAlign: "inherit",
      fontWeight: 400,
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#16181b",
        backgroundColor: "rgba(233, 236, 239, 0.8)",
      },
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
      display: "block",
      padding: "4px 10px",
    },

    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },

    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 5px",
      fontSize: "16px",
      fontFamily: "Noto Sans",
    },
  },
})((props) => (
  <Menu
    keepMounted
    disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 2,
    position: "relative",
    border: "1px solid #dfdfdf",
    margin: "0px",
    padding: "0px",
    "&.Mui-disabled": {
      backgroundColor: "#cccccc",
    },
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const ToolbarBtn = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    margin: "0 1px 0",
    minWidth: "26px",
    maxWidth: "26px",
    minHeight: "26px",
    maxHeight: "26px",
    color: "inherit",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

let r_user;
let r_headerPage;

let hotTableRef;

let hotTableID = "content-hot-table";

let openProgessbar = false;

let returnData = undefined;

let apiCallTime;
function CollectionGrid(props) {
  const classes = useStyles();
  const searchRef = useRef(null);
  const tableRef = useRef(null);
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const year = t("DataManagement.year");
  const month = t("DataManagement.month");
  const weightText = t("DataManagement.weight");

  const undoTooltip = t("DataManagement.toolbar.undo");
  const redoTooltip = t("DataManagement.toolbar.redo");
  const saveTooltip = t("DataManagement.toolbar.save");
  const findReplaceTooltip = t("DataManagement.toolbar.find");

  const insertRowAboveText = t("DataManagement.contextMenu.Insert_row_above");
  const insertRowBelowText = t("DataManagement.contextMenu.Insert_row_below");

  const saveQ = t("MessageBox.saveQ");
  const searching = t("DataManagement.backdrop.searching");
  const saving = t("DataManagement.backdrop.saving");
  const unknown = t("DataManagement.unknown");

  const [yearData, setYearData] = useState(undefined);
  const [monthData, setMonthData] = useState(undefined);
  const weightData = ["g(gram)", "kg(kilogram)", "t(tonne)"];

  const [selectedYear, setSelectedYear] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedWeight, setSelectedWeight] = useState(weightData[0]);

  const [yearMenuRef, setYearMenuRef] = useState(undefined);
  const yearMenuOpen = Boolean(yearMenuRef);
  const [monthMenuRef, setMonthMenuRef] = useState(undefined);
  const monthMenuOpen = Boolean(monthMenuRef);

  const [weightMenuRef, setWeightMenuRef] = useState(undefined);
  const weightMenuOpen = Boolean(weightMenuRef);

  const [refreshSearch, setRefreshSearch] = useState(false);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgType, setConfirmDlgType] = useState(-1);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);
  const [progressData, setProgressData] = useState({
    open: false,
    content: "",
  });

  hotTableRef = useRef(null);
  r_user = useSelector((state) => state.user, shallowEqual);
  r_headerPage = useSelector((state) => state.headerPage, shallowEqual);

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }

    const onResize = () => {
      if (!tableRef || !tableRef.current) return;
      onRefreshTable(1);
    };

    const instance = hotTableRef.current
      ? hotTableRef.current.hotInstance
      : undefined;

    const onBeforePaste = (datas, coords) => {
      for (let d_row = 0; d_row < datas.length; d_row++) {
        const data = datas[d_row];
        for (let d_col = 0; d_col < data.length; d_col++) {
          data[d_col] = data[d_col].toString().trim();
          let isMinus = data[d_col].toString().indexOf("-") !== -1;
          let t_item = data[d_col]
            .toString()
            .replace(/\$/gi, "")
            .replace(/,/gi, "")
            .replace(/-/gi, "")
            .trim();
          if (isEmpty(t_item)) {
            data[d_col] = "";
          } else {
            let regex = new RegExp(/^[-]?\d+(?:[.]\d+)?$/, "g");
            if (regex.test(t_item)) {
              t_item = parseFloat(t_item);
              if (!isNaN(t_item)) {
                data[d_col] = `${t_item * (isMinus ? -1 : 1)}`;
              }
            }
          }
        }
      }
    };

    const onBeforeContextMenuSetItems = (menuItems) => {
      if (!instance) return;
      const selectedLast = instance.getSelectedLast();
      const row = selectedLast[0] < 0 ? 0 : selectedLast[0];
      const col = selectedLast[1] < 0 ? 0 : selectedLast[1];
      let firstRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[0] : selectedLast[2];
      firstRow = firstRow < 0 ? 0 : firstRow;
      let lastRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[2] : selectedLast[0];
      lastRow = lastRow < 0 ? 0 : lastRow;
      const createCount = lastRow - firstRow + 1;
      for (let loop1 = 0; loop1 < menuItems.length; loop1++) {
        let menuItem = menuItems[loop1];
        if (menuItem.key === "row_above" || menuItem.key === "row_below") {
          let t_Count = `${createCount}`;
          if (menuItem.key === "row_above") {
            t_Count = `${row === 0 ? 0 : createCount}`;
            menuItem.name = insertRowAboveText.replace("xx", t_Count);
          } else if (menuItem.key === "row_below") {
            menuItem.name = insertRowBelowText.replace("xx", t_Count);
          }
        }
      }
    };

    const isTableInputClosest = (event) => {
      const target = event.target;
      if (target.id === "find-text-field") {
        return false;
      }
      if (target.closest("#find-text-field") !== null) {
        return false;
      }
      return true;
    };

    const onBeforeKeyDown = (event) => {
      if (event.keyCode === 27) {
        return;
      }
      if (!isTableInputClosest(event) || openProgessbar) {
        event.stopImmediatePropagation();
        return;
      }
    };

    if (instance) {
      instance.addHook("beforePaste", onBeforePaste);
      instance.addHook(
        "beforeContextMenuSetItems",
        onBeforeContextMenuSetItems
      );
      instance.addHook("beforeKeyDown", onBeforeKeyDown);
    }

    window.addEventListener("resize", onResize);

    return () => {
      if (instance && !instance.isDestroyed) {
        instance.removeHook("beforePaste", onBeforePaste);
        instance.removeHook(
          "beforeContextMenuSetItems",
          onBeforeContextMenuSetItems
        );
        instance.removeHook("beforeKeyDown", onBeforeKeyDown);
      }

      window.removeEventListener("resize", onResize);

      returnData = undefined;
    };
  }, []);

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "handleSearch") {
          handleSearch(item.params, item.reload);
        } else if (item.key === "handleSaveBtn") {
          handleSaveBtn(item.params);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    const startDate = moment("2009-06-01");
    const nowDate = moment();
    const startYear = parseInt(startDate.format("YYYY"));
    const nowYear = parseInt(nowDate.format("YYYY"));
    let nowMonth = nowDate.format("MM");

    const newYearData = ["----"];
    for (let loop1 = 0; loop1 <= nowYear - startYear; loop1++) {
      newYearData.push(`${nowYear - loop1}`);
    }

    const newMonthData = ["--"];
    for (let loop1 = 0; loop1 < 12; loop1++) {
      newMonthData.push(`${loop1 + 1}`.padStart(2, "0"));
    }

    if (hotTableRef && hotTableRef.current) {
      const instance = hotTableRef.current.hotInstance;
      if (instance) {
        onClearTable(instance);
        instance.updateSettings(TableSettings);
        setRefreshSearch(true);
      }
    }

    setYearData(newYearData);
    setMonthData(newMonthData);
    setSelectedYear(nowYear);
    setSelectedMonth(nowMonth);

    let _params = { year: nowYear, month: nowMonth };
    setTimeout(() => {
      if (hotTableRef && hotTableRef.current) handleSearch(_params, false);
    });
  }, []);

  useEffect(() => {
    onRefreshTable(500);
  }, [props.refreshTable]);

  const handleSearch = (_params, repload) => {
    if (!r_user) return;
    if (!_params) return;

    if (_params.year !== "----")
      if (_params.month !== "--") {
        _params.datetime = moment(`${_params.year}-${_params.month}-01`);
        _params.startDate = _params.datetime.format("YYYY-MM-01");
        _params.endDate = _params.datetime
          .add(1, "months")
          .format("YYYY-MM-01");
      } else {
        _params.datetime = moment(`${_params.year}-01-01`);
        _params.startDate = _params.datetime.format("YYYY-MM-01");
        _params.endDate = _params.datetime.add(1, "years").format("YYYY-MM-01");
      }

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/search";
    const queryData = {
      tokenUser: r_user,
      kindType: 0,
      startDate: _params.startDate,
      endDate: _params.endDate,
    };

    queryData.fixedColData = COLLECTION.fixedColData;

    handleOpenProgress(searching);

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({
        key: "handleSearch",
        kindType: 0,
        params: _params,
        repload,
      });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return handleCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return handleCloseProgress();

      if (
        !hotTableRef ||
        !hotTableRef.current ||
        !hotTableRef.current.hotInstance
      )
        return handleCloseProgress();

      const instance = hotTableRef.current.hotInstance;
      if (instance.isDestroyed) return handleCloseProgress();

      onClearTable(instance);

      if (!success) {
        instance.updateSettings(TableSettings);
      } else if (res && res.data) {
        if (res.data.last_edit) {
          const editor = res.data.last_edit.editor;
          const updatedTime = moment
            .utc(res.data.last_edit.logged_date)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");

          if (props.handleEditor) props.handleEditor(editor);
          if (props.handleUpdatedTime) props.handleUpdatedTime(updatedTime);
        } else {
          if (props.handleEditor) props.handleEditor(unknown);
          if (props.handleUpdatedTime) props.handleUpdatedTime(unknown);
        }

        if (Array.isArray(res.data.list))
          returnData = res.data.list.map((val) => {
            switch (selectedWeight) {
              case weightData[1]:
                val.weight = val.weight / 1000;
                break;
              case weightData[2]:
                val.weight = val.weight / 1000000;
                break;
              default:
                break;
            }
            return val;
          });

        COLLECTION.initView(instance, returnData, selectedWeight);
      }

      setTimeout(() => {
        setRefreshSearch(true);
        handleCloseProgress();
      });
    });
    apiCallTime = runTime;
  };

  const handleSaveBtn = (_params) => {
    if (!_params) return;

    const instance = hotTableRef.current.hotInstance;
    const activeEditor = instance.getActiveEditor();
    if (activeEditor) {
      if (activeEditor.isOpened()) {
        activeEditor.finishEditing(false, false, () => {
          if (activeEditor.isOpened()) {
            activeEditor.close();
          }
        });
        return;
      }
    }

    const collection_list = [];
    const sourceData = instance.getSourceData();

    // const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    const weight_regexp = /^[0-9]+(.[0-9]+)?$/;
    const datetime_regexp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    for (let row = 0; row < sourceData.length; row++) {
      if (row > 0) {
        const apply_date = sourceData[row][0];
        let weight = sourceData[row][1] ? sourceData[row][1] : 0;
        const contents = sourceData[row][2]
          ? sourceData[row][2].substr(0, 500)
          : "";
        const collection_pkey = sourceData[row][3]
          ? sourceData[row][3]
          : uuid_v4();

        if (!weight) continue;
        if (!datetime_regexp.test(apply_date)) continue;
        if (!weight_regexp.test(weight)) continue;
        if (contents.length < 1) continue;
        if (collection_pkey.length < 1) continue;

        switch (selectedWeight) {
          case weightData[0]:
            break;
          case weightData[1]:
            weight = weight * 1000;
            break;
          case weightData[2]:
            weight = weight * 1000000;
            break;
          default:
            continue;
        }

        weight = parseFloat(weight);
        collection_list.push({ apply_date, weight, contents, collection_pkey });
      }
    }

    if (_params.year !== "----")
      if (_params.month !== "--") {
        _params.datetime = moment(`${_params.year}-${_params.month}-01`);
        _params.startDate = _params.datetime.format("YYYY-MM-01");
        _params.endDate = _params.datetime
          .add(1, "months")
          .format("YYYY-MM-01");
      } else {
        _params.datetime = moment(`${_params.year}-01-01`);
        _params.startDate = _params.datetime.format("YYYY-MM-01");
        _params.endDate = _params.datetime.add(1, "years").format("YYYY-MM-01");
      }

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/saveAll";
    const queryData = {
      tokenUser: r_user,
      kindType: 0,
      startDate: _params.startDate,
      endDate: _params.endDate,
      collection_list,
    };

    handleOpenProgress(saving);

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "handleSaveBtn", params: _params });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return handleCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return handleCloseProgress();

      if (
        !hotTableRef ||
        !hotTableRef.current ||
        !hotTableRef.current.hotInstance
      )
        return handleCloseProgress();

      const instance = hotTableRef.current.hotInstance;
      if (instance.isDestroyed) return handleCloseProgress();

      onClearTable(instance);
      setTimeout(() => handleSearch(_params));
      handleCloseProgress();
    });

    apiCallTime = runTime;
  };

  const handleYear = (newValue) => {
    setYearMenuRef(undefined);

    const month = newValue !== "----" ? selectedMonth : monthData[0];
    setSelectedYear(newValue);
    setSelectedMonth(month);

    let _params = { year: newValue, month };
    setTimeout(() => handleSearch(_params, false));
  };

  const handleMonth = (newValue) => {
    setMonthMenuRef(undefined);

    setSelectedMonth(newValue);
    let _params = { year: selectedYear, month: newValue };
    setTimeout(() => handleSearch(_params, false));
  };

  const handleWeightType = (newValue) => {
    setWeightMenuRef(undefined);
    setSelectedWeight(newValue);
    if (
      !hotTableRef ||
      !hotTableRef.current ||
      !hotTableRef.current.hotInstance
    )
      return;

    const instance = hotTableRef.current.hotInstance;
    if (instance.isDestroyed) return;

    const sourceData = instance.getSourceData();
    if (!Array.isArray(sourceData)) return;

    const returnData = [];
    const weight_regexp = /^[0-9]+(.[0-9]+)?$/;
    const datetime_regexp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    sourceData.forEach((val, idx) => {
      if (idx === 0 || idx === sourceData.length) return;
      if (!val[0] && !val[1] && !val[2]) return;

      if (!val[0] || !datetime_regexp.test(val[0])) val[0] = "";

      if (val[1] && weight_regexp.test(val[1]))
        switch (selectedWeight) {
          case weightData[0]:
            if (newValue === weightData[1]) val[1] = val[1] / 1000;
            else val[1] = val[1] / 1000000;
            break;
          case weightData[1]:
            if (newValue === weightData[0]) val[1] = val[1] * 1000;
            else val[1] = val[1] / 1000;
            break;
          case weightData[2]:
            if (newValue === weightData[0]) val[1] = val[1] * 1000000;
            else val[1] = val[1] * 1000;
            break;
          default:
            return;
        }
      else val[1] = 0;

      returnData.push({
        apply_date: val[0],
        weight: val[1],
        contents: val[2],
        collection_pkey: val[3],
      });
    });

    onClearTable(instance);
    setTimeout(() => COLLECTION.initView(instance, returnData, newValue));
  };

  const handleUndoClick = () => {
    const instance = hotTableRef.current.hotInstance;
    const plugin = instance.getPlugin("UndoRedo");
    if (plugin.isUndoAvailable()) {
      plugin.undo();
    }
    // onUndoRedoCheck(false);
  };

  const handleRedoClick = () => {
    const instance = hotTableRef.current.hotInstance;
    const plugin = instance.getPlugin("UndoRedo");
    if (plugin.isRedoAvailable()) {
      plugin.redo();
    }
    // onUndoRedoCheck(false);
  };

  const onRefreshTable = (delayTime) => {
    setTimeout(() => {
      if (isEmpty(hotTableRef) || isEmpty(hotTableRef.current)) return;
      const instance = hotTableRef.current.hotInstance;
      instance.render();
    }, delayTime);
  };

  const handleOpenProgress = (content) => {
    setProgressData({ open: true, content: content });
  };

  const handleCloseProgress = () => {
    setProgressData({ open: false, content: "" });
  };

  const handleCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const handleOpenConfirmDlg = (content) => {
    setConfirmDlgTitle("");
    setConfirmDlgContent(content);
    setOpenConfirmDlg(true);
  };

  const handleOkConfirmDlg = () => {
    if (confirmDlgContent === saveQ) {
      const _params = {
        year: `${selectedYear}`,
        month: `${selectedMonth}`,
      };
      handleSaveBtn(_params);
    }
    handleCloseConfirmDlg();
  };

  const handleCancelConfirmDlg = () => {
    handleCloseConfirmDlg();
  };

  const handleCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  const renderDatetime = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ height: "100%", paddingTop: "10px" }}
      >
        <Grid item xs>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Button1
                onClick={(e) => setYearMenuRef(e.currentTarget)}
                style={{ width: "100px", height: "32px" }}
              >
                <span
                  className="text-font"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#262626",
                    lineHeight: "0px",
                  }}
                >
                  {selectedYear}
                </span>
              </Button1>
              <StyledMenu
                anchorEl={yearMenuRef}
                open={yearMenuOpen}
                onClose={() => setYearMenuRef(undefined)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableScrollLock={true}
                style={{ marginTop: "0px" }}
              >
                <div className="year-layout">
                  {Array.isArray(yearData) &&
                    yearData.map((value, _idx) => {
                      return (
                        <div
                          key={`year-key-${_idx}`}
                          className="year-item"
                          onClick={() => handleYear(value)}
                        >
                          {value}
                        </div>
                      );
                    })}
                </div>
              </StyledMenu>
            </Grid>
            <Grid item style={{ paddingLeft: "10px" }}>
              <span
                className="text-font"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#262626",
                  lineHeight: "32px",
                }}
              >
                {year}
              </span>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <Button1
                disabled={selectedYear === "----"}
                onClick={(e) => setMonthMenuRef(e.currentTarget)}
                style={{ height: "32px" }}
              >
                <span
                  className="text-font"
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#262626",
                    lineHeight: "0px",
                  }}
                >
                  {selectedMonth}
                </span>
              </Button1>
              <StyledMenu
                anchorEl={monthMenuRef}
                open={monthMenuOpen}
                onClose={() => setMonthMenuRef(undefined)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableScrollLock={true}
                style={{ marginTop: "0px" }}
              >
                <div className="month-layout">
                  {Array.isArray(monthData) &&
                    monthData.map((value, _idx) => {
                      return (
                        <div
                          key={`month-key-${_idx}`}
                          className="month-item"
                          onClick={() => handleMonth(value)}
                        >
                          {value}
                        </div>
                      );
                    })}
                </div>
              </StyledMenu>
            </Grid>
            <Grid item style={{ paddingLeft: "10px" }}>
              <span
                className="text-font"
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#262626",
                  lineHeight: "32px",
                }}
              >
                {month}
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item style={{ paddingLeft: "10px" }}>
              <span
                className="text-font"
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#262626",
                }}
              >
                {`${weightText}:`}
              </span>
            </Grid>
            <Grid item style={{ paddingLeft: "20px" }}>
              <Button1
                onClick={(e) => setWeightMenuRef(e.currentTarget)}
                style={{ height: "32px", width: "120px" }}
              >
                <span
                  className="text-font"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#262626",
                    textTransform: "none",
                  }}
                >
                  {selectedWeight} ▼
                </span>
              </Button1>
              <StyledMenu
                anchorEl={weightMenuRef}
                open={weightMenuOpen}
                onClose={() => setWeightMenuRef(undefined)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableScrollLock={true}
                style={{ marginTop: "0px", marginLeft: "8px" }}
              >
                <div className="weight-layout">
                  {Array.isArray(weightData) &&
                    weightData.map((value, _idx) => {
                      return (
                        <div
                          key={`weight-key-${_idx}`}
                          className="weight-item"
                          onClick={() => handleWeightType(value)}
                        >
                          {value}
                        </div>
                      );
                    })}
                </div>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const gridToolbarRender = () => {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{
            backgroundColor: "#f3f3f3",
            padding: "0 8px",
            borderTop: "1px solid #aeaeae",
            borderBottom: "1px solid #aeaeae",
          }}
        >
          <Grid item>
            <CustomTooltip title={undoTooltip}>
              <span>
                <ToolbarBtn onClick={handleUndoClick}>
                  <UndoIcon style={{ fontSize: "18px" }} />
                </ToolbarBtn>
              </span>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <CustomTooltip title={redoTooltip}>
              <span>
                <ToolbarBtn onClick={handleRedoClick}>
                  <RedoIcon style={{ fontSize: "18px" }} />
                </ToolbarBtn>
              </span>
            </CustomTooltip>
          </Grid>
          <Grid item>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: "7px 3px 7px",
                height: "26px",
              }}
            />
          </Grid>
          <Grid item>
            <CustomTooltip title={saveTooltip}>
              <span>
                <ToolbarBtn onClick={() => handleOpenConfirmDlg(saveQ)}>
                  {<SaveIcon style={{ fontSize: "18px" }} />}
                </ToolbarBtn>
              </span>
            </CustomTooltip>
          </Grid>
        </Grid>
      </div>
    );
  };

  const tableRender = () => {
    if (!tableRef || !tableRef.current) return <></>;

    return (
      <div
        className="table-view-container"
        style={{
          width: "100%",
          height: "100%",
          border: "1px solid #c2c2c2",
        }}
      >
        <DataTable
          tableID={hotTableID}
          tableRef={hotTableRef}
          tableSettings={TableSettings}
        />
      </div>
    );
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ height: "100%", padding: "0px 8px" }}
    >
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={handleCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={handleOkConfirmDlg}
          onCancelConfirmDlg={handleCancelConfirmDlg}
          onCloseConfirmDlg={handleCloseConfirmDlg}
        />
      )}

      <div id="search-section" ref={searchRef} style={{ width: "100%" }} />
      <Grid item style={{ padding: "0px 0px 10px" }}>
        {renderDatetime()}
      </Grid>
      <Grid item>{gridToolbarRender()}</Grid>
      <Grid item xs style={{ padding: "0px 0px 10px" }}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          style={{ width: "100%", height: "100%" }}
        >
          <Grid
            xs
            item
            innerRef={tableRef}
            style={{ width: "100%", minHeight: "100%" }}
          >
            <div style={{ width: "100%", height: "100%" }}>{tableRender()}</div>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={progressData.open}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressData.content}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
}

export default CollectionGrid;
