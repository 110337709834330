import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default exportOrgChartPdf;

function exportOrgChartPdf({ loadConfig }) {
  const config = loadConfig();
  const { id } = config;
  const { downloadEvent, getFileName } = config;
  const { nodeWidth, nodeHeight } = config;
  const { viewLeft, viewRight, viewTop, viewBottom } = config;

  downloadEvent(true);
  const sizeMargin = 500;
  const wGap = nodeWidth + nodeWidth + sizeMargin;
  const hGap = nodeHeight + nodeHeight + sizeMargin;
  const width =
    (viewLeft < 0 ? viewLeft * -1 : viewLeft) +
    (viewRight < 0 ? viewRight * -1 : viewRight) +
    wGap;
  const height =
    (viewTop < 0 ? viewTop * -1 : viewTop) +
    (viewBottom < 0 ? viewBottom * -1 : viewBottom) +
    hGap;
  const viewBox = `${viewLeft - wGap / 2} ${
    viewTop - hGap / 2
  } ${width} ${height}`;

  const canvasContainer = document.getElementById(`${id}-canvas-container`);
  var oldCanvas = canvasContainer.querySelector("#newCanvas");
  if (oldCanvas) {
    oldCanvas.remove();
  }

  const svgContainer = document.getElementById(`${id}-svg-container`);
  var oldLayout = svgContainer.querySelector("#pdf-chart-layout");
  if (oldLayout) {
    oldLayout.remove();
  }

  var newLayout = document.createElement("div");
  newLayout.id = "pdf-chart-layout";
  svgContainer.appendChild(newLayout);

  var titleContainer = document.getElementById("title-container");
  titleContainer = titleContainer.cloneNode(true);
  newLayout.appendChild(titleContainer);

  var tableContainer = document.getElementById("children-table-container");
  var tableHeight = tableContainer.offsetTop + tableContainer.offsetHeight;
  tableContainer = tableContainer.cloneNode(true);
  newLayout.appendChild(tableContainer);

  var scoreContainer = document.getElementById("score-container");
  scoreContainer = scoreContainer.cloneNode(true);
  scoreContainer.style.top = `${tableHeight + 32}px`;
  newLayout.appendChild(scoreContainer);

  var newSvg = document.getElementById("chart-container");
  newSvg = newSvg.cloneNode(true);
  newSvg.id = "newSvg";
  newSvg.setAttribute("width", width);
  newSvg.setAttribute("height", height);
  newSvg.setAttribute("viewBox", viewBox);
  newSvg.setAttribute("enable-background", ` new ${viewBox}`);

  const g = newSvg.querySelector("g");
  g.setAttribute("transform", `translate(0, 0) scale(1)`);

  let svgImage = new Image();
  var svgHtml = new XMLSerializer().serializeToString(newSvg);
  svgImage.src =
    "data:image/svg+xml;base64," + Buffer.from(svgHtml).toString("base64");

  let widthGap = width < 14400 ? 1 : 14400 / width;
  let heightGap = height < 14400 ? 1 : 14400 / height;

  let newWidth = width * (widthGap < heightGap ? widthGap : heightGap);
  let newHeight = height * (widthGap < heightGap ? widthGap : heightGap);

  svgImage.onload = () => {
    html2canvas(svgContainer, {
      allowTaint: true, // false
      backgroundColor: "#efefef", // #ffffff
      canvas: null, // null
      foreignObjectRendering: false, // false
      imageTimeout: 15000, // 15000
      ignoreElements: (element) => false, // (element) => false
      logging: false, // true
      onclone: (cloneDoc, element) => {
        element.style.display = "";
      }, // null
      proxy: null, // null
      removeContainer: true, // true
      scale: window.devicePixelRatio, // window.devicePixelRatio
      useCORS: true, // false
      width: newWidth, // Element width
      height: newHeight, // Element height
      x: 0, // Element x-offset
      y: 0, // Element y-offset
      scrollX: 0, // Element scrollX
      scrollY: 0, // Element scrollY
      windowWidth: newWidth, // window.innerWidth
      windowHeight: newHeight, // window.innerHeight
    }).then((canvas) => {
      var context = canvas.getContext("2d");
      context.drawImage(svgImage, 0, 0, canvas.width, canvas.height);

      newLayout.appendChild(newSvg);

      canvas.id = "newCanvas";
      canvasContainer.appendChild(canvas);

      const canvasData = canvas.toDataURL("image/jpeg", 1.0);

      const fileName = getFileName(config);

      const pdf = new jsPDF({
        orientation: canvas.width < canvas.height ? "portrait" : "landscape",
        unit: "px",
        hotfixes: ["px_scaling"],
        format: [canvas.width, canvas.height],
      });

      pdf.addImage(canvasData, "JPEG", 0, 0, canvas.width, canvas.height);
      pdf.save(fileName, { returnPromise: true }).then((data) => {
        //
      });
      downloadEvent(false);
    });
  };
}
