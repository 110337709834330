import React, { useState, useEffect, useRef } from "react";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { useIsAuthenticated } from "@azure/msal-react";

import { r_setDataManagementPage } from "../../actions/dataManagementPage";

import clsx from "clsx";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Grid, Typography } from "@material-ui/core";
import { Button, IconButton, Menu } from "@material-ui/core";
import { InputBase } from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";

import { DataTable, LeftSidebar } from "./components";

import { useTranslation } from "react-i18next";

import { DrawerWidth, HeaderMinHeight } from "../../define";

import {
  CollectionCalender,
  CollectionGrid,
  ReductionGrid,
  ReductionCalender,
} from "./render";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${DrawerWidth}px`,
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${DrawerWidth}px)`,
    marginLeft: 0,
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

let r_dataManagementPage;

const t_nowMenu = {
  menu_pkey: "",
  group_pkey: "",
  group_name: "",
  subName: "",
  fileName: "",
  tableName: "",
  dataTitle: "",
  type: "",
};
let nowMenu;

const containerHeightGap = HeaderMinHeight * -1 - 81;
function DataManagement(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const searchRef = useRef(null);

  const [menuDatas, setMenuDatas] = useState([]);

  const [_nowMenu, setNowMenu] = useState(t_nowMenu);
  nowMenu = _nowMenu;

  const lastEditor = t("DataManagement.lastEditor");
  const lastUpdatedTime = t("DataManagement.lastUpdatedTime");

  const unknown = t("DataManagement.unknown");
  const [editor, setEditor] = useState(unknown);
  const [updatedTime, setUpdatedTime] = useState(unknown);

  const [openSidebar, setOpenSidebar] = useState(true);
  const [renderType, setRenderType] = useState(undefined);
  const [kindType, setKindType] = useState(undefined);

  const [refreshTable, setRefreshTable] = useState(500);

  r_dataManagementPage = useSelector(
    (state) => state.dataManagementPage,
    shallowEqual
  );

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }

    return () => {};
  }, []);

  useEffect(() => {
    let newMenuDatas = [];
    if (props.menuItem) {
      if (Array.isArray(props.menuItem.dataManagementMenus)) {
        props.menuItem.dataManagementMenus.forEach((menuData) => {
          if (!menuData.hidden) {
            newMenuDatas.push(menuData);
          }
        });
      }
    }

    let idx;
    if (nowMenu && nowMenu.menu_pkey) {
      idx = newMenuDatas.findIndex(
        (item) => item.menu_pkey === nowMenu.menu_pkey
      );
      if (idx === -1) {
        window.location.reload();
        return;
      }
    } else {
      if (newMenuDatas.length > 0) {
        let menuData = newMenuDatas[0];

        if (
          r_dataManagementPage &&
          r_dataManagementPage.menuData &&
          r_dataManagementPage.menuData.menu_pkey
        ) {
          idx = newMenuDatas.findIndex(
            (item) => item.menu_pkey === r_dataManagementPage.menuData.menu_pkey
          );
          if (idx !== -1) {
            menuData = newMenuDatas[idx];
          }
        }

        let newMenuData = {
          menu_pkey: menuData.menu_pkey,
          group_pkey: menuData.group_pkey,
          group_name: menuData.group_name,
          subName: menuData.subName,
          fileName: menuData.fileName,
          tableName: menuData.tableName,
          dataTitle: menuData.dataTitle,
          type: menuData.type,
        };

        setNowMenu(newMenuData);
        dispatch(r_setDataManagementPage({ menuData: newMenuData }));
      }
    }

    setMenuDatas(newMenuDatas);
  }, [props.menuItem]);

  useEffect(() => {
    if (!nowMenu || !nowMenu.menu_pkey) {
      return;
    }

    onChangeView();
  }, [nowMenu]);

  const onChangeView = () => {
    let menu_pkey = nowMenu.menu_pkey.split("/");
    setKindType(menu_pkey[0] !== "Collection");
    setRenderType(menu_pkey[1] !== "Grid");
  };

  const handleSidebar = () => {
    if (!openSidebar) {
      onOpenSidebar();
    } else {
      onCloseSidebar();
    }
  };

  const onOpenSidebar = () => {
    setOpenSidebar(true);
    setRefreshTable(!refreshTable);
  };

  const onCloseSidebar = () => {
    setOpenSidebar(false);
    setRefreshTable(!refreshTable);
  };

  const onChangeSidebar = (menuData) => {
    if (nowMenu.menu_pkey === menuData.menu_pkey) {
      return;
    }

    let newMenuData = {
      menu_pkey: menuData.menu_pkey,
      group_pkey: menuData.group_pkey,
      group_name: menuData.group_name,
      subName: menuData.subName,
      fileName: menuData.fileName,
      tableName: menuData.tableName,
      dataTitle: menuData.dataTitle,
      type: menuData.type,
    };

    setNowMenu(newMenuData);
    dispatch(r_setDataManagementPage({ menuData: newMenuData }));
  };

  const handleEditor = (_editor) => setEditor(_editor);

  const handleUpdatedTime = (_updatedTime) => setUpdatedTime(_updatedTime);

  return (
    <>
      <div id="DataManagement" className="content-wrapper">
        <div className="content-root" style={{ height: "100%" }}>
          <CssBaseline />
          <LeftSidebar
            open={openSidebar}
            onClose={onCloseSidebar}
            nowMenu={nowMenu}
            menuDatas={menuDatas}
            onChangeSidebar={onChangeSidebar}
          />

          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openSidebar,
            })}
            open={openSidebar}
            style={{ backgroundColor: "#f4f6f9" }}
          >
            <Grid
              container
              direction="column"
              alignItems="stretch"
              style={{
                minWidth: "660px",
                minHeight: "800px",
                height: "100%",
              }}
            >
              <Grid item style={{ borderBottom: "1px solid #cdcdcd" }}>
                <Grid
                  container
                  direction="row"
                  alignItems="stretch"
                  style={{ padding: "10px 8px", minHeight: "34px" }}
                >
                  <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        style={{ marginRight: openSidebar ? "" : "5px" }}
                      >
                        <IconButton
                          color="inherit"
                          aria-label="openSidebar drawer"
                          onClick={handleSidebar}
                          edge="start"
                          size="small"
                          style={{ display: openSidebar ? "none" : "" }}
                        >
                          <MenuIcon style={{ fontSize: "15px" }} />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Typography className="title-label">
                          {nowMenu.group_name}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginLeft: "10px" }}>
                        <Typography className="sub-title-label">
                          {`[${nowMenu.dataTitle}]`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs style={{ minWidth: "400px" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      style={{ height: "100%" }}
                    >
                      <Grid item>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {`${lastEditor}:`}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginRight: "15px" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginLeft: "5px",
                            color: "#262626",
                          }}
                        >{`${editor || ""}`}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          style={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {`${lastUpdatedTime}:`}
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginRight: "15px" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            marginLeft: "5px",
                            color: "#262626",
                          }}
                        >{`${updatedTime || ""}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                {renderType ? (
                  kindType ? (
                    <ReductionCalender
                      renderType={renderType}
                      openSidebar={openSidebar}
                      handleEditor={handleEditor}
                      handleUpdatedTime={handleUpdatedTime}
                    />
                  ) : (
                    <CollectionCalender
                      renderType={renderType}
                      openSidebar={openSidebar}
                      handleEditor={handleEditor}
                      handleUpdatedTime={handleUpdatedTime}
                    />
                  )
                ) : kindType ? (
                  <ReductionGrid
                    refreshTable={refreshTable}
                    handleEditor={handleEditor}
                    handleUpdatedTime={handleUpdatedTime}
                  />
                ) : (
                  <CollectionGrid
                    refreshTable={refreshTable}
                    handleEditor={handleEditor}
                    handleUpdatedTime={handleUpdatedTime}
                  />
                )}
              </Grid>
            </Grid>
          </main>
        </div>
      </div>
    </>
  );
}

export default DataManagement;
