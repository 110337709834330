import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
    // outlineOffset: "none !important",
    // boxShadow: "none",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);
