import Handsontable from "handsontable";
import { isEmpty } from "../../../../utils/Utils";
import { NumericEditor } from "../../components/editors/numeric";

const fixedColData = [
  ["물품 코드", "material_code"],
  ["물품 명", "material_name"],
  ["플라스틱 코드", "plastic_code"],
  ["플라스틱 종류", "plastic_type"],  
  ["초기 중량(g)", "infancy"],
  ["절감 중량(g)", "reduction"],  
  ["현재 중량(g)", "weight"],
  ["적용 날짜", "apply_date"],
  ["절감 사유 (최대 500자)", "contents"],
];

const getContextMenu = () => {
  const rowMenuDisabled = (instance, isRemove, isAbove) => {
    const settings = instance.getSettings();
    if (!isEmpty(settings.readOnly) && settings.readOnly) {
      return true;
    }

    if (isRemove || isAbove) {
      const selectedLast = instance.getSelectedLast();
      const row = selectedLast[0] < 0 ? 0 : selectedLast[0];
      if (row === 0) {
        return true;
      }
    }
    return false;
  };

  const rowMenuCallback = (instance, isRemove, isAbove) => {
    if (isRemove) {
      let removeRows = [];
      const selected = instance.getSelected();
      for (let loop1 = 0; loop1 < selected.length; loop1++) {
        const _item = selected[loop1];

        let firstRow = _item[0] <= _item[2] ? _item[0] : _item[2];
        firstRow = firstRow < 0 ? 0 : firstRow;

        let lastRow = _item[0] <= _item[2] ? _item[2] : _item[0];
        lastRow = lastRow < 0 ? 0 : lastRow;

        let itemCount = 0;
        let startIndex = 0;
        for (let row = firstRow; row < lastRow + 1; row++) {
          if (row === 0) {
            continue;
          }
          if (itemCount === 0) {
            startIndex = row;
          }
          itemCount++;
        }
        if (itemCount > 0) {
          removeRows.push([startIndex, itemCount]);
        }
      }
      if (removeRows.length === 0) {
        return;
      }
      instance.alter("remove_row", removeRows);
    } else {
      const selectedLast = instance.getSelectedLast();

      const row = selectedLast[0] < 0 ? 0 : selectedLast[0];

      if (row === 0 && isAbove) {
        return;
      }

      let firstRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[0] : selectedLast[2];
      firstRow = firstRow < 0 ? 0 : firstRow;

      let lastRow =
        selectedLast[0] <= selectedLast[2] ? selectedLast[2] : selectedLast[0];
      lastRow = lastRow < 0 ? 0 : lastRow;

      const createCount = lastRow - firstRow + 1;

      instance.alter("insert_row", isAbove ? row : row + 1, createCount);
    }
  };

  return {
    items: {
      row_above: {
        disabled: function () {
          return rowMenuDisabled(this, false, true);
        },
        callback: function () {
          rowMenuCallback(this, false, true);
        },
      },
      row_below: {
        disabled: function () {
          return rowMenuDisabled(this, false, false);
        },
        callback: function () {
          rowMenuCallback(this, false, false);
        },
      },
      remove_row: {
        disabled: function () {
          return rowMenuDisabled(this, true, false);
        },
        callback: function () {
          rowMenuCallback(this, true, false);
        },
      },
    },
  };
};

function Renderer(instance, td, row, col, prop, value, cellProperties) {
  let isNumeric = [4, 5, 6].includes(col);

  if (isNumeric) {
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
    if (isEmpty(value) || value === 0) {
      td.innerText = "-";
      td.style.textAlign = "right";
    }
  } else {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
  }

  if (row < 1) {
    td.style.fontWeight = "bold";
    td.style.textAlign = "center";
    td.style.verticalAlign = "middle";
    td.style.color = "#000";
    td.style.backgroundColor = "#bbbbbb";
  } else if (col === 0 || col === 2) {
    td.style.textAlign = "center";
  } else if (col === 1 || col === 3 || col === 4 || col === 6) {
    td.style.verticalAlign = "middle";
    td.style.color = "#000";
    td.style.backgroundColor = "#eeeeee";
  } else if (col === 5) {
    td.style.verticalAlign = "middle";
    td.style.color = "#000";
  } else if (col === 7) {
    td.style.textAlign = "center";
    td.style.verticalAlign = "middle";
    td.style.color = "#000";
  }
}

function Cells(row, col, prop) {
  const instance = this.instance;
  const settings = instance.getSettings();

  const cellProperties = { readOnly: settings.readOnly };

  if (!cellProperties.readOnly) {
    const columns = settings.columns;
    if (Array.isArray(columns)) {
      if (columns[col] && columns[col].readOnly) {
        cellProperties.readOnly = true;
      }
    }
  }

  if (!cellProperties.readOnly) {
    if (row === 0 || col === 1 || col === 3 || col === 4 || col === 6) {
      cellProperties.readOnly = true;
    }
  }

  return cellProperties;
}

const getCols = () => {
  const textValidator = (value, regexp, callback) => {
    if (regexp) callback(regexp.test(value));
    else callback(true);
  };

  const cols = [
    {
      type: "text",
      width: 150,
      validator: (value, callback) =>
        textValidator(value, /^(.){6,6}$/, callback),
      renderer: Renderer,
    },
    {
      type: "text",
      width: 300,
      validator: (value, callback) =>
        textValidator(value, /^(.){1,255}$/, callback),
      renderer: Renderer,
    },
    {
      type: "text",
      width: 150,
      validator: (value, callback) =>
        textValidator(value, /^(.){5,5}$/, callback),
      renderer: Renderer,
    },
    {
      type: "text",
      width: 300,
      validator: (value, callback) =>
        textValidator(value, /^(.){1,255}$/, callback),
      renderer: Renderer,
    },
    {
      type: "numeric",
      numericFormat: {
        pattern: "0,",
        culture: "ko-KR",
      },
      width: 120,
      editor: NumericEditor,
      renderer: Renderer,
    },
    {
      type: "numeric",
      numericFormat: {
        pattern: "0,",
        culture: "ko-KR",
      },
      width: 120,
      editor: NumericEditor,
      renderer: Renderer,
    },
    {
      type: "numeric",
      numericFormat: {
        pattern: "0,",
        culture: "ko-KR",
      },
      width: 120,
      editor: NumericEditor,
      renderer: Renderer,
    },
    {
      type: "text",
      width: 150,
      validator: (value, callback) =>
        textValidator(
          value,
          /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
          callback
        ),
      renderer: Renderer,
    },
    {
      type: "text",
      width: 380,
      validator: (value, callback) =>
        textValidator(value, /^(.){1,500}$/, callback),
      renderer: Renderer,
    },
  ];
  return cols;
};

const initView = (instance, resultData) => {
  const datas = [];

  let _data = [];
  for (let col = 0; col < fixedColData.length; col++) {
    const colName = fixedColData[col][0];
    _data.push(colName);
  }
  datas.push(_data);

  if (Array.isArray(resultData) || resultData.length !== 0) {
    for (let loop1 = 0; loop1 < resultData.length; loop1++) {
      _data = [];
      for (let col = 0; col < fixedColData.length; col++) {
        const fieldName = fixedColData[col][1];
        let _value = resultData[loop1][fieldName];
        if (isEmpty(_value)) {
          _value = "";
        } else if (col === 4 || col === 5 || col === 6) _value = _value.toFixed(2);

        _data.push(_value);
      }
      datas.push(_data);
    }
  }

  _data = [];
  for (let col = 0; col < fixedColData.length; col++) {
    _data.push("");
  }
  datas.push(_data);

  const columns = getCols();
  instance.updateSettings({
    columns: columns,
    data: datas,
    cells: Cells,
    fixedRowsTop: 2,
    fixedColumnsLeft: 1,
    contextMenu: getContextMenu(),
    allowInsertRow: true,
    allowRemoveRow: true,
    maxRows: 100000,
    maxCols: columns.length,
    readOnly: false,
    undo: true,
  });
};

export const REDUCTION = {
  fixedColData: fixedColData,
  Cells: Cells,
  getCols: getCols,
  initView: initView,
};
