import React, { useEffect } from "react";

import { useDispatch } from "react-redux";

import { r_setUser } from "../../actions/user";
import { r_setMenuInfo } from "../../actions/menuInfo";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { makeStyles } from "@material-ui/core/styles";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { RequestProfileData } from "../../utils/azure";

import { sendBackend } from "../../utils/backend";

import { useTranslation } from "react-i18next";

import { getUserCheck, setUserCheck } from "../../utils/Utils";
import { resetAppData, isEmpty } from "../../utils/Utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function UserCheck() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && !getUserCheck()) {
      RequestProfileData(instance, accounts, dispatch, ({ user }) => {
        const apiPath = "v1/auth/login";
        const queryData = { user };
        sendBackend(true, apiPath, queryData, (success, res) => {
          if (!isAuthenticated || getUserCheck()) {
            return;
          }

          if (success && res && res.data && res.data.user) {
            let newUser = res.data.user;
            let newGroups = [];
            if (!isEmpty(res.data.groups)) {
              newGroups = res.data.groups;
            }
            newUser.groups = newGroups;

            let newMenuInfo = { data: [] };
            if (!isEmpty(res.data.mainMenus)) {
              newMenuInfo.data = res.data.mainMenus;
            }

            setUserCheck(true);
            dispatch(r_setUser(newUser));
            dispatch(r_setMenuInfo(newMenuInfo));

            setTimeout(() => window.location.reload());
            return;
          }

          resetAppData(dispatch, true);
        });
      });
    }
  }, [isAuthenticated]);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <CircularProgress color="inherit" />
        </Grid>
        <Grid item style={{ marginTop: "10px" }}>
          <p>{t("UserCheck.backdrop.contents")}</p>
        </Grid>
      </Grid>
    </Backdrop>
  );
}

export default UserCheck;
