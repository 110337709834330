import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Fade from "@material-ui/core/Fade";
import InputBase from "@material-ui/core/InputBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";

import CloseIcon from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { isEmpty } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "425px",
    margin: "0px",
    zIndex: 1100,
  },
  title: {
    width: "100%",
    height: "27px",
    background: "#fbfbfb",
    border: "solid 1px #bebebe",
    padding: "0px 8px",
  },
  titleLabel: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    color: "#262626",
  },
  content: {
    width: "100%",
    border: "solid 1px #cfcfcf",
    background: "#f1f1f1",
  },
  contentPaper: {
    width: "100%",
    margin: "8px 8px 0px 8px",
    padding: "8px",
    border: "solid 1px #dbdbdb",
    background: "#ffffff",
  },
  contentAction: {
    width: "100%",
    margin: "0px",
  },
  checkLabel: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    color: "#262626",
  },
  resultLabel: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    color: "#262626",
  },
  btnRoot: {
    height: "22px",
    background: "#dfdfdf",
    border: "solid 1px #bbbbbb",
    borderRadius: "0px",
  },
  btnLabel: {
    fontFamily: "Noto Sans",
    fontSize: "11px",
    color: "#262626",
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const CustomTextField = withStyles(() => ({
  root: {
    width: "100%",
  },
  input: {
    height: "14px",
    fontSize: "12px",
    fontFamily: "Noto Sans",
    color: "#262626",
    backgroundColor: "#ffffff",
    border: "solid 1px #c9c9c9",
    padding: "5px 8px",
    "&:focus": {
      borderColor: "#7ec5f8",
    },
  },
}))(InputBase);

const ColorCheckbox = withStyles({
  root: {
    color: "#00b5ef",
    "&$checked": {
      color: "#00b5ef",
    },
    width: "20px",
    height: "20px",
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

let _searchText;
let _caseMatch;
let _fullMatch;
function SearchPopper(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const titleName = t("SearchPopper.find");

  const findName = t("SearchPopper.find");
  const previousFindName = t("SearchPopper.previousFind");
  const closeName = t("SearchPopper.close");
  const caseMatchName = t("SearchPopper.matchCase");
  const fullMatchName = t("SearchPopper.identical");
  const refindName = t("SearchPopper.reSearch");

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchELE, setSearchELE] = useState(null);

  const [searchText, setSearchText] = useState("");
  _searchText = searchText;
  const [caseMatch, setCaseMatch] = useState(false);
  _caseMatch = caseMatch;
  const [fullMatch, setFullMatch] = useState(false);
  _fullMatch = fullMatch;

  const [searchResult, setSearchResult] = useState([]);
  const [resultIndex, setResultIndex] = useState(0);
  const [resultCount, setResultCount] = useState(null);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const instance = props.hotTableRef.current.hotInstance;

    const customQueryMethod = (queryStr, value) => {
      if (isEmpty(queryStr) || isEmpty(value)) {
        return false;
      }
      if (!queryStr.toLowerCase || queryStr.length === 0) {
        return false;
      }

      if (!_caseMatch && !_fullMatch) {
        return (
          value.toString().toLowerCase().indexOf(queryStr.toLowerCase()) !== -1
        );
      }

      if (_caseMatch && _fullMatch) {
        return value.toString() === queryStr;
      }

      if (_caseMatch) {
        return value.toString().indexOf(queryStr) !== -1;
      }

      if (_fullMatch) {
        return value.toString().toLowerCase() === queryStr.toLowerCase();
      }
    };

    const searchSetting = {
      queryMethod: function (queryStr, value) {
        return customQueryMethod(queryStr, value);
      },
    };

    instance.updateSettings({ search: searchSetting });
  }, []);

  useEffect(() => {
    const searchRef = props.searchRef;
    if (!isEmpty(searchRef) && !isEmpty(searchRef.current)) {
      setAnchorEl(searchRef.current);
    }
  }, [props.searchRef]);

  useEffect(() => {
    if (props.open) {
      if (!isEmpty(_searchText)) {
        setRefresh(true);
      }
    } else {
      handleSearch("", -1);
      const instance = props.hotTableRef.current.hotInstance;
      const editor = instance.getActiveEditor();
      if (editor) {
        editor.focus();
      }
    }
  }, [props.open]);

  useEffect(() => {
    setTimeout(() => {
      if (searchELE) {
        if (searchELE !== document.activeElement) {
          searchELE.focus();
        }
      }
    }, 100);
  }, [searchELE]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
      onRefreshSearch(-1);
    }
  }, [refresh]);

  useEffect(() => {
    if (props.refresh) {
      props.setRefresh(false);
      setRefresh(true);
    }
  }, [props.refresh]);

  const handleClose = () => {
    props.onClose();
  };

  const handleTextField = (event) => {
    const { value } = event.target;
    setSearchText(value);
    handleSearch(value, -1);
  };

  const handleCheckBox = (event) => {
    const { name } = event.target;
    if (name === "case-match-check") {
      setCaseMatch(!caseMatch);
    } else if (name === "full-match-check") {
      setFullMatch(!fullMatch);
    }
    setRefresh(true);
  };

  const handleFind = (previous) => {
    if (!previous) {
      selectNextMatch(resultIndex);
    } else {
      selectPreviousMatch(resultIndex);
    }
  };

  const handleSearch = (value, focusIndex) => {
    const instance = props.hotTableRef.current.hotInstance;
    const searchPlugin = instance.getPlugin("search");
    const newResult = searchPlugin.query(value);
    setResultIndex(focusIndex);
    setSearchResult(newResult);
    setResultCount(newResult.length);
    instance.render();
  };

  const selectNextMatch = (focusIndex) => {
    if (resultCount > 0) {
      if (isWrongCheckAllQuery()) {
        return false;
      }
      let nextFocusIndex = focusIndex + 1;
      nextFocusIndex = nextFocusIndex < resultCount ? nextFocusIndex : 0;
      setResultIndex(nextFocusIndex);
      onChangeSearchCell(nextFocusIndex);
      return true;
    }
    return false;
  };

  const selectPreviousMatch = (focusIndex) => {
    if (resultCount > 0) {
      if (isWrongCheckAllQuery()) {
        return false;
      }
      let preFocusIndex = focusIndex - 1;
      preFocusIndex = preFocusIndex < 0 ? resultCount - 1 : preFocusIndex;
      setResultIndex(preFocusIndex);
      onChangeSearchCell(preFocusIndex);
      return true;
    }
    return false;
  };

  const onChangeSearchCell = (focusIndex) => {
    if (resultCount > 0) {
      const searchCell = searchResult[focusIndex];
      const instance = props.hotTableRef.current.hotInstance;
      instance.selectCell(
        searchCell.row,
        searchCell.col,
        searchCell.row,
        searchCell.col,
        false,
        true
      );
      instance.scrollViewportTo(
        searchCell.row,
        searchCell.col,
        false,
        false,
        true
      );
    }
  };

  const onSearchKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleTextField(event);
      selectNextMatch(resultIndex);
    } else if (event.keyCode === 27) {
      handleClose();
    }
  };

  const isWrongQueryData = (focusIndex) => {
    if (resultCount > 0 && focusIndex > -1) {
      const searchCell = searchResult[focusIndex];
      const instance = props.hotTableRef.current.hotInstance;
      let origin = instance.getDataAtCell(searchCell.row, searchCell.col);
      origin = isEmpty(origin) ? "" : origin;
      if (origin !== searchCell.data) {
        onRefreshSearch(-1);
        return true;
      }
    }
    return false;
  };

  const isWrongCheckAllQuery = () => {
    if (resultCount > 0) {
      for (let loop1 = 0; loop1 < searchResult.length; loop1++) {
        if (isWrongQueryData(loop1)) {
          return true;
        }
      }
    }
    return false;
  };

  const onRefreshSearch = (focusIndex) => {
    handleSearch(searchText, focusIndex);
  };

  return (
    <>
      <Popper
        open={props.open}
        anchorEl={anchorEl}
        className={classes.root}
        placement="bottom-end"
        disablePortal={false}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "window",
          },
          arrow: {
            enabled: false,
          },
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Grid container>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                className={classes.title}
              >
                <Grid item xs={8}>
                  <Typography className={classes.titleLabel}>
                    {titleName}
                  </Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <IconButton1
                    size="small"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon style={{ fontSize: "17px" }} />
                  </IconButton1>
                </Grid>
              </Grid>
              <Grid item container className={classes.content}>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.contentPaper}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={2}>
                      <Typography className={classes.titleLabel}>
                        {findName}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <CustomTextField
                        id="find-text-field"
                        inputRef={(input) => {
                          setSearchELE(input);
                        }}
                        defaultValue={searchText}
                        onKeyDown={onSearchKeyPress}
                        onChange={handleTextField}
                        autoFocus={true}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.contentAction}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ padding: "5px 8px" }}
                  >
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Grid item>
                        <FormControlLabel
                          control={
                            <ColorCheckbox
                              checked={caseMatch}
                              onChange={handleCheckBox}
                              name="case-match-check"
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                            />
                          }
                          label={
                            <Typography
                              className={classes.checkLabel}
                              style={{ margin: "0px 0px 0px 5px" }}
                            >
                              {caseMatchName}
                            </Typography>
                          }
                          style={{ margin: 0 }}
                        />
                      </Grid>
                      <Grid item>
                        <FormControlLabel
                          control={
                            <ColorCheckbox
                              checked={fullMatch}
                              onChange={handleCheckBox}
                              name="full-match-check"
                              icon={
                                <CheckBoxOutlineBlankIcon fontSize="small" />
                              }
                              checkedIcon={<CheckBoxIcon fontSize="small" />}
                            />
                          }
                          label={
                            <Typography
                              className={classes.checkLabel}
                              style={{ margin: "0px 0px 0px 5px" }}
                            >
                              {fullMatchName}
                            </Typography>
                          }
                          style={{ margin: "0px 0px 0px 10px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button1
                          size="small"
                          className={classes.btnRoot}
                          onClick={() => onRefreshSearch(-1)}
                        >
                          <Typography className={classes.btnLabel}>
                            {refindName}
                          </Typography>
                        </Button1>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container style={{ padding: "0px 8px 8px 8px" }}>
                    <Grid
                      item
                      xs={3}
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        className={classes.resultLabel}
                        style={{
                          padding: "0px 8px",
                          color: resultCount <= 0 ? "#FFFFFF" : "#FFFFFF",
                          background: resultCount <= 0 ? "#AA0000" : "#00AA00",
                        }}
                      >
                        {resultCount > 0 ? resultIndex + 1 : 0}
                        &nbsp;/&nbsp;
                        {resultCount || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button1
                          size="small"
                          className={classes.btnRoot}
                          onClick={() => handleFind(true)}
                          style={{ marginRight: "10px" }}
                        >
                          <Typography className={classes.btnLabel}>
                            {previousFindName}
                          </Typography>
                        </Button1>
                      </Grid>
                      <Grid item>
                        <Button1
                          size="small"
                          className={classes.btnRoot}
                          onClick={() => handleFind(false)}
                          style={{ marginRight: "10px" }}
                        >
                          <Typography className={classes.btnLabel}>
                            {findName}
                          </Typography>
                        </Button1>
                      </Grid>
                      <Grid item>
                        <Button1
                          size="small"
                          className={classes.btnRoot}
                          onClick={handleClose}
                          style={{ marginLeft: "15px" }}
                        >
                          <Typography className={classes.btnLabel}>
                            {closeName}
                          </Typography>
                        </Button1>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default SearchPopper;
