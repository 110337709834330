import React, { useState, useEffect, useMemo } from "react";

import { useIsAuthenticated } from "@azure/msal-react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";

import { GroupListDialog } from "../components";

import { MessageDialog, ConfirmDialog } from "../../../../components";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../../utils/Utils";
import { getUserCheck, getLocale } from "../../../../utils/Utils";

import moment from "moment-timezone";

import "moment/min/locales.min";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./index.scss";

moment.locale(getLocale());

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
}));

const contentStyles = makeStyles((theme) => ({
  title: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#181D1F",
  },
  content1: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#181D1F",
  },
  content2: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#181D1F",
  },
  btn: {
    height: "30px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  backdrop: {
    color: "#fff",
    zIndex: 1301,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#edit-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const t_userData = {
  id: "",
  mail: "",
  displayName: "",
  groups: [],
};

let r_headerPage;
let r_user;
let apiCallTime;

let inviteView;

let originData;
let userData;
function EditDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  r_headerPage = props.r_headerPage;
  r_user = props.r_user;

  const [_inviteView, setInviteView] = useState(!props.userData);
  inviteView = _inviteView;

  const [_originData, setOriginData] = useState(
    props.userData ? props.userData : t_userData
  );
  originData = _originData;

  const [_userData, setUserData] = useState(
    props.userData ? props.userData : t_userData
  );
  userData = _userData;

  const [mailText, setMailText] = useState("");

  const [changedData, setChangedData] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [gridLoading, setGridLoading] = useState(false);
  const [progressText, setProgressText] = useState("");

  const [gridApi, setGridApi] = useState(null);

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);

  const [agGridRowData, setAgGridRowData] = useState(userData.groups);

  const [openGroupListDlg, setOpenGroupListDlg] = useState(false);
  const [groupDatas, setGroupDatas] = useState([]);
  const [filterGroups, setFilterGroups] = useState([]);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const textRenderer = (value) => {
    let strVal = `${value}`;
    let element = document.createElement("div");
    element.title = strVal;
    element.innerHTML = strVal;
    return element;
  };

  const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let strA = isEmpty(valueA) ? "" : valueA.toString();
    strA = strA.toLowerCase();
    let strB = isEmpty(valueB) ? "" : valueB.toString();
    strB = strB.toLowerCase();

    return strA.localeCompare(strB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: t("UserManagement.GroupView.name"),
        headerTooltip: t("UserManagement.GroupView.name"),
        field: "name",
        width: 500,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.name),
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      wrapText: true,
      autoHeight: true,
    }),
    []
  );

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "getUser") {
          getUser(item.value);
        } else if (item.key === "getGroupDatas") {
          getGroupDatas();
        } else if (item.key === "saveUser") {
          saveUser(item.value);
        } else if (item.key === "inviteUsers") {
          inviteUsers(item.userDatas, item.forceClose);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    const handleResize = () => {
      //
    };

    if (gridApi) {
      if (!inviteView) {
        getUser(originData.id);
      }

      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]);

  const onOpenProgress = (contents) => {
    setProgressText(contents);
    setGridLoading(true);
  };

  const onCloseProgress = () => {
    setGridLoading(false);
    setProgressText("");
  };

  const getUser = (user_id) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.loading"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "getUser", value: user_id });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/user";
    const queryData = { tokenUser: r_user, id: user_id };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let newUserData = {};
      if (success && res && res.data) {
        if (!isEmpty(res.data.userData)) {
          newUserData = {
            id: res.data.userData.id,
            mail: res.data.userData.mail,
            displayName: res.data.userData.displayName,
            descriptions: res.data.userData.descriptions,
            groups: res.data.userData.groups,
            loggedInTime: res.data.userData.loggedInTime,
            loggedInCount: res.data.userData.loggedInCount,
            createdTime: res.data.userData.createdTime,
          };
        }

        setInviteView(false);

        setOriginData(newUserData);
        setUserData(newUserData);

        setAgGridRowData(newUserData.groups);

        onCloseProgress();
        return;
      }

      onCloseProgress();
      props.onCloseEditDlg(true);
    });

    apiCallTime = runTime;
  };

  const getGroupDatas = () => {
    onOpenProgress(t("UserManagement.UserView.backdrop.loading"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "getGroupDatas", value: "" });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/groups";
    const queryData = { tokenUser: r_user };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let newGroupDatas = [];
      let newFilterGroups = [];
      if (success && res && res.data) {
        if (!isEmpty(res.data.groupDatas)) {
          for (let i = 0; i < res.data.groupDatas.length; i++) {
            let groupData = res.data.groupDatas[i];
            newGroupDatas.push({
              group_pkey: groupData.group_pkey,
              name: groupData.name,
            });

            let idx = userData.groups.findIndex(
              (item) => item.group_pkey === groupData.group_pkey
            );
            if (idx === -1) {
              newFilterGroups.push({
                group_pkey: groupData.group_pkey,
                name: groupData.name,
              });
            }
          }
        }
      }
      setGroupDatas(newGroupDatas);

      if (newFilterGroups.length > 0) {
        onOpenGroupListDlg(newFilterGroups);
      } else {
        onOpenMessageDlg(t("UserManagement.UserView.noGroupDatas"));
      }

      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const saveUser = (forceClose) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.saving"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "saveUser", value: forceClose });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/user";
    const queryData = {
      tokenUser: r_user,
      dataType: "save",
      userData: userData,
    };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      if (success) {
        setChangedData(true);

        onCloseProgress();
        if (forceClose) {
          props.onCloseEditDlg(true);
        } else {
          getUser(userData.id);
          onOpenMessageDlg(t("UserManagement.successSave"));
        }
        return;
      }

      onCloseProgress();
      if (forceClose) {
        props.onCloseEditDlg(true);
      } else {
        onOpenMessageDlg(t("UserManagement.failureSave"));
      }
    });

    apiCallTime = runTime;
  };

  const inviteUsers = (userDatas, forceClose) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.inviting"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "inviteUsers", userDatas, forceClose });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/users";
    const queryData = {
      tokenUser: r_user,
      dataType: "invite",
      userDatas: userDatas,
    };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      if (success && res && res.data) {
        if (!isEmpty(res.data.resultDatas)) {
          let resultDatas = res.data.resultDatas;
          if (Array.isArray(resultDatas) && resultDatas.length > 0) {
            let resultData = resultDatas[0];
            if (resultData.id) {
              setChangedData(true);
              onCloseProgress();

              if (forceClose) {
                props.onCloseEditDlg(true);
              } else {
                getUser(resultData.id);
                onOpenMessageDlg(t("UserManagement.UserView.successInvite"));
              }
              return;
            }
          }
        }
      }

      onOpenMessageDlg(t("UserManagement.UserView.failureInvite"));
      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const handleAdd = () => {
    getGroupDatas();
  };

  const handleDelete = () => {
    if (gridApi) {
      let selectedNodes = gridApi.getSelectedNodes();
      if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
        const newUserData = JSON.parse(JSON.stringify(userData));
        for (let i = 0; i < selectedNodes.length; i++) {
          let groupData = selectedNodes[i].data;
          let idx = newUserData.groups.findIndex(
            (item) => item.group_pkey === groupData.group_pkey
          );
          if (idx !== -1) {
            newUserData.groups.splice(idx, 1);
          }
        }

        setUserData(newUserData);
        setAgGridRowData(newUserData.groups);
      } else {
        onOpenMessageDlg(t("UserManagement.UserView.noSelectedGroup"));
      }
    }
  };

  const isEmail = (asValue) => {
    var regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regExp.test(asValue);
  };

  const handleSave = (forceClose) => {
    if (inviteView) {
      if (!mailText) {
        onOpenMessageDlg(t("UserManagement.UserView.emptyMail"));
        return;
      }

      if (!isEmail(mailText)) {
        onOpenMessageDlg(t("UserManagement.UserView.invalidMailFormat"));
        return;
      }
      inviteUsers([{ mail: mailText, groups: userData.groups }], forceClose);
    } else {
      saveUser(forceClose);
    }
  };

  const handleChange = (e) => {
    const { id } = e.target;
    if (id === "id") {
      const value = e.target.value;
      setMailText(value);
    } else if (id === "search") {
      const value = e.target.value;
      setSearchText(value);
      onGridSearch(value);
    }
  };

  const handleSearch = () => {
    onGridSearch(searchText);
  };

  const onGridSearch = (_searchText) => {
    if (gridApi) {
      gridApi.setQuickFilter(_searchText);
    }
  };

  const dateToString = (value) => {
    const format = "YYYY-MM-DD"; // YYYY-MM-DD HH:mm:ss.SSS
    const dataDate = moment.utc(value).local().format(format);
    return dataDate;
  };

  const onCellClicked = (event) => {
    const selected = event.node.isSelected();
    event.node.setSelected(!selected);
  };

  const handleClose = () => {
    let notSame;
    if (inviteView) {
      notSame = mailText && isEmail(mailText);
    } else {
      notSame = originData.groups.length !== userData.groups.length;
      if (originData.groups.length === userData.groups.length) {
        for (let i = 0; i < originData.groups.length; i++) {
          let idx = userData.groups.findIndex(
            (item) => item.group_pkey === originData.groups[i].group_pkey
          );
          if (idx === -1) {
            notSame = true;
            break;
          }
        }
      }
    }

    if (notSame) {
      onOpenConfirmDlg(t("UserManagement.UserView.saveAndCloseChanges"));
      return;
    }

    props.onCloseEditDlg(changedData);
  };

  const onOpenGroupListDlg = (datas) => {
    setFilterGroups(datas);
    setOpenGroupListDlg(true);
  };

  const onCloseGroupListDlg = (groups) => {
    if (Array.isArray(groups) && groups.length > 0) {
      const newUserData = JSON.parse(JSON.stringify(userData));
      for (let i = 0; i < groups.length; i++) {
        newUserData.groups.push(groups[i]);
      }

      setUserData(newUserData);
      setAgGridRowData(newUserData.groups);
    }

    setFilterGroups([]);
    setOpenGroupListDlg(false);
  };

  const onOpenMessageDlg = (content) => {
    setOpenMessageDlg(true);
    setMessageDlgTitle("");
    setMessageDlgContent(content);
  };

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const onOpenConfirmDlg = (content) => {
    setOpenConfirmDlg(true);
    setConfirmDlgTitle("");
    setConfirmDlgContent(content);
  };

  const onOkConfirmDlg = () => {
    onCloseConfirmDlg();

    handleSave(true);
  };

  const onCancelConfirmDlg = () => {
    onCloseConfirmDlg();

    props.onCloseEditDlg(changedData);
  };

  const onCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCloseConfirmDlg}
        />
      )}

      <Dialog
        id="edit-dialog"
        maxWidth="sm"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle className={s_header.titlebar} id="edit-dialog-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={10} container direction="row" alignItems="center">
              <Typography className={s_header.title}>
                {inviteView
                  ? t("UserManagement.UserView.inviteUser")
                  : t("UserManagement.UserView.updateUser")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ padding: "16px 20px", margin: 0, width: "100%" }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="baseline"
              style={{ marginBottom: "20px" }}
            >
              <Grid
                item
                xs={10}
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                {inviteView ? (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="baseline"
                  >
                    <Grid item style={{ width: "100%" }}>
                      <div className="input-group" style={{ width: "100%" }}>
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <span className="fas fa-envelope" />
                          </div>
                        </div>
                        <input
                          id="id"
                          type="email"
                          className="form-control rounded-0"
                          placeholder={t(
                            "UserManagement.UserView.idPlaceholder"
                          )}
                          autoFocus={true}
                          onChange={handleChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{ width: "100%", marginBottom: "10px" }}
                    >
                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="baseline"
                      >
                        <Grid item style={{ marginRight: "5px" }}>
                          <Typography className={s_content.title}>
                            {t("UserManagement.UserView.id")}:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={s_content.content1}>
                            {userData.mail}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="baseline"
                      >
                        <Grid item style={{ marginRight: "5px" }}>
                          <Typography className={s_content.title}>
                            {t("UserManagement.UserView.displayName")}:
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={s_content.content1}>
                            {userData.displayName ? userData.displayName : ""}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item style={{ marginRight: "5px" }}>
                        <Typography className={s_content.title}>
                          {t("UserManagement.UserView.loggedInTime")}:
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginRight: "20px" }}>
                        <Typography className={s_content.content2}>
                          {dateToString(userData.loggedInTime)}
                        </Typography>
                      </Grid>

                      <Grid item style={{ marginRight: "5px" }}>
                        <Typography className={s_content.title}>
                          {t("UserManagement.UserView.loggedInCount")}:
                        </Typography>
                      </Grid>
                      <Grid item style={{ marginRight: "20px" }}>
                        <Typography className={s_content.content2}>
                          {userData.loggedInCount}
                        </Typography>
                      </Grid>

                      <Grid item style={{ marginRight: "5px" }}>
                        <Typography className={s_content.title}>
                          {t("UserManagement.UserView.createdTime")}:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={s_content.content2}>
                          {dateToString(userData.createdTime)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                item
                xs={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button1
                  className={s_content.btn}
                  onClick={() => handleSave(false)}
                >
                  <Typography className={s_content.btn_label}>
                    {t("UserManagement.GroupView.save")}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ paddingLeft: "8px", margin: 0, width: "100%" }}
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ margin: "0.5rem 0px" }}
              >
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <button
                      type="button"
                      className="btn btn-default btn-sm btn-custom"
                      onClick={handleAdd}
                    >
                      <i className="fas fa-plus"></i>
                      {t("UserManagement.UserView.addGroup")}
                    </button>
                  </Grid>
                  <Grid item>
                    <button
                      type="button"
                      className="btn btn-default btn-sm btn-custom ml-2"
                      onClick={handleDelete}
                    >
                      <i className="fas fa-minus"></i>
                      {t("UserManagement.UserView.deleteGroup")}
                    </button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <div className="search-layout" style={{ width: "100%" }}>
                      <div className="input-group">
                        <input
                          id="search"
                          type="text"
                          className="form-control rounded-0"
                          placeholder={t("UserManagement.UserView.search")}
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-default btn-sm btn-flat"
                            onClick={handleSearch}
                          >
                            <i className="fa fa-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item style={{ width: "100%", height: "400px" }}>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    rowData={agGridRowData}
                    rowStyle={{ cursor: "pointer" }}
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={500}
                    headerHeight={40}
                    suppressMovableColumns="true"
                    suppressRowClickSelection="true"
                    enableCellTextSelection="true"
                    suppressCellSelection="true"
                    onGridReady={(params) => {
                      setGridApi(params.api);
                    }}
                    localeText={{
                      noRowsToShow: " ",
                    }}
                    onColumnResized={(params) => {
                      params.api.resetRowHeights();
                    }}
                    onCellClicked={onCellClicked}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {openGroupListDlg && (
        <GroupListDialog
          isOpen={openGroupListDlg}
          groupDatas={filterGroups}
          onCloseGroupListDlg={onCloseGroupListDlg}
        />
      )}

      <Backdrop className={s_content.backdrop} open={gridLoading}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressText}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
}

export default EditDialog;
