export const i18n_ko = {
  UserCheck: {
    backdrop: {
      contents: "사용자 정보 확인중",
    },
  },
  Login: {
    title: "로그인하여 서비스를 시작하세요",
    login: "로그인",
    backdrop: {
      contents: "로그인 진행중",
    },
  },
  Blank: {
    Wrong: {
      title: "표시할 리포트 페이지가 없습니다.",
      message: "관리자에게 문의해주세요.",
    },
    Error: {
      title: "존재하지 않거나 접근할 수 없는 페이지입니다.",
      message: "웹 주소를 확인해주세요.",
    },
    error: {
      title: "접근할 수 없는 페이지",
      message: "웹 주소가 올바른지 확인하세요.",
    },
  },
  Header: {
    logout: "로그아웃",
    administration: "관리자 전용",
    backdrop: {
      logout: {
        contents: "로그아웃 진행중",
      },
    },
  },
  Admin: {
    title: "관리자 전용",
    subTitle: "회원 목록",
    number: "순 번",
    mail: "메 일",
    displayName: "이 름",
    cust_no: "회 원 번 호",
    descriptions: "설 명",
    dashboard: "대 시 보 드",
    shortcuts: "바로가기",
    refresh: "새로고침",
    search: "검색",
    noData: "표시할 데이터가 없습니다.",
    backdrop: {
      contents: "로딩중",
    },
  },
  NotFound: {
    title: "페이지 오류",
    message: "잘못된 접근으로 표시할 페이지가 없습니다.",
  },
  Report: {
    Wrong: {
      title: "잘못된 보고서 정보입니다.",
      message: "페이지 새로고침(F5)이나 서비스 관리자에게 문의해주세요.",
    },
    OnlyDeskTop: {
      title: "데스크탑 전용 페이지입니다.",
      message:
        "태블릿의 경우 가로 모드로 전환하거나 데스크탑에서 웹 페이지를 접속해주세요.",
    },
    OnlyMobile: {
      title: "모바일 전용 페이지입니다.",
      message:
        "태블릿의 경우 세로 모드로 전환하거나 모바일에서 웹 페이지를 접속해주세요.",
    },
    Error: {
      title: "보고서를 불러오지 못했습니다.",
      message: "페이지 새로고침(F5)이나 서비스 관리자에게 문의해주세요.",
    },
    wrong: {
      title: "잘못된 정보",
      message: "새로고침(F5)이나 서비스 관리자에게 문의하세요.",
    },
    error: {
      title: "잘못된 정보",
      message: "새로고침(F5) 및 이전 페이지 이동하거나 관리자에게 문의하세요.",
    },
  },
  OrgChart: {
    titleFormat: "님의 계보도",
    childrenTable: {
      title: "하위 직급 현황",
      noData: "표시할 데이터가 없습니다.",
      category: "구분",
      left: "좌",
      right: "우",
    },
    score: {
      title: "등급",
      A: "파랑",
      B: "노랑",
      C: "빨강",
      D: "검정",
    },
    childType: {
      option1: "명목직급",
      option2: "현재직급",
    },
    leftRight: {
      option1: "전체",
      option2: " 좌 ",
      option3: " 우 ",
    },
    search: {
      icon: "이동",
    },
    person: {
      cust_no: "회원번호",
      cust_name: "이름",
      cur: "현",
      max: "명",
      keepCount: "유지횟수",
      childrenPV: "산하 PV",
      leftPV: "산하 PV(좌)",
      rightPV: "산하 PV(우)",
      pv_da: "일평균",
    },
    backdrop: {
      contents: "로딩중",
      downloading: "다운로드중",
    },
    wrong: {
      title: "표시할 데이터 없음",
      message: "날짜를 변경하거나 관리자에게 문의하세요.",
    },
    error: {
      title: "잘못된 정보",
      message: "이전 페이지로 이동하거나 관리자에게 문의하세요.",
    },
  },
  ChildrenList: {
    cust_no: "회 원 번 호",
    cust_name: "이 름",
    cur_level: "현 재 직 급",
    max_level: "명 목 직 급",
    keep_count: "유 지 횟 수",
    pv: "P V",
    tot: "매 출",
    dashboard: "세 부 정 보",
    shortcuts: "바로가기",
    refresh: "새로고침",
    search: "검색",
    noData: "표시할 데이터가 없습니다.",
    backdrop: {
      contents: "로딩중",
    },
  },
  ScorePerson: {
    cust_no: "회 원 번 호",
    cust_name: "이 름",
    score: "등 급",
    cur_level: "현 재 직 급",
    max_level: "명 목 직 급",
    dashboard: "세 부 정 보",
    shortcuts: "바로가기",
    refresh: "새로고침",
    search: "검색",
    noData: "표시할 데이터가 없습니다.",
  },
  Autocomplete: {
    noOptionsText: "데이터가 없습니다.",
  },
  DataManagement: {
    basic: "초기 중량 관리",
    reduction: "절감 중량 관리",
    materialCode: "물품 코드",
    materialName: "물품 명",
    materialInfo: "물품 코드/명",
    menuRefresh: "메뉴 새로고침",
    year: "년",
    month: "월",
    week: "주",
    date: "날짜",
    jisa: "법인",
    search: "검색",
    plastic_type_edit: "플라스틱 정보 관리",
    refresh: "새로고침",
    Code: "코드",
    Name: "품명",
    Type: "종류",
    plasticCode: "플라스틱 코드",
    plasticType: "플라스틱 종류",
    add: "추가",
    save: "저장",
    delete: "삭제",
    upload: "업로드",
    startEdit: "편집시작",
    endEdit: "편집종료",
    lastSaved: "마지막 저장 시간",
    lastUpdatedTime: "마지막 업데이트 시간",
    lastEditor: "마지막 편집자",
    unknown: "알 수 없음",
    saveBT: "초기 정보",
    saveRT: "절감 정보",
    weight: "중량 단위",
    days: {
      sunday: "일",
      monday: "월",
      tuesday: "화",
      wednesday: "수",
      thursday: "목",
      friday: "금",
      saturday: "토",
    },
    menu: {
      materialSavings: "재료 절감",
      collection: "수거 이벤트",
      subMenu: {
        grid: "표",
        calendar: "달력",
      },
    },
    backdrop: {
      deleting: "삭제중",
      saving: "저장중",
      searching: "검색중",
      loading: "로딩중",
    },
    pageInfo: {
      title: "검색 정보",
    },
    contextMenu: {
      Insert_row_above: "위쪽에 xx 개 행 삽입",
      Insert_row_below: "아래쪽에 xx 개 행 삽입",
    },
    noDeleteData: "삭제할 데이터가 없습니다.",
    noSaveData: "저장할 데이터가 없습니다.",
    importingFile: "파일 가져오기 중",
    fileInvalid: "잘못된 파일입니다.",
    columnNotMatch: "열 정보가 일치하지 않습니다.",
    toolbar: {
      undo: "실행취소",
      redo: "재실행",
      save: "저장",
      delete: "삭제",
      upload: "업로드",
      find: "찾기",
      find_replace: "찾기/바꾸기",
    },
    msg: {
      material: "물품이 존재하지 않습니다.",
      plastic: "플라스틱 종류가 존재하지 않습니다.",
    },
  },
  UserManagement: {
    userMenu: "사용자 관리",
    groupMenu: "권한 관리",
    UserView: {
      title: "사용자 관리",
      invite: "추가",
      edit: "편집",
      delete: "삭제",
      refresh: "새로고침",
      search: "검색",

      id: "ID",
      displayName: "이름",
      groups: "부여권한",
      loggedInTime: "마지막 로그인",
      loggedInCount: "로그인 횟수",
      createdTime: "생성일",

      backdrop: {
        contents: "로딩중",
        loading: "로딩중",
        saving: "저장중",
        inviting: "추가중",
        deleting: "삭제중",
        uploading: "업로드중",
        downloading: "다운로드중",
      },

      inviteUpdateUser: "사용자 추가/편집",
      inviteUser: "사용자 추가",
      updateUser: "사용자 편집",
      addGroup: "권한 추가",
      deleteGroup: "권한 삭제",
      save: "저장",
      add: "추가",
      download: "다운로드",
      upload: "업로드",
      fileName: "사용자 정보",

      deleteUser: "사용자 삭제",

      downloadTemplate: "템플릿 다운로드",
      fileUpload: "파일 업로드",

      format_id: "아이디",
      format_groups: "권한",
      format_example: "예시",
      exam_id: "hway@hway.co.kr (필수열)",
      exam_groups: "권한명1, 권한명2    ','(쉼표)로 구분 (미입력 가능)",

      noSelectedUser: "선택된 사용자가 없습니다.",
      questSelectedUser: "선택된 사용자를 삭제 하시겠습니까?",
      noGroupDatas: "추가 가능한 권한이 없습니다.",
      noSelectedGroup: "선택된 권한이 없습니다.",
      saveAndCloseChanges: "변경 사항을 저장 후 닫으시겠습니까?",
      idPlaceholder: "아이디(이메일)를 입력해주세요",
      emptyMail: "아이디(이메일)을 입력해주세요.",
      invalidMailFormat: "이메일 형식이 올바르지 않습니다.",
      successInvite: "사용자 추가를 완료하였습니다.",
      failureInvite: "사용자 추가를 실패하였습니다.",
    },
    GroupView: {
      title: "권한 관리",
      edit: "구성원 편집",
      search: "검색",

      name: "권한명",
      descriptions: "설명",
      memberCount: "구성원 수",
      loggedInTime: "마지막 로그인",
      loggedInCount: "로그인 횟수",
      createdTime: "생성일",

      backdrop: {
        contents: "로딩중",
        loading: "로딩중",
        saving: "저장중",
        uploading: "업로드중",
        downloading: "다운로드중",
      },

      member: "구성원",
      addMember: "구성원 추가",
      deleteMember: "구성원 삭제",
      save: "저장",
      add: "추가",
      refresh: "새로고침",
      download: "다운로드",
      upload: "업로드",
      fileName: "권한 정보",

      updateMembers: "구성원 편집",

      downloadTemplate: "템플릿 다운로드",
      fileUpload: "파일 업로드",

      format_id: "아이디",
      format_group: "권한",
      format_example: "예시",
      exam_id: "hway@hway.co.kr (필수열)",
      exam_group: "권한명 (1개의 권한만 입력) (필수열)",

      noSelectedGroup: "선택된 권한이 없습니다.",
      noUserDatas: "추가 가능한 사용자가 없습니다.",
      noSelectedUser: "선택된 구성원이 없습니다.",
      saveAndCloseChanges: "변경 사항을 저장 후 닫으시겠습니까?",
    },
    failureSave: "저장을 실패하였습니다.",
    successSave: "저장을 완료하였습니다.",
    failureDownload: "다운로드를 실패하였습니다.",
    noUploadData: "업로드할 데이터가 없습니다.",
    failureUpload: "파일 업로드를 실패하였습니다.",
    successUpload: "파일 업로드를 완료하였습니다.",
  },
  MessageBox: {
    confirm: "확인",
    yes: "예",
    no: "아니요",
    close: "닫기",
    saveQ: "데이터를 저장하시겠습니까?",
    deleteQ: "데이터를 삭제하시겠습니까?",
    save: "저장",
    cancel: "취소",
    delete: "삭제",
  },
  There_is_no_content_display: "표시할 데이터가 없습니다.",
  SearchPopper: {
    find: "찾기",
    previousFind: "이전",
    close: "닫기",
    matchCase: "대/소문자 구분",
    identical: "전체 일치",
    reSearch: "재 검색",
  },
  CalendarView: {
    reduction: "절감",
    collection: "수거",
    unit: "건",
  },
  ReductionEditDlg: {
    material_info: "물품 코드/명",
    material_code: "코드",
    material_name: "품명",
    material_list: "물품 목록",
    type: "종류",
    plastic_list: "플라스틱 목록",
    plastic_code: "플라스틱 코드",
    plastic_type: "플라스틱 종류",
    plastic_type_edit: "플라스틱 정보 관리",
    plasticType: "플라스틱 종류",
    infancy: "초기 중량",
    reduction: "절감 중량",
    collection: "수거 중량(g)",
    contents: "절감 사유",
    contents_placeholder: "최대 500자",
    loading: "로딩 중",
    delete: "삭제",
    save: "저장",
    msg: {
      material: "물품을 선택바랍니다.",
      plastic: "플라스틱 종류를 선택바랍니다.",
      infancy: "초기 중량(g)을 확인바랍니다.",
      reduction: "절감 중량(g)을 확인바랍니다.",
      collection: "수거 중량(g)을 확인바랍니다.",
      weight: "숫자와 소수점 두자리까지 입력이 가능합니다.",
      contents: "절감 사유를 입력바랍니다.",
      contents_placeholder: "설명은 최대 500자 입니다.",
      save: "저장하시겠습니까?",
      delete: "삭제하시겠습니까?",
    },
    search: "키워드를 입력하세요.",
    search_edit: "검색 및 편집",
  },
  CalendarListDlg: {
    add: "추가",
    edit: "편집",
    delete: "삭제",
    search: "키워드를 입력하세요.",
    materialCode: "물품 코드",
    materialName: "물품명",
    plastic_code: "플라스틱 코드",
    plasticType: "플라스틱 종류",
    infancy: "초기 중량(g)",
    reduction: "절감 중량(g)",
    collection: "수거 중량(x)",
    loding: "로딩 중",
    weight: "중량 단위",
    msg: {
      selected: "항목을 선택바랍니다.",
      selectedOne: "단일 선택바랍니다.",
      delete: "삭제하시겠습니까?",
    },
  },
  PlasticListDlg: {
    add: "추가",
    edit: "편집",
    save: "저장",
    delete: "삭제",
    search: "키워드를 입력하세요.",
    loding: "로딩 중",
    plastic_code: "플라스틱 코드",
    plastic_type: "플라스틱 종류",
    msg: {
      save: "저장하시겠습니까?",
      delete: "삭제하시겠습니까?",
      selected: "항목을 선택바랍니다.",
      selectedOne: "단일 선택바랍니다.",
      already: "이미 존재하는 플라스틱 종류입니다.",
    },
  },
  PlasticEditDlg: {
    loding: "로딩 중",
    plastic_code: "플라스틱 코드",
    plastic_type: "플라스틱 종류",
    msg: {
      plastic_code: "플라스틱 코드를 입력바랍니다.",
      plastic_type: "플라스틱 종류를 입력바랍니다.",
    },
  },
  CollectionEditDlg: {
    apply_date: "적용 날짜",
    weight: "수거 중량",
    contents: "수거 사유",
    notePlaceholder: "최대 500자",
    loding: "로딩 중",
    save: "저장",
    delete: "삭제",
    msg: {
      save: "저장하시겠습니까?",
      weight: "수거 중량(x)을 입력바랍니다.",
      contents: "수거 사유를 입력바랍니다.",
      delete: "삭제하시겠습니까?",
    },
  },
};
