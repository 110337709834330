import React from "react";

import { Switch, Route } from "react-router-dom";

import {
  useIsAuthenticated,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { PathName } from "./define";

import { Header, Login, Logout, UserCheck } from "./containers";

import { MainRouter } from "./routers";

import { getUserCheck } from "./utils/Utils";

import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/languages";

import "./App.css";

registerAllModules();

function App() {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated && !getUserCheck()) {
    return (
      <div className="App">
        <UserCheck />
      </div>
    );
  }

  return (
    <div className="App">
      <UnauthenticatedTemplate>
        <Switch>
          <Route
            exact
            path={PathName.Main}
            render={(props) => <Login {...props} />}
          />
          <Route
            path={PathName.Logout}
            render={(props) => <Logout {...props} />}
          />
          <Route path={PathName.Etc} render={(props) => <Login {...props} />} />
        </Switch>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <div className="wrapper">
          <Header />
          <Switch>
            <Route
              exact
              path={PathName.Main}
              render={(props) => <MainRouter {...props} />}
            />
            <Route
              path={PathName.Logout}
              render={(props) => <Logout {...props} />}
            />
            <Route
              path={PathName.Etc}
              render={(props) => <MainRouter {...props} />}
            />
          </Switch>
        </div>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
