import React, { useState, useEffect, useMemo } from "react";

import { useIsAuthenticated } from "@azure/msal-react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";

import { UserListDialog } from "../components";

import { MessageDialog, ConfirmDialog } from "../../../../components";

import { useTranslation } from "react-i18next";

import { isEmpty, jsonToString } from "../../../../utils/Utils";
import { getUserCheck, getLocale } from "../../../../utils/Utils";

import moment from "moment-timezone";

import "moment/min/locales.min";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./index.scss";

moment.locale(getLocale());

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
}));

const contentStyles = makeStyles((theme) => ({
  title: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#181D1F",
  },
  content: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#181D1F",
  },
  btn: {
    height: "30px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  backdrop: {
    color: "#fff",
    zIndex: 1301,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#edit-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

let r_headerPage;
let r_user;
let apiCallTime;

let groupData;
function EditDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  r_headerPage = props.r_headerPage;
  r_user = props.r_user;

  const [originData, setOriginData] = useState(props.groupData);
  const [_groupData, setGroupData] = useState(originData);
  groupData = _groupData;

  const [changedData, setChangedData] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [gridLoading, setGridLoading] = useState(false);
  const [progressText, setProgressText] = useState("");

  const [gridApi, setGridApi] = useState(null);

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);

  const [agGridRowData, setAgGridRowData] = useState(groupData.members);

  const [openUserListDlg, setOpenUserListDlg] = useState(false);
  const [userDatas, setUserDatas] = useState([]);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const textRenderer = (value) => {
    let strVal = `${value}`;
    let element = document.createElement("div");
    element.title = strVal;
    element.innerHTML = strVal;
    return element;
  };

  const dateRenderer = (value) => {
    const dataDate = dateToString(value);
    let element = document.createElement("div");
    element.title = dataDate;
    element.innerHTML = dataDate;
    return element;
  };

  const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let strA = isEmpty(valueA) ? "" : valueA.toString();
    strA = strA.toLowerCase();
    let strB = isEmpty(valueB) ? "" : valueB.toString();
    strB = strB.toLowerCase();

    return strA.localeCompare(strB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: t("UserManagement.UserView.id"),
        headerTooltip: t("UserManagement.UserView.id"),
        field: "mail",
        width: 500,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.mail),
      },
      {
        headerName: t("UserManagement.UserView.displayName"),
        headerTooltip: t("UserManagement.UserView.displayName"),
        field: "displayName",
        width: 420,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.displayName),
      },
      {
        headerName: t("UserManagement.UserView.loggedInTime"),
        headerTooltip: t("UserManagement.UserView.loggedInTime"),
        field: "loggedInTime",
        width: 250,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => dateRenderer(params.data.loggedInTime),
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      wrapText: true,
      autoHeight: true,
    }),
    []
  );

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "getGroupData") {
          getGroupData();
        } else if (item.key === "getUsers") {
          getUsers();
        } else if (item.key === "saveGroup") {
          saveGroup(item.value);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    const handleResize = () => {
      //
    };

    if (gridApi) {
      getGroupData();
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]);

  const onOpenProgress = (contents) => {
    setProgressText(contents);
    setGridLoading(true);
  };

  const onCloseProgress = () => {
    setGridLoading(false);
    setProgressText("");
  };

  const getGroupData = () => {
    onOpenProgress(t("UserManagement.GroupView.backdrop.loading"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "getGroupData", value: "" });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/group";
    const queryData = { tokenUser: r_user, group_pkey: groupData.group_pkey };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let newGroupData = {};
      if (success && res && res.data) {
        if (!isEmpty(res.data.groupData)) {
          newGroupData = res.data.groupData;
        }

        setOriginData(newGroupData);
        setGroupData(newGroupData);

        setAgGridRowData(newGroupData.members);

        onCloseProgress();
        return;
      }

      onCloseProgress();
      props.onCloseEditDlg(true);
    });

    apiCallTime = runTime;
  };

  const getUsers = () => {
    onOpenProgress(t("UserManagement.GroupView.backdrop.loading"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "getUsers", value: "" });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/users";
    const queryData = { tokenUser: r_user };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let newUserDatas = [];
      if (success && res && res.data) {
        if (!isEmpty(res.data.userDatas)) {
          for (let i = 0; i < res.data.userDatas.length; i++) {
            let user = res.data.userDatas[i];
            let idx = groupData.members.findIndex(
              (item) => item.id === user.id
            );
            if (idx === -1) {
              newUserDatas.push({
                id: user.id,
                mail: user.mail,
                displayName: user.displayName,
                descriptions: user.descriptions,
                createdTime: user.createdTime,
                loggedInTime: user.loggedInTime,
              });
            }
          }
        }
      }

      if (newUserDatas.length > 0) {
        onOpenUserListDlg(newUserDatas);
      } else {
        onOpenMessageDlg(t("UserManagement.GroupView.noUserDatas"));
      }

      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const saveGroup = (forceClose) => {
    onOpenProgress(t("UserManagement.GroupView.backdrop.saving"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "saveGroup", value: forceClose });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/group";
    const queryData = {
      tokenUser: r_user,
      dataType: "save",
      groupData: groupData,
    };
    props.sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let newGroupData = {};
      if (success && res && res.data) {
        if (!isEmpty(res.data.groupData)) {
          newGroupData = res.data.groupData;
        }

        setOriginData(newGroupData);
        setGroupData(newGroupData);

        setAgGridRowData(newGroupData.members);

        setChangedData(true);

        onCloseProgress();
        if (forceClose) {
          props.onCloseEditDlg(true);
        } else {
          onOpenMessageDlg(t("UserManagement.successSave"));
        }
        return;
      }

      onCloseProgress();
      if (forceClose) {
        props.onCloseEditDlg(true);
      } else {
        onOpenMessageDlg(t("UserManagement.failureSave"));
      }
    });

    apiCallTime = runTime;
  };

  const handleAdd = () => {
    getUsers();
  };

  const handleDelete = () => {
    if (gridApi) {
      let selectedNodes = gridApi.getSelectedNodes();
      if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
        const newGroupData = JSON.parse(JSON.stringify(groupData));
        for (let i = 0; i < selectedNodes.length; i++) {
          let userData = selectedNodes[i].data;
          let idx = newGroupData.members.findIndex(
            (item) => item.id === userData.id
          );
          if (idx !== -1) {
            newGroupData.members.splice(idx, 1);
          }
        }

        setGroupData(newGroupData);
        setAgGridRowData(newGroupData.members);
      } else {
        onOpenMessageDlg(t("UserManagement.GroupView.noSelectedUser"));
      }
    }
  };

  const handleSave = (forceClose) => {
    saveGroup(forceClose);
  };

  const handleChange = (e) => {
    const { id } = e.target;
    if (id === "search") {
      const value = e.target.value;
      setSearchText(value);
      onGridSearch(value);
    }
  };

  const handleSearch = () => {
    onGridSearch(searchText);
  };

  const onGridSearch = (_searchText) => {
    if (gridApi) {
      gridApi.setQuickFilter(_searchText);
    }
  };

  const dateToString = (value) => {
    const format = "YYYY-MM-DD"; // YYYY-MM-DD HH:mm:ss.SSS
    const dataDate = moment.utc(value).local().format(format);
    return dataDate;
  };

  const onCellClicked = (event) => {
    const selected = event.node.isSelected();
    event.node.setSelected(!selected);
  };

  const handleClose = () => {
    let notSame = originData.members.length !== groupData.members.length;

    if (!notSame) {
      for (let i = 0; i < originData.members.length; i++) {
        let idx = groupData.members.findIndex(
          (item) => item.id === originData.members[i].id
        );
        if (idx === -1) {
          notSame = true;
          break;
        }
      }
    }

    if (notSame) {
      onOpenConfirmDlg(t("UserManagement.GroupView.saveAndCloseChanges"));
      return;
    }

    props.onCloseEditDlg(changedData);
  };

  const onOpenUserListDlg = (datas) => {
    setUserDatas(datas);
    setOpenUserListDlg(true);
  };

  const onCloseUserListDlg = (members) => {
    if (Array.isArray(members) && members.length > 0) {
      const newGroupData = JSON.parse(JSON.stringify(groupData));
      for (let i = 0; i < members.length; i++) {
        newGroupData.members.push(members[i]);
      }

      setGroupData(newGroupData);
      setAgGridRowData(newGroupData.members);

      setTimeout(() => {
        if (!gridApi || gridApi.destroyCalled) {
          return;
        }
        const memberMap = new Map();
        for (let i = 0; i < members.length; i++) {
          memberMap.set(members[i].id, members[i]);
        }

        const allLeafChildren = gridApi.getModel().rootNode.allLeafChildren;

        for (let i = 0; i < allLeafChildren.length; i++) {
          const row = allLeafChildren[i];
          if (row && row.data && row.data.id) {
            if (memberMap.get(row.data.id)) {
              allLeafChildren[i].setSelected(true);
            }
          }
        }
        const rowsToDisplay = gridApi.getModel().rowsToDisplay;
        gridApi.ensureIndexVisible(rowsToDisplay.length - 1, "bottom");
      }, 100);
    }

    setUserDatas([]);
    setOpenUserListDlg(false);
  };

  const onOpenMessageDlg = (content) => {
    setOpenMessageDlg(true);
    setMessageDlgTitle("");
    setMessageDlgContent(content);
  };

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const onOpenConfirmDlg = (content) => {
    setOpenConfirmDlg(true);
    setConfirmDlgTitle("");
    setConfirmDlgContent(content);
  };

  const onOkConfirmDlg = () => {
    onCloseConfirmDlg();

    handleSave(true);
  };

  const onCancelConfirmDlg = () => {
    onCloseConfirmDlg();

    props.onCloseEditDlg(changedData);
  };

  const onCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCloseConfirmDlg}
        />
      )}

      <Dialog
        id="edit-dialog"
        maxWidth="lg"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="edit-dialog-title"
      >
        <DialogTitle className={s_header.titlebar} id="edit-dialog-title">
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={10} container direction="row" alignItems="center">
              <Typography className={s_header.title}>
                {t("UserManagement.GroupView.edit")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            style={{ padding: "16px 20px", margin: 0, width: "100%" }}
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ marginBottom: "20px" }}
            >
              <Grid
                item
                xs={10}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item style={{ marginRight: "5px" }}>
                  <Typography className={s_content.title}>
                    {t("UserManagement.GroupView.name")}:
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "30px" }}>
                  <Typography className={s_content.content}>
                    {groupData.name}
                  </Typography>
                </Grid>
                {/* <Grid item style={{ marginRight: "5px" }}>
                  <Typography className={s_content.title}>
                    {t("UserManagement.GroupView.descriptions")}:
                  </Typography>
                </Grid>
                <Grid item style={{ marginRight: "30px" }}>
                  <Typography className={s_content.content}>
                    {groupData.descriptions}
                  </Typography>
                </Grid> */}
                <Grid item style={{ marginRight: "5px" }}>
                  <Typography className={s_content.title}>
                    {t("UserManagement.GroupView.createdTime")}:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={s_content.content}>
                    {dateToString(groupData.createdTime)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                xs={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Button1
                  className={s_content.btn}
                  onClick={() => handleSave(false)}
                >
                  <Typography className={s_content.btn_label}>
                    {t("UserManagement.GroupView.save")}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>

            <Grid item style={{ display: "none" }}>
              <Typography
                className={s_content.title}
                style={{ fontSize: "18px" }}
              >
                {t("UserManagement.GroupView.member")}
              </Typography>
            </Grid>

            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{ margin: "0.5rem 0px" }}
            >
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item>
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom"
                    onClick={handleAdd}
                  >
                    <i className="fas fa-plus"></i>
                    {t("UserManagement.GroupView.addMember")}
                  </button>
                </Grid>
                <Grid item>
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom ml-2"
                    onClick={handleDelete}
                  >
                    <i className="fas fa-minus"></i>
                    {t("UserManagement.GroupView.deleteMember")}
                  </button>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <div className="search-layout">
                    <div className="input-group">
                      <input
                        id="search"
                        type="text"
                        className="form-control rounded-0"
                        placeholder={t("UserManagement.UserView.search")}
                        onChange={handleChange}
                      />
                      <div className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-default btn-sm btn-flat"
                          onClick={handleSearch}
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ width: "100%", height: "550px" }}>
              <div
                className="ag-theme-alpine"
                style={{ height: "100%", width: "100%" }}
              >
                <AgGridReact
                  rowData={agGridRowData}
                  rowStyle={{ cursor: "pointer" }}
                  rowSelection="multiple"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  tooltipShowDelay={500}
                  headerHeight={40}
                  suppressMovableColumns="true"
                  suppressRowClickSelection="true"
                  enableCellTextSelection="true"
                  suppressCellSelection="true"
                  onGridReady={(params) => {
                    setGridApi(params.api);
                  }}
                  localeText={{
                    noRowsToShow: " ",
                  }}
                  onColumnResized={(params) => {
                    params.api.resetRowHeights();
                  }}
                  onCellClicked={onCellClicked}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {openUserListDlg && (
        <UserListDialog
          isOpen={openUserListDlg}
          userDatas={userDatas}
          onCloseUserListDlg={onCloseUserListDlg}
        />
      )}

      <Backdrop className={s_content.backdrop} open={gridLoading}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressText}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
}

export default EditDialog;
