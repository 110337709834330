import React, { useEffect, useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { TextField } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";

import { MessageDialog } from "../../../components";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const TextField1 = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    padding: "5px 10px 3px",
    "& .MuiInputBase-root": {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(TextField);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#calendar-data-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function PlasticEditDlg(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();
  const { t } = useTranslation();

  const [plasticCode, setPlasticCode] = useState(undefined);
  const [plasticType, setPlasticType] = useState(undefined);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const plasticCodeTxt = t("PlasticEditDlg.plastic_code");
  const plasticTypeTxt = t("PlasticEditDlg.plastic_type");

  const plasticCodeMsg = t("PlasticEditDlg.msg.plastic_code");
  const plasticTypeMsg = t("PlasticEditDlg.msg.plastic_type");

  useEffect(() => {
    if (!props.title) return props.handleClose();
    if (props.plastic) {
      setPlasticCode(props.plastic.plastic_code);
      setPlasticType(props.plastic.plastic_type);
    }
  }, [props.title, props.plastic]);

  const handlePlasticCode = (e) => {
    const code = e.target.value.replace(/[^0-9]/g, "").substring(0, 5);
    setPlasticCode(code);
  };

  const handlePlasticType = (e) => {
    setPlasticType(e.target.value.substring(0, 100));
  };

  const handleSave = () => {
    if (!plasticCode || plasticCode.length < 1) {
      setMessageDlgContent(plasticCodeMsg);
      setOpenMessageDlg(true);
      return;
    }

    if (!plasticType || plasticType.length < 1) {
      setMessageDlgContent(plasticTypeMsg);
      setOpenMessageDlg(true);
      return;
    }

    props.handleSave({ plastic_code: plasticCode, plastic_type: plasticType });
  };

  const handleClose = () => {
    props.handleClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContent("");
    setOpenMessageDlg(false);
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      <Dialog
        // disableEscapeKeyDown
        maxWidth="sm"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="calendar-data-dialog-title"
      >
        <DialogTitle
          className={s_header.titlebar}
          id="calendar-data-dialog-title"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={10} container direction="row" alignItems="center">
              <Typography className={s_header.title}>{props.title}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "0px 15px 15px" }}
          >
            <Grid item xs style={{ paddingTop: "20px" }}>
              <Grid container direction="row" alignItems="center">
                <Grid item style={{ width: "120px" }}>
                  <Typography className={s_content.label}>
                    {plasticCodeTxt}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <TextField1
                    value={plasticCode || ""}
                    disabled={props.plastic}
                    onChange={handlePlasticCode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs style={{ paddingTop: "20px" }}>
              <Grid container direction="row" alignItems="center">
                <Grid item style={{ width: "120px" }}>
                  <Typography className={s_content.label}>
                    {plasticTypeTxt}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <TextField1
                    value={plasticType || ""}
                    onChange={handlePlasticType}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item style={{ paddingTop: "20px" }}>
                <Button1 className={s_content.btn} onClick={handleSave}>
                  <Typography className={s_content.btn_label}>
                    {props.okName}
                  </Typography>
                </Button1>
              </Grid>
              <Grid item style={{ padding: "20px 0px 0px 20px" }}>
                <Button1 className={s_content.btn} onClick={handleClose}>
                  <Typography className={s_content.btn_label}>
                    {props.cancelName}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PlasticEditDlg;
