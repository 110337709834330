import React, { useState, useMemo } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { AgGridReact } from "ag-grid-react";

import { isEmpty } from "../../../../utils/Utils";

import { useTranslation } from "react-i18next";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./index.scss";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    height: "30px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#user-list-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function UserListDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");

  const [gridApi, setGridApi] = useState(null);

  const agGridRowData = props.userDatas;

  const textRenderer = (value) => {
    let strVal = `${value}`;
    let element = document.createElement("div");
    element.title = strVal;
    element.innerHTML = strVal;
    return element;
  };

  const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let strA = isEmpty(valueA) ? "" : valueA.toString();
    strA = strA.toLowerCase();
    let strB = isEmpty(valueB) ? "" : valueB.toString();
    strB = strB.toLowerCase();

    return strA.localeCompare(strB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: t("UserManagement.UserView.id"),
        headerTooltip: t("UserManagement.UserView.id"),
        field: "mail",
        width: 245,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.mail),
      },
      {
        headerName: t("UserManagement.UserView.displayName"),
        headerTooltip: t("UserManagement.UserView.displayName"),
        field: "displayName",
        width: 245,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.displayName),
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      wrapText: true,
      autoHeight: true,
    }),
    []
  );

  const handleChange = (e) => {
    const { id } = e.target;
    if (id === "search") {
      const value = e.target.value;
      setSearchText(value);
      onGridSearch(value);
    }
  };

  const handleSearch = () => {
    onGridSearch(searchText);
  };

  const onGridSearch = (_searchText) => {
    if (gridApi) {
      gridApi.setQuickFilter(_searchText);
    }
  };

  const onCellClicked = (event) => {
    const selected = event.node.isSelected();
    event.node.setSelected(!selected);
  };

  const handleDone = () => {
    const members = [];
    if (gridApi) {
      let selectedNodes = gridApi.getSelectedNodes();
      if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
        for (let i = 0; i < selectedNodes.length; i++) {
          let userData = selectedNodes[i].data;
          members.push(userData);
        }
      }
    }
    props.onCloseUserListDlg(members);
  };

  const handleClose = () => {
    props.onCloseUserListDlg([]);
  };

  return (
    <Dialog
      id="user-list-dialog"
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        handleClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="user-list-dialog-title"
    >
      <DialogTitle className={s_header.titlebar} id="user-list-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={s_header.title}>
              {t("UserManagement.GroupView.addMember")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleClose}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ padding: "16px 20px", margin: 0, width: "100%" }}
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ margin: "0.5rem 0px" }}
          >
            <Grid item>
              <div className="search-layout" style={{ width: "280px" }}>
                <div className="input-group">
                  <input
                    id="search"
                    type="text"
                    className="form-control rounded-0"
                    placeholder={t("UserManagement.UserView.search")}
                    onChange={handleChange}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default btn-sm btn-flat"
                      onClick={handleSearch}
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid item style={{ width: "100%", height: "350px" }}>
            <div
              className="ag-theme-alpine"
              style={{ height: "100%", width: "100%" }}
            >
              <AgGridReact
                rowData={agGridRowData}
                rowStyle={{ cursor: "pointer" }}
                rowSelection="multiple"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                tooltipShowDelay={500}
                headerHeight={40}
                suppressMovableColumns="true"
                suppressRowClickSelection="true"
                enableCellTextSelection="true"
                suppressCellSelection="true"
                onGridReady={(params) => {
                  setGridApi(params.api);
                }}
                localeText={{
                  noRowsToShow: " ",
                }}
                onColumnResized={(params) => {
                  params.api.resetRowHeights();
                }}
                onCellClicked={onCellClicked}
              />
            </div>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ marginTop: "16px" }}
          >
            <Button1 className={s_content.btn} onClick={handleDone}>
              <Typography className={s_content.btn_label}>
                {t("UserManagement.GroupView.add")}
              </Typography>
            </Button1>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default UserListDialog;
