import React, { useState, useEffect, useMemo } from "react";

import { shallowEqual, useSelector } from "react-redux";

import { useIsAuthenticated } from "@azure/msal-react";

import { sendBackend } from "../../../utils/backend";

import { isEmpty } from "../../../utils/Utils";
import { getUserCheck, getLocale } from "../../../utils/Utils";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { Menu, MenuItem, Typography } from "@material-ui/core";

import { AgGridReact } from "ag-grid-react";

import { EditDialog, RightSidebar } from "./components";

import { MessageDialog, ConfirmDialog } from "../../../components";

import { useTranslation } from "react-i18next";

import ExcelJS from "exceljs";

import moment from "moment-timezone";

import "moment/min/locales.min";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import "./index.scss";

moment.locale(getLocale());

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
  list: {
    padding: "8px 0px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    // height: "26px",
    // fontSize: "13px",
    padding: "10px 12px",
    "&:focus": {
      // backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
    "& i": {
      width: "17px",
      fontSize: "15px",
      color: "#00b5ef",
      marginRight: "0.4rem",
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
  menuItemLabel: {
    fontSize: "14px",
    fontFamily: "Noto Sans",
    color: "#656565",
    textAlign: "left",
  },
}));

let tempGridData;

let r_headerPage;
let r_user;
let apiCallTime;
function UserView() {
  const classes = useStyles();

  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  r_headerPage = useSelector((state) => state.headerPage, shallowEqual);
  r_user = useSelector((state) => state.user, shallowEqual);

  const [searchText, setSearchText] = useState("");

  const [gridLoading, setGridLoading] = useState(false);
  const [progressText, setProgressText] = useState("");

  const [gridApi, setGridApi] = useState(null);

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);

  const [agGridRowData, setAgGridRowData] = useState([]);

  const [selectedNodes, setSelectedNodes] = useState([]);

  const [_tempGridData, setTempGridData] = useState({});
  tempGridData = _tempGridData;

  const [openEditDlg, setOpenEditDlg] = useState(false);
  const [selectedUser, setSelectedUser] = useState(undefined);

  const [uploadMenuRef, setUploadMenuRef] = useState(null);
  const openUploadMenu = Boolean(uploadMenuRef);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarType, setSidebarType] = useState("");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const textRenderer = (value) => {
    let strVal = `${value}`;
    let element = document.createElement("div");
    element.title = strVal;
    element.innerHTML = strVal;
    return element;
  };

  const dateRenderer = (value) => {
    const format = "YYYY-MM-DD"; // YYYY-MM-DD HH:mm:ss.SSS
    const dataDate = moment.utc(value).local().format(format);
    let element = document.createElement("div");
    element.title = dataDate;
    element.innerHTML = dataDate;
    return element;
  };

  const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let strA = isEmpty(valueA) ? "" : valueA.toString();
    strA = strA.toLowerCase();
    let strB = isEmpty(valueB) ? "" : valueB.toString();
    strB = strB.toLowerCase();

    return strA.localeCompare(strB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const columnDefs = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: false,
      },
      {
        headerName: t("UserManagement.UserView.id"),
        headerTooltip: t("UserManagement.UserView.id"),
        field: "mail",
        width: 265,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.mail),
      },
      {
        headerName: t("UserManagement.UserView.displayName"),
        headerTooltip: t("UserManagement.UserView.displayName"),
        field: "displayName",
        width: 165,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.displayName),
      },
      {
        headerName: t("UserManagement.UserView.groups"),
        headerTooltip: t("UserManagement.UserView.groups"),
        field: "groupNames",
        width: 295,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.groupNames),
      },
      {
        headerName: t("UserManagement.UserView.loggedInTime"),
        headerTooltip: t("UserManagement.UserView.loggedInTime"),
        field: "loggedInTime",
        width: 118,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => {
          if (params.data.createdTime === params.data.loggedInTime) {
            return textRenderer("-");
          }
          return dateRenderer(params.data.loggedInTime);
        },
      },
      {
        headerName: t("UserManagement.UserView.loggedInCount"),
        headerTooltip: t("UserManagement.UserView.loggedInCount"),
        field: "loggedInCount",
        width: 105,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.data.loggedInCount),
      },
      {
        headerName: t("UserManagement.UserView.createdTime"),
        headerTooltip: t("UserManagement.UserView.createdTime"),
        field: "createdTime",
        width: 108,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => dateRenderer(params.data.createdTime),
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      wrapText: true,
      autoHeight: true,
    }),
    []
  );

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }
  }, []);

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "getUsers") {
          getUsers(
            item.isDownload,
            item.isUpload,
            item.uploadType,
            item.userDatas
          );
        } else if (item.key === "inviteUsers") {
          inviteUsers(item.userDatas, item.isUpload);
        } else if (item.key === "deleteUsers") {
          deleteUsers(item.userDatas, item.isUpload);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  useEffect(() => {
    const handleResize = () => {
      //
    };

    if (gridApi) {
      getUsers(false, false, "", []);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [gridApi]);

  const onOpenProgress = (contents) => {
    setProgressText(contents);
    setGridLoading(true);
  };

  const onCloseProgress = () => {
    setGridLoading(false);
    setProgressText("");
  };

  const getUsers = (isDownload, isUpload, uploadType, _userDatas) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.loading"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({
        key: "getUsers",
        isDownload: isDownload,
        isUpload: isUpload,
        uploadType: uploadType,
        userDatas: _userDatas,
      });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/users";
    const queryData = { tokenUser: r_user };
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      let userDatas = [];
      if (success && res && res.data) {
        if (!isEmpty(res.data.userDatas)) {
          userDatas = res.data.userDatas;

          for (let i = 0; i < userDatas.length; i++) {
            let userData = userDatas[i];
            let groupNames = "";
            for (let k = 0; k < userData.groups.length; k++) {
              let group = userData.groups[k];
              if (k === 0) {
                groupNames += `${group.name}`;
              } else {
                groupNames += `, ${group.name}`;
              }
            }
            userData.groupNames = groupNames;
          }
        }
      }

      setAgGridRowData(userDatas);

      if (isDownload) {
        handleDownload(userDatas);
        return;
      }

      if (isUpload) {
        for (let i = 0; i < _userDatas.length; i++) {
          _userDatas[i].oid = "";
          _userDatas[i].id = "";
          let idx = userDatas.findIndex(
            (item) => item.mail === _userDatas[i].mail
          );
          if (idx !== -1) {
            _userDatas[i].oid = userDatas[idx].id;
            _userDatas[i].id = userDatas[idx].id;
          }
        }

        if (uploadType === "invite") {
          inviteUsers(_userDatas, true);
        } else if (uploadType === "update") {
          // updateUsers(_userDatas);
        } else if (uploadType === "delete") {
          deleteUsers(_userDatas, true);
        }
        return;
      }

      onCloseSidebar();

      if (gridApi && tempGridData && tempGridData.id) {
        const data_id = tempGridData.id;
        setTimeout(() => {
          if (!gridApi || gridApi.destroyCalled) {
            return;
          }

          const rowsToDisplay = gridApi.getModel().rowsToDisplay;
          for (let i = 0; i < rowsToDisplay.length; i++) {
            let row = rowsToDisplay[i];
            if (row.data.id === data_id) {
              rowsToDisplay[i].setSelected(true);
              gridApi.ensureIndexVisible(i, "middle");
              break;
            }
          }
        }, 100);
      }

      setTempGridData({});
      setSelectedNodes([]);
      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const inviteUsers = (userDatas, isUpload) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.inviting"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "inviteUsers", userDatas, isUpload });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/users";
    const queryData = {
      tokenUser: r_user,
      dataType: "invite",
      userDatas: userDatas,
    };
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      if (success && res && res.data) {
        if (!isEmpty(res.data.resultDatas)) {
          let resultDatas = res.data.resultDatas;
          getUsers(false, false, "", []);

          if (isUpload) {
            onOpenMessageDlg(t("UserManagement.successUpload"));
          }
          return;
        }
      } else {
        if (isUpload) {
          onOpenMessageDlg(t("UserManagement.failureUpload"));
        }
      }

      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const deleteUsers = (userDatas, isUpload) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.deleting"));
    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "deleteUsers", userDatas, isUpload });
      setDelayDatas(newArray);
      return;
    }

    const runTime = new Date().getTime();
    const apiPath = "v1/usermanagement/users";
    const queryData = {
      tokenUser: r_user,
      dataType: "delete",
      userDatas: userDatas,
    };
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) {
        return;
      }

      if (!isAuthenticated || !getUserCheck()) {
        return;
      }

      if (!gridApi || (gridApi && gridApi.destroyCalled)) {
        return;
      }

      if (success && res && res.data) {
        if (!isEmpty(res.data.resultDatas)) {
          let resultDatas = res.data.resultDatas;
          getUsers(false, false, "", []);

          if (isUpload) {
            onOpenMessageDlg(t("UserManagement.successUpload"));
          }
          return;
        }
      } else {
        if (isUpload) {
          onOpenMessageDlg(t("UserManagement.failureUpload"));
        }
      }

      onCloseProgress();
    });

    apiCallTime = runTime;
  };

  const onFileUpload = (dataType, userDatas) => {
    getUsers(false, true, dataType, userDatas);
    onCloseSidebar();
  };

  const handleInvite = () => {
    // if (gridApi) {
    //   gridApi.deselectAll();
    // }
    onOpenEditDlg(undefined);
  };

  const handleEdit = () => {
    if (gridApi) {
      if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
        gridApi.deselectAll();

        const lastSelected = selectedNodes[selectedNodes.length - 1];
        lastSelected.setSelected(true);

        const selectedRow = lastSelected.data;
        onOpenEditDlg({
          id: selectedRow.id,
          mail: selectedRow.mail,
          displayName: selectedRow.displayName,
          descriptions: selectedRow.descriptions,
          groups: selectedRow.groups,
          loggedInTime: selectedRow.loggedInTime,
          loggedInCount: selectedRow.loggedInCount,
          createdTime: selectedRow.createdTime,
        });
      } else {
        onOpenMessageDlg(t("UserManagement.UserView.noSelectedUser"));
      }
    }
  };

  const handleDelete = (forceClick) => {
    if (gridApi) {
      if (Array.isArray(selectedNodes) && selectedNodes.length > 0) {
        if (forceClick) {
          const userDatas = [];
          for (let i = 0; i < selectedNodes.length; i++) {
            let userData = selectedNodes[i].data;
            userDatas.push({
              id: userData.id,
              mail: userData.mail,
              displayName: userData.displayName,
            });
          }

          if (userDatas.length > 0) {
            deleteUsers(userDatas, false);
          }
          return;
        }
        onOpenConfirmDlg(t("UserManagement.UserView.questSelectedUser"));
      } else {
        onOpenMessageDlg(t("UserManagement.UserView.noSelectedUser"));
      }
    }
  };

  const handleDownload = (_userDatas) => {
    onOpenProgress(t("UserManagement.UserView.backdrop.downloading"));

    const userDatas = JSON.parse(JSON.stringify(_userDatas));

    const workbook = new ExcelJS.Workbook();

    workbook.creator = "BizViu";
    workbook.lastModifiedBy = "BizViu System";
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("Sheet1");

    worksheet.columns = [
      {
        header: t("UserManagement.UserView.id"),
        key: "mail",
        width: 35,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
      {
        header: t("UserManagement.UserView.displayName"),
        key: "displayName",
        width: 25,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
      {
        header: t("UserManagement.UserView.groups"),
        key: "groupNames",
        width: 60,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
      {
        header: t("UserManagement.UserView.loggedInTime"),
        key: "loggedInTime",
        width: 13.5,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
      {
        header: t("UserManagement.UserView.loggedInCount"),
        key: "loggedInCount",
        width: 13.5,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
      {
        header: t("UserManagement.UserView.createdTime"),
        key: "createdTime",
        width: 13.5,
        style: {
          font: { bold: true },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        },
      },
    ];

    for (let i = 0; i < userDatas.length; i++) {
      const format = "YYYY-MM-DD"; // YYYY-MM-DD HH:mm:ss.SSS
      userDatas[i].loggedInTime = moment
        .utc(userDatas[i].loggedInTime)
        .local()
        .format(format);
      userDatas[i].createdTime = moment
        .utc(userDatas[i].createdTime)
        .local()
        .format(format);
      const row = worksheet.addRow(userDatas[i]);

      for (let k = 0; k < worksheet.columns.length; k++) {
        row.getCell(k + 1).style = {
          font: { bold: false },
          alignment: {
            vertical: "middle",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }

    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${moment().local().format("YYYYMMDD_HHmm")}_${t(
          "UserManagement.UserView.fileName"
        )}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);

        onCloseProgress();
      })
      .catch((err) => {
        console.error(err);
        onCloseProgress();
        onOpenMessageDlg(t("UserManagement.failureDownload"));
      });
  };

  const handleUpload = (event) => {
    setUploadMenuRef(event.currentTarget);
  };

  const onCloseUploadMenu = () => {
    setUploadMenuRef(null);
  };

  const handleChange = (e) => {
    const { id } = e.target;
    if (id === "search") {
      const value = e.target.value;
      setSearchText(value);
      onGridSearch(value);
    }
  };

  const handleSearch = () => {
    onGridSearch(searchText);
  };

  const onGridSearch = (_searchText) => {
    if (gridApi) {
      gridApi.setQuickFilter(_searchText);
    }
  };

  const onSelectionChanged = (event) => {
    let newSelectedNodes = [];
    const nowSelectedNodes = event.api.getSelectedNodes();
    if (Array.isArray(nowSelectedNodes) && nowSelectedNodes.length > 0) {
      for (let i = 0; i < selectedNodes.length; i++) {
        let idx = nowSelectedNodes.findIndex(
          (item) => item.data.id === selectedNodes[i].data.id
        );
        if (idx !== -1) {
          newSelectedNodes.push(selectedNodes[i]);
        }
      }

      for (let i = 0; i < nowSelectedNodes.length; i++) {
        let idx = newSelectedNodes.findIndex(
          (item) => item.data.id === nowSelectedNodes[i].data.id
        );
        if (idx === -1) {
          newSelectedNodes.push(nowSelectedNodes[i]);
        }
      }
    }
    setSelectedNodes(newSelectedNodes);
  };

  const onRowDoubleClicked = (event) => {
    if (gridApi) {
      gridApi.deselectAll();
    }

    if (!event.node.isSelected()) {
      event.node.setSelected(true);
    }

    const selectedRow = event.data;
    onOpenEditDlg({
      id: selectedRow.id,
      mail: selectedRow.mail,
      displayName: selectedRow.displayName,
      descriptions: selectedRow.descriptions,
      groups: selectedRow.groups,
      loggedInTime: selectedRow.loggedInTime,
      loggedInCount: selectedRow.loggedInCount,
      createdTime: selectedRow.createdTime,
    });
  };

  const onCellClicked = (event) => {
    const selected = event.node.isSelected();
    event.node.setSelected(!selected);
  };

  const onOpenEditDlg = (userData) => {
    setSelectedUser(userData);
    setOpenEditDlg(true);
  };

  const onCloseEditDlg = (changed) => {
    if (changed) {
      if (selectedUser) {
        setTempGridData({ id: selectedUser.id });
      }
      getUsers(false, false, "", []);
    }
    setSelectedUser(undefined);
    setOpenEditDlg(false);
  };

  const onOpenSidebar = (_sidebarType) => {
    onCloseUploadMenu();

    setOpenSidebar(true);
    setSidebarType(_sidebarType);
  };

  const onCloseSidebar = () => {
    setOpenSidebar(false);
    setSidebarType("");
  };

  const onOpenMessageDlg = (content) => {
    setOpenMessageDlg(true);
    setMessageDlgTitle("");
    setMessageDlgContent(content);
  };

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const onOpenConfirmDlg = (content) => {
    setOpenConfirmDlg(true);
    setConfirmDlgTitle("");
    setConfirmDlgContent(content);
  };

  const onOkConfirmDlg = () => {
    onCloseConfirmDlg();

    handleDelete(true);
  };

  const onCancelConfirmDlg = () => {
    onCloseConfirmDlg();
  };

  const onCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  return (
    <div id="UserView">
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCloseConfirmDlg}
        />
      )}

      <div className="content-wrapper">
        <section className="content-header">
          <div className="container content-header-box">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h5 className="m-0">
                  <span style={{ fontWeight: "bold" }}>
                    {t("UserManagement.UserView.title")}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="content" style={{ height: "calc(100% - 70px)" }}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content-title"></div>
              </div>
              <div className="col-md-7 mb-2 d-flex align-items-center">
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom"
                    onClick={handleInvite}
                  >
                    <i className="fas fa-plus"></i>
                    {t("UserManagement.UserView.invite")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom ml-2"
                    onClick={handleEdit}
                  >
                    <i className="fas fa-edit"></i>
                    {t("UserManagement.UserView.edit")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom ml-2"
                    onClick={() => handleDelete(false)}
                  >
                    <i className="fas fa-minus"></i>
                    {t("UserManagement.UserView.delete")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btn-sm btn-custom ml-2"
                    onClick={() => getUsers(false, false, "", [])}
                    style={{ display: "none" }}
                  >
                    <i className="fas fa-sync"></i>
                    {t("UserManagement.UserView.refresh")}
                  </button>
                </div>
                <div className="col-md-6">
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-default btn-sm btn-custom"
                      onClick={() => getUsers(true, false, "", [])}
                    >
                      <i className="fas fa-download"></i>
                      {t("UserManagement.UserView.download")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-default btn-sm btn-custom ml-2"
                      onClick={handleUpload}
                    >
                      <i className="fas fa-upload"></i>
                      {t("UserManagement.UserView.upload")}
                    </button>
                    {openUploadMenu && (
                      <StyledMenu
                        id="upload-menu"
                        anchorEl={uploadMenuRef}
                        keepMounted
                        open={openUploadMenu}
                        onClose={onCloseUploadMenu}
                        disableScrollLock={true}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <StyledMenuItem
                          key="upload-menu-item-1"
                          onClick={() => onOpenSidebar("invite")}
                        >
                          <i className="fas fa-plus" />
                          <Typography className={classes.menuItemLabel}>
                            {t("UserManagement.UserView.inviteUpdateUser")}
                          </Typography>
                        </StyledMenuItem>
                        {/* <StyledMenuItem
                          key="upload-menu-item-2"
                          onClick={() => onOpenSidebar("update")}
                        >
                          <i className="fas fa-edit" />
                          <Typography className={classes.menuItemLabel}>
                            {t("UserManagement.UserView.updateUser")}
                          </Typography>
                        </StyledMenuItem> */}
                        <StyledMenuItem
                          key="upload-menu-item-3"
                          onClick={() => onOpenSidebar("delete")}
                        >
                          <i className="fas fa-minus" />
                          <Typography className={classes.menuItemLabel}>
                            {t("UserManagement.UserView.deleteUser")}
                          </Typography>
                        </StyledMenuItem>
                      </StyledMenu>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5 mb-2">
                <div className="search-layout" style={{ width: "100%" }}>
                  <div className="input-group">
                    <input
                      id="search"
                      type="text"
                      className="form-control rounded-0"
                      placeholder={t("UserManagement.UserView.search")}
                      onChange={handleChange}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default btn-sm btn-flat"
                        onClick={handleSearch}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-12 mt-1"
                style={{
                  height:
                    "calc(100vh - 40px - 66px - 38px - 38px - 8px - 4px - 20px)",
                }}
              >
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    rowData={agGridRowData}
                    rowStyle={{ cursor: "pointer" }}
                    rowSelection="multiple"
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    tooltipShowDelay={500}
                    headerHeight={40}
                    suppressMovableColumns="true"
                    suppressRowClickSelection="true"
                    enableCellTextSelection="true"
                    suppressCellSelection="true"
                    onGridReady={(params) => {
                      setGridApi(params.api);
                    }}
                    localeText={{
                      noRowsToShow: " ",
                    }}
                    onColumnResized={(params) => {
                      params.api.resetRowHeights();
                    }}
                    onSelectionChanged={onSelectionChanged}
                    onRowDoubleClicked={onRowDoubleClicked}
                    onCellClicked={onCellClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {openEditDlg && (
        <EditDialog
          isOpen={openEditDlg}
          r_headerPage={r_headerPage}
          r_user={r_user}
          sendBackend={sendBackend}
          userData={selectedUser}
          onCloseEditDlg={onCloseEditDlg}
        />
      )}

      {openSidebar && (
        <RightSidebar
          isOpen={openSidebar}
          sidebarType={sidebarType}
          onCloseSidebar={onCloseSidebar}
          onOpenMessageDlg={onOpenMessageDlg}
          onFileUpload={onFileUpload}
        />
      )}

      <Backdrop className={classes.backdrop} open={gridLoading}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressText}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </div>
  );
}

export default UserView;
