import React, { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import { isMobile, isTablet } from "react-device-detect";

import { Error } from "../../containers";
import { Report } from "../../containers";
import { DataManagement } from "../../containers";
import { GroupView } from "../../containers";
import { UserView } from "../../containers";

import { MenuType, MenuShowType, ReportMenuType } from "../../define";
import { UserManagementType } from "../../define";
import { PathType } from "../../define";

import { isEmpty } from "../../utils/Utils";

let r_menuInfo;

let _landscapeMode;
function MainRouter(props) {
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();

  const [chartData, setChartData] = useState({
    custNo: "S0000001",
    slicerDate: "날짜 선택",
    homeCustNo: "S0000001",
  });

  r_menuInfo = useSelector((state) => state.menuInfo, shallowEqual);

  const [landscapeMode, setLandscapeMode] = useState(
    window.matchMedia("(orientation: landscape)").matches
  );
  _landscapeMode = landscapeMode;

  const mobileView = { mainMenu: false, reportMenu: false };
  if (isMobile) {
    if (!isTablet) {
      mobileView.mainMenu = true;
      mobileView.reportMenu = true;
    } else {
      if (!landscapeMode) {
        mobileView.mainMenu = true;
        mobileView.reportMenu = true;
      }
    }
  }

  useEffect(() => {
    const onResize = () => {
      const __landscapeMode = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      if (_landscapeMode !== __landscapeMode) {
        setLandscapeMode(__landscapeMode);
      }
    };

    const onOrientationchange = () => {
      const __landscapeMode = window.matchMedia(
        "(orientation: landscape)"
      ).matches;
      if (_landscapeMode !== __landscapeMode) {
        setLandscapeMode(__landscapeMode);
      }
    };

    window.addEventListener("resize", onResize);
    window.addEventListener("orientationchange", onOrientationchange);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("orientationchange", onOrientationchange);
    };
  }, []);

  const changeChartData = (data) => {
    setChartData(data);
  };

  let notFound = false;
  let notExist = true;
  let pageName = "";
  let menuItem = undefined;
  if (r_menuInfo) {
    if (Array.isArray(r_menuInfo.data) && r_menuInfo.data.length > 0) {
      const pathNames = pathname.split("/").filter((word) => word !== "");

      let menuName = "";
      let menuType = -1;

      if (pathNames.length === 0) {
        menuName = "Home";
      } else if (pathNames.length === 1) {
        menuName = pathNames[0];
      } else if (pathNames.length === 2) {
        if (pathNames[0] === PathType.Menu) {
          menuName = pathNames[1];
          if (menuName === PathType.Genealogy) {
            menuType = MenuType.Genealogy;
          } else if (menuName === PathType.DataManagement) {
            menuType = MenuType.DataManagement;
          } else if (menuName === PathType.UserManagement) {
            menuType = MenuType.UserManagement;
          } else {
            menuType = MenuType.Normal;
          }
        } else if (pathNames[0] === PathType.Report) {
          menuName = pathNames[1];
          menuType = MenuType.Report;
        }
      } else if (pathNames.length === 3) {
        if (pathNames[0] === PathType.Report) {
          menuName = pathNames[1];
          pageName = pathNames[2];
          menuType = MenuType.Report;
        } else if (pathNames[0] === PathType.Menu) {
          if (pathNames[1] === PathType.UserManagement) {
            menuName = pathNames[1];
            pageName = pathNames[2];
            menuType = MenuType.UserManagement;
          }
        }
      }

      if (menuName === "" && menuType === -1) {
        notFound = true;
      }

      if (!notFound) {
        if (menuName === "Home") {
          menuItem = undefined;
          menuType = -1;
          for (let loop1 = 0; loop1 < r_menuInfo.data.length; loop1++) {
            const mainMenu = r_menuInfo.data[loop1];
            if (mainMenu.hidden) {
              continue;
            }
            if (mobileView.mainMenu) {
              if (mainMenu.showType === MenuShowType.OnlyDeskTop) {
                continue;
              }
            } else {
              if (mainMenu.showType === MenuShowType.OnlyMobile) {
                continue;
              }
            }

            menuItem = mainMenu;
            menuType = mainMenu.menuType;
            break;
          }

          if (menuType === MenuType.Report) {
            pageName = "";
            for (let k = 0; k < menuItem.reportMenus.length; k++) {
              const reportMenu = menuItem.reportMenus[k];
              if (reportMenu.hidden) {
                continue;
              }
              if (mobileView.reportMenu) {
                if (reportMenu.menuType === ReportMenuType.OnlyDeskTop) {
                  continue;
                }
              } else {
                if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
                  continue;
                }
              }
              pageName = reportMenu.pageName;
              break;
            }
          }
        } else {
          for (let loop1 = 0; loop1 < r_menuInfo.data.length; loop1++) {
            const mainMenu = r_menuInfo.data[loop1];
            if (mainMenu.hidden) {
              continue;
            }
            if (mobileView.mainMenu) {
              if (mainMenu.showType === MenuShowType.OnlyDeskTop) {
                continue;
              }
            } else {
              if (mainMenu.showType === MenuShowType.OnlyMobile) {
                continue;
              }
            }

            const menu_pkey = mainMenu.menu_pkey.toLowerCase();
            if (menuName === menu_pkey) {
              if (pathNames.length === 1 || menuType === mainMenu.menuType) {
                if (pathNames.length === 3) {
                  if (pageName && menuType === MenuType.Report) {
                    for (let k = 0; k < mainMenu.reportMenus.length; k++) {
                      const reportMenu = mainMenu.reportMenus[k];
                      if (reportMenu.hidden) {
                        continue;
                      }
                      if (mobileView.reportMenu) {
                        if (
                          reportMenu.menuType === ReportMenuType.OnlyDeskTop
                        ) {
                          continue;
                        }
                      } else {
                        if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
                          continue;
                        }
                      }
                      if (reportMenu.pageName.toLowerCase() === pageName) {
                        pageName = reportMenu.pageName;
                        break;
                      }
                    }
                  }
                }
                menuItem = mainMenu;
                notExist = false;
                break;
              }
            }
          }
        }
      }
    }
  }

  if (!menuItem) {
    if (notFound || notExist) {
      return (
        <Error
          {...props}
          viewType="Blank.Error"
          errorCode={notFound ? 1000 : 1001}
        />
      );
    }
    return <Error {...props} viewType="Blank.Wrong" errorCode={1002} />;
  }

  let newPath;
  if (menuItem.menuType === MenuType.Report) {
    newPath = `/${PathType.Report}/${menuItem.menu_pkey}`;
    if (pageName) {
      newPath += `/${pageName}`;
    } else {
      for (var i in menuItem.reportMenus) {
        const reportMenu = menuItem.reportMenus[i];
        if (reportMenu.hidden) {
          continue;
        }
        if (mobileView.reportMenu) {
          if (reportMenu.menuType === ReportMenuType.OnlyDeskTop) {
            continue;
          }
        } else {
          if (reportMenu.menuType === ReportMenuType.OnlyMobile) {
            continue;
          }
        }
        newPath += `/${reportMenu.pageName}`;
        break;
      }
    }

    if (pathname !== newPath.toLowerCase()) {
      return <Redirect to={newPath} />;
    }

    let workspaceId = undefined;
    let reportId = undefined;
    let reportType = undefined;
    let reportPage = undefined;

    if (!isEmpty(pageName)) {
      let idx = menuItem.reportMenus.findIndex(
        (item) => item.pageName === pageName
      );
      if (idx !== -1) {
        const reportMenuType = menuItem.reportMenus[idx].menuType;
        if (mobileView.reportMenu) {
          if (reportMenuType === ReportMenuType.OnlyDeskTop) {
            return (
              <Error
                {...props}
                viewType="Report.OnlyDeskTop"
                errorCode={10002}
              />
            );
          }
          workspaceId = menuItem.reportMenus[idx].mWorkspaceId;
          reportId = menuItem.reportMenus[idx].mReportId;
          reportType = menuItem.reportMenus[idx].mReportType;
          reportPage = menuItem.reportMenus[idx].mReportPage;
        } else {
          if (reportMenuType === ReportMenuType.OnlyMobile) {
            return (
              <Error
                {...props}
                viewType="Report.OnlyMobile"
                errorCode={10001}
              />
            );
          }
          workspaceId = menuItem.reportMenus[idx].workspaceId;
          reportId = menuItem.reportMenus[idx].reportId;
          reportType = menuItem.reportMenus[idx].reportType;
          reportPage = menuItem.reportMenus[idx].reportPage;
        }
      }
    }

    if (!workspaceId && !reportId) {
      return <Error {...props} viewType="Report.Wrong" errorCode={10000} />;
    }

    return (
      <Report
        {...props}
        workspaceId={workspaceId}
        reportId={reportId}
        reportType={reportType}
        reportPage={reportPage}
      />
    );
  } else if (menuItem.menuType === MenuType.Genealogy) {
    newPath = `/${PathType.Menu}/${menuItem.menu_pkey}`;

    if (pathname !== newPath.toLowerCase()) {
      return <Redirect to={newPath} />;
    }
  } else if (menuItem.menuType === MenuType.DataManagement) {
    newPath = `/${PathType.Menu}/${menuItem.menu_pkey}`;

    if (pathname !== newPath.toLowerCase()) {
      return <Redirect to={newPath} />;
    }
    return <DataManagement {...props} menuItem={menuItem} />;
  } else if (menuItem.menuType === MenuType.UserManagement) {
    if (!pageName) {
      pageName = UserManagementType.User;
    }

    if (
      pageName &&
      (pageName === UserManagementType.User ||
        pageName === UserManagementType.Group)
    ) {
      newPath = `/${PathType.Menu}/${menuItem.menu_pkey}/${pageName}`;

      if (pathname !== newPath.toLowerCase()) {
        return <Redirect to={newPath} />;
      }
      if (pageName === UserManagementType.User) {
        return <UserView {...props} />;
      } else {
        return <GroupView {...props} />;
      }
    }
  }

  return <Error {...props} viewType="Blank.Error" errorCode={1003} />;
}

export default MainRouter;
