import { createStore, applyMiddleware } from "redux";
import reducers from "../reducers";
import reduxThunk from "redux-thunk";
import storage from "redux-persist/lib/storage";

import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "Hway Inc. Blue Marine frontend Key",
  storage: storage,
};

const enhancedReducer = persistReducer(persistConfig, reducers);
export default function configureStore() {
  const store = createStore(enhancedReducer, applyMiddleware(reduxThunk));
  const persistor = persistStore(store);
  return { store, persistor };
}
