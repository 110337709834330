import * as d3 from "d3";
import render from "./render";
import defaultConfig from "./config";

const transform = { x: 0, y: 0, k: 1 };

function chart(options) {
  const config = {
    ...defaultConfig,
    ...options,
    treeData: options.treeData,
  };

  const {
    id,
    treeData,
    nodeWidth,
    nodeHeight,
    nodeWSpacing,
    nodeHSpacing,
    zoomInId,
    zoomOutId,
    loadConfig,
  } = config;

  const elem = document.querySelector(id);
  elem.innerHTML = "";

  const elemWidth = elem.offsetWidth;
  config.elemWidth = elemWidth;

  const elemHeight = elem.offsetHeight;
  config.elemHeight = elemHeight;

  const treeRoot = d3.hierarchy(treeData);
  treeRoot.x0 = 0;
  treeRoot.y0 = elemHeight / 2;
  treeRoot.descendants().forEach((d) => {
    d.id = d.data.person.id;
  });
  config.treeRoot = treeRoot;

  setTransform(
    elemWidth / 2 - nodeWidth / 2,
    elemHeight / 2 - nodeHeight / 2,
    1
  );

  const tree = d3
    .tree()
    .separation(() => 1)
    .nodeSize([nodeWidth + nodeWSpacing, nodeHeight + nodeHSpacing]);
  config.tree = tree;

  const svg = d3
    .select(id)
    .append("svg")
    .attr("id", "chart-container")
    .attr("viewBox", `0 0 ${elemWidth} ${elemHeight}`);
  config.svg = svg;

  const gNode = svg.append("g");
  config.gNode = gNode;

  const zoom = d3.zoom().scaleExtent([0.02, 2]).on("zoom", zoomed);
  config.zoom = zoom;

  svg.call(zoom);

  config.render = render;

  config.onChangedZoom = onChangedZoom;

  config.startSearch = startSearch;

  render({ ...config, sourceNode: null });

  setTimeout(() => interpolateZoom(0, transform));

  window.addEventListener("resize", winResize);

  d3.select(`#${zoomInId}`).on("click", (e) => zoomClick(e));
  d3.select(`#${zoomOutId}`).on("click", (e) => zoomClick(e));

  const canvasContainer = document.createElement("div");
  canvasContainer.setAttribute("id", `${id}-canvas-container`);
  canvasContainer.setAttribute("style", "display:none;");

  const svgContainer = document.createElement("div");
  svgContainer.setAttribute("id", `${id}-svg-container`);
  svgContainer.setAttribute("style", "display:none;");

  const orgChart = document.getElementById("root");
  orgChart.append(canvasContainer);
  orgChart.append(svgContainer);

  function setTransform(x, y, k) {
    transform.x = x;
    transform.y = y;
    transform.k = k;
  }

  function checkTransform(_transform) {
    const config = loadConfig();
    const { viewLeft, viewRight, viewTop, viewBottom } = config;
    const { elemWidth, elemHeight } = config;

    let { x, y, k } = _transform;

    let left = (viewLeft * k - elemWidth / 2) * -1;
    let right = (viewRight * k - elemWidth + elemWidth / 2) * -1;
    let top = (viewTop * k - elemHeight / 2) * -1;
    let bottom = (viewBottom * k - elemHeight + elemHeight / 2) * -1;

    x = x > left ? left : x;
    x = x < right ? right : x;

    y = y > top ? top : y;
    y = y < bottom ? bottom : y;

    return { x, y, k };
  }

  function zoomed(event) {
    if (event && event.transform) {
      const config = loadConfig();
      const { gNode } = config;

      const { x, y, k } = checkTransform(event.transform);
      if (
        event.transform.x !== x ||
        event.transform.y !== y ||
        event.transform.k !== k
      ) {
        interpolateZoom(0, { x, y, k });
        return;
      }

      setTransform(x, y, k);
      gNode.attr(
        "transform",
        `translate(${transform.x},${transform.y}) scale(${transform.k})`
      );
    }
  }

  function interpolateZoom(_duration, _transform) {
    const config = loadConfig();
    const { svg, zoom } = config;

    const { x, y, k } = _transform;
    svg
      .transition()
      .duration(_duration)
      .call(zoom.transform, d3.zoomIdentity.translate(x, y).scale(k));
  }

  function winResize() {
    const config = loadConfig();
    const { id, svg } = config;
    const { whMargin } = config;
    const { onConfigChange } = config;
    const elem = document.querySelector(id);

    if (!elem) {
      window.removeEventListener("resize", winResize);
      return;
    }

    const elemWidth = elem.offsetWidth;
    const elemHeight = elem.offsetHeight;

    if (whMargin) {
      let winWidth = window.innerWidth;
      if (winWidth !== elemWidth) {
        elemWidth = winWidth;
      }
      let winHeight = window.innerHeight;
      if (typeof whMargin === "number") {
        winHeight = winHeight - whMargin;
      }
      winHeight = winHeight < 700 ? 700 : winHeight;
      if (winHeight !== elemHeight) {
        elemHeight = winHeight;
      }
    }

    config.elemWidth = elemWidth;
    config.elemHeight = elemHeight;

    svg.attr("viewBox", `0 0 ${elemWidth} ${elemHeight}`);

    interpolateZoom(0, transform);

    onConfigChange(config);
  }

  function zoomClick(e) {
    e.preventDefault();

    const { id } = e.target;

    const config = loadConfig();
    const { elemWidth, elemHeight } = config;
    const { zoom, zoomOutId } = config;
    // const { animationDuration } = config;

    let { x, y, k } = transform;

    let scaleExtent = zoom.scaleExtent();
    let newScale = k + (id === zoomOutId ? -0.1 : 0.1);
    newScale = newScale < scaleExtent[0] ? scaleExtent[0] : newScale;
    newScale = newScale > scaleExtent[1] ? scaleExtent[1] : newScale;

    if (k === newScale) {
      return false;
    }
    let center = [elemWidth / 2, elemHeight / 2];
    x = (center[0] - x) / k;
    y = (center[1] - y) / k;
    k = newScale;

    x = x + center[0] - (x * k + x);
    y = y + center[1] - (y * k + y);

    interpolateZoom(50, { x, y, k });
  }

  function onChangedZoom(d) {
    const config = loadConfig();
    const { animationDuration } = config;
    if (config && animationDuration) {
      centerNode(d, 0, transform.k);
    }
  }

  function startSearch(searchText) {
    // let searchNode = searchTree(searchText);
    // render({ ...config, sourceNode: searchNode });
    searchTree(searchText);
  }

  function searchTree(searchText) {
    const config = loadConfig();
    const { treeRoot } = config;

    const nodes = treeRoot.descendants().reverse();

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if (node.id === searchText) {
        const config = loadConfig();
        const { animationDuration } = config;

        let scale = 0.7;
        centerNode(node, animationDuration, scale);
        return node;
      }
    }

    return null;
  }

  let eventTimer = null;
  function centerNode(source, duration, scale) {
    const config = loadConfig();
    const { elemWidth, elemHeight, nodeWidth, nodeHeight } = config;

    interpolateZoom(duration, {
      x: (-source.x - nodeWidth / 2) * scale + elemWidth / 2,
      y: (-source.y - nodeHeight / 2) * scale + elemHeight / 2,
      k: scale,
    });

    if (eventTimer) {
      clearTimeout(eventTimer.item);
      d3.select(`#r-${eventTimer.id}`).attr("class", "score-border");
    }

    d3.select(`#r-${source.id}`).attr("class", "score-border person-highlight");

    eventTimer = setTimeout(() => {
      d3.select(`#r-${source.id}`).attr("class", "score-border");
      eventTimer = null;
    }, 800);

    eventTimer = { id: source.id, item: eventTimer };
  }
}

export default chart;
