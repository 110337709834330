import { resetAppData } from "./Utils";

import { loginRequest, graphConfig } from "../authConfig";
import { callMsGraph } from "../graph";

export const RequestProfileData = (instance, accounts, dispatch, callback) => {
  if (!Array.isArray(accounts) || accounts.length === 0) {
    resetAppData(dispatch, true);
    return;
  }

  instance
    .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
    .then((res) => {
      getMsGraphData(accounts, res.accessToken, dispatch, callback);
    })
    .catch((err) => {
      console.error(err && err.message ? err.message : err);
      resetAppData(dispatch, true);
    });
};

export const getMsGraphData = (accounts, accessToken, dispatch, callback) => {
  let user = {};
  callMsGraph(`${graphConfig.graphEndpoint}/me`, accessToken)
    .then((res) => {
      if (!res || (res.error && res.error.message)) {
        resetAppData(dispatch, true);
        return;
      }
      user = {
        id: res.id,
        mail: res.mail ? res.mail : accounts[0].username,
        displayName: res.displayName,
      };
      callback({ user });
    })
    .catch((err) => {
      console.error(err && err.message ? err.message : err);
      resetAppData(dispatch, true);
    });
};
