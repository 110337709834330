import CryptoJS from "crypto-js";

import { request } from "./axios";

import { getLocale } from "./Utils";

import moment from "moment-timezone";
import "moment/min/locales.min";
moment.locale(getLocale());

export const sendBackend = async (publicApi, apiPath, queryData, callback) => {
  try {
    let reqData = { data: queryData };
    if (publicApi) {
      reqData.token = CryptoJS.AES.encrypt(
        JSON.stringify({
          date: moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss.SSS"),
          user_id: process.env.REACT_APP_PUBLIC_USER_ID,
          token: process.env.REACT_APP_PUBLIC_API_ACCESS_TOKEN,
        }),
        process.env.REACT_APP_PUBLIC_API_SECRET_KEY
      ).toString();
    }

    const res = await request("post", apiPath, reqData);
    if (res) {
      if (res.data) {
        let success = res.data.success ? true : false;
        callback(success, res.data);
      } else {
        callback(false, res);
      }
    } else {
      callback(false, {});
    }
  } catch (err) {
    if (err) {
      if (err.response) {
        callback(false, err.response);
      } else {
        callback(false, err);
      }
    } else {
      callback(false, {});
    }
  }
};
