import React from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#confirm-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfirmDialog(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();

  const handleOk = () => {
    props.onOkConfirmDlg();
  };

  const handleCancel = () => {
    props.onCancelConfirmDlg();
  };

  const handleClose = () => {
    props.onCloseConfirmDlg();
  };

  return (
    <Dialog
      // disableBackdropClick
      // disableEscapeKeyDown
      maxWidth="sm"
      fullWidth={true}
      open={props.isOpen}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
          return;
        }
        handleClose();
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="confirm-dialog-title"
    >
      <DialogTitle className={s_header.titlebar} id="confirm-dialog-title">
        <Grid
          container
          direction="row"
          alignItems="center"
          style={{ height: "36px" }}
        >
          <Grid item xs={10} container direction="row" alignItems="center">
            <Typography className={s_header.title}>{props.title}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <IconButton1
              size="small"
              aria-label="close"
              onClick={handleClose}
              style={{ color: "#ffffff" }}
            >
              <CloseIcon />
            </IconButton1>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "15px 15px" }}
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Typography className={s_header.content}>
              {props.content}
            </Typography>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            style={{ marginTop: "10px" }}
          >
            <Grid item>
              <Button1
                className={s_content.btn}
                onClick={handleOk}
                style={{ marginRight: "20px" }}
              >
                <Typography className={s_content.btn_label}>
                  {props.okName}
                </Typography>
              </Button1>
            </Grid>

            <Grid item>
              <Button1 className={s_content.btn} onClick={handleCancel}>
                <Typography className={s_content.btn_label}>
                  {props.cancelName}
                </Typography>
              </Button1>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmDialog;
