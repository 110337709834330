import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { Backdrop, TextField, CircularProgress, Menu } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { Autocomplete } from "@material-ui/lab";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../utils/Utils";
import { sendBackend } from "../../../utils/backend";
import { MessageDialog, ConfirmDialog } from "../../../components";

import moment from "moment-timezone";
import "moment/min/locales.min";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  label: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const Button2 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "5px",
    position: "relative",
    border: "1px solid #cccccc",
    margin: "0px",
    "&.Mui-disabled": {
      backgroundColor: "#cccccc",
    },
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const TextField1 = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    padding: "0px 10px",
    "& .MuiInputBase-root": {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(TextField);

const StyledMenu = withStyles({
  paper: {
    color: "#212529",
    margin: 0,
    padding: ".1rem 0",
    overflow: "overlay",
    fontSize: "1rem",
    borderTop: "none",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.175)",
    listStyle: "none",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    backgroundColor: "rgba(255, 255, 255, 0.8)",

    "& .MuiMenu-list": {
      padding: "0 !important",
      width: "100% !important",
    },

    "& .MuiMenuItem-root": {
      clear: "both",
      width: "100%",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "block",
      padding: 0,
      textAlign: "inherit",
      fontWeight: 400,
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#16181b",
        backgroundColor: "rgba(233, 236, 239, 0.8)",
      },
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
      display: "block",
      padding: "4px 10px",
    },

    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },

    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 5px",
      fontSize: "16px",
      fontFamily: "Noto Sans",
    },
  },
})((props) => (
  <Menu
    keepMounted
    disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#calendar-data-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

let r_user;
let apiCallTime;
function CollectionEditDlg(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();
  const { t } = useTranslation();

  const message_weight = t("ReductionEditDlg.msg.weight");

  const [selectedYear, setSelectedYear] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(undefined);

  const [yearData, setYearData] = useState([]);
  const monthData = Array.from({ length: 12 }, (_, index) =>
    `${index + 1}`.padStart(2, 0)
  );
  const dayData = Array.from({ length: 31 }, (_, index) =>
    `${index + 1}`.padStart(2, 0)
  );

  const [yearMenuRef, setYearMenuRef] = useState(undefined);
  const yearMenuOpen = Boolean(yearMenuRef);
  const [monthMenuRef, setMonthMenuRef] = useState(undefined);
  const monthMenuOpen = Boolean(monthMenuRef);
  const [dayMenuRef, setDayMenuRef] = useState(undefined);
  const dayMenuOpen = Boolean(dayMenuRef);

  const [selectedCollection, setSelectedCollection] = useState(undefined);
  const [collectionWeight, setCollectionWeight] = useState(undefined);
  const [contents, setContents] = useState(undefined);

  const weightData = ["g(gram)", "kg(kilogram)", "t(tonne)"];
  const [selectedWeight, setSelectedWeight] = useState(weightData[0]);
  const [weightMenuRef, setWeightMenuRef] = useState(undefined);
  const weightMenuOpen = Boolean(weightMenuRef);

  const [isLoding, setIsLoding] = useState(false);

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgType, setConfirmDlgType] = useState(-1);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  r_user = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    const startDate = moment("2009-06-01");
    const nowDate = moment();
    const startYear = parseInt(startDate.format("YYYY"));
    const nowYear = parseInt(nowDate.format("YYYY"));

    const newYearData = [nowYear + 1];
    for (let loop1 = 0; loop1 <= nowYear - startYear; loop1++) {
      newYearData.push(nowYear - loop1);
    }

    setYearData(newYearData);
    if (props.selected) {
      setSelectedCollection(props.selected.collection_pkey);
      setCollectionWeight(props.selected.weight);
      setContents(props.selected.contents);
    }

    if (props.title) {
      const datetime = props.title.split("-");
      setSelectedYear(datetime[0]);
      setSelectedMonth(datetime[1]);
      setSelectedDay(datetime[2]);
      return;
    }

    setSelectedYear(nowYear);
    setSelectedMonth(nowDate.format("MM"));
    setSelectedDay(nowDate.format("DD"));
  }, []);

  const handleYear = (newValue) => {
    setYearMenuRef(undefined);
    setSelectedYear(newValue);
  };

  const handleMonth = (newValue) => {
    setMonthMenuRef(undefined);
    setSelectedMonth(newValue);
  };

  const handleDay = (newValue) => {
    setDayMenuRef(undefined);
    setSelectedDay(newValue);
  };

  const handleDelete = () => {
    setConfirmDlgTitle(t("CollectionEditDlg.delete"));
    setConfirmDlgContent(t("CollectionEditDlg.msg.delete"));
    setOpenConfirmDlg(true);
  };

  const handleSave = () => {
    if (!collectionWeight) {
      setMessageDlgContent(
        t("CollectionEditDlg.msg.weight").replace(
          "x",
          selectedWeight.split("(")[1].split(")")[0]
        )
      );
      setOpenMessageDlg(true);
      return;
    }

    if (!contents) {
      setMessageDlgContent(t("CollectionEditDlg.msg.contents"));
      setOpenMessageDlg(true);
      return;
    }

    setConfirmDlgTitle(t("CollectionEditDlg.save"));
    setConfirmDlgContent(t("CollectionEditDlg.msg.save"));
    setOpenConfirmDlg(true);
  };

  const handleSaveOk = () => {
    let weight = 0;

    switch (selectedWeight) {
      case weightData[0]:
        weight = collectionWeight;
        break;
      case weightData[1]:
        weight = collectionWeight * 1000;
        break;
      case weightData[2]:
        weight = collectionWeight * 1000000;
        break;
      default:
        return;
    }

    const isSave = confirmDlgTitle === t("CollectionEditDlg.save");

    const data = {
      apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      collection_pkey: selectedCollection,
      weight,
      contents,
    };

    const runTime = new Date().getTime();
    const apiPath = `v1/datamanagement/${isSave ? "save" : "delete"}`;
    const queryData = {
      tokenUser: r_user,
      kindType: false,
      ...data,
      selected: [data],
    };

    // setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);

      setOpenConfirmDlg(false);
      props.handleSave();
    });

    apiCallTime = runTime;
  };

  const handleCollection = (e) => {
    const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    if (e && e.target && e.target.value) {
      const weight = e.target.value;
      if (!weight_regexp.test(weight)) {
        setMessageDlgContent(message_weight);
        setOpenMessageDlg(true);
        return;
      }
      setCollectionWeight(e.target.value);
    } else setCollectionWeight();
  };

  const handleWeightType = (newValue) => {
    setWeightMenuRef(undefined);
    setSelectedWeight(newValue);
  };

  const handleSaveCancel = () => {
    setOpenConfirmDlg(false);
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContent("");
    setOpenMessageDlg(false);
  };

  const collectionRender = () => {
    return (
      <Grid item container direction="column" justifyContent="center" alignItems="stretch">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          style={{ paddingTop: "10px" }}
        >
          <Grid item>
            <Typography className={s_content.label}>
              {`${t("CollectionEditDlg.apply_date")}`}
            </Typography>
          </Grid>
          <Grid item xs style={{ paddingLeft: "10px" }}>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Button2
                  onClick={(e) => setYearMenuRef(e.currentTarget)}
                  style={{
                    width: "80px",
                    height: "32px",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    className="text-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#262626",
                      lineHeight: "0px",
                    }}
                  >
                    {selectedYear}
                  </span>
                </Button2>
              </Grid>
              <Grid item style={{ padding: "0px 10px", display: 'flex', alignItems: 'center' }}>
                <Typography className={s_content.label}>{`년`}</Typography>
              </Grid>
              <Grid item>
                <Button2
                  onClick={(e) => setMonthMenuRef(e.currentTarget)}
                  style={{
                    width: "60px",
                    height: "32px",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    className="text-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#262626",
                      lineHeight: "0px",
                    }}
                  >
                    {selectedMonth}
                  </span>
                </Button2>
              </Grid>
              <Grid item style={{ padding: "0px 10px", display: 'flex', alignItems: 'center' }}>
                <Typography className={s_content.label}>{`월`}</Typography>
              </Grid>
              <Grid item>
                <Button2
                  onClick={(e) => setDayMenuRef(e.currentTarget)}
                  style={{
                    width: "60px",
                    height: "32px",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                  }}
                >
                  <span
                    className="text-font"
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      color: "#262626",
                      lineHeight: "0px",
                    }}
                  >
                    {selectedDay}
                  </span>
                </Button2>
              </Grid>
              <Grid item style={{ padding: "0px 10px", display: 'flex', alignItems: 'center' }}>
                <Typography className={s_content.label}>{`일`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid item>
            <Typography className={s_content.label}>
              {`${t("CollectionEditDlg.weight")}`}
            </Typography>
          </Grid>
          <Grid 
          item 
          container 
          direction="row" 
          xs 
          style={{
            marginLeft: "10px",            
            border: "1px solid #CCCCCC",
            borderRadius: "5px"
          }}
          >
            <Grid 
              item 
              xs 
              style={{ 
                borderRight: "1px solid #CCCCCC",
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <TextField1
                value={collectionWeight || ""}
                inputProps={{ maxLength: 20 }}
                onChange={handleCollection}
                style={{ 
                  height: "32px", 
                  width: "100%",
                  border: "none"
                }}
              />
            </Grid>
            <Grid item style={{ alignItems: 'center', display: 'flex', borderRadius: "5px" }}>
              <Button2
                onClick={(e) => setWeightMenuRef(e.currentTarget)}
                style={{ 
                  height: "32px",
                  width: "120px",
                  border: "none"
                }}
              >
                <span
                  className="text-font"
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#262626",
                    textTransform: "none",
                  }}
                >
                  {selectedWeight} ▼
                </span>
              </Button2>
              <StyledMenu
                anchorEl={weightMenuRef}
                open={weightMenuOpen}
                onClose={() => setWeightMenuRef(undefined)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                disableScrollLock={true}
                style={{ marginTop: "0px" }}
              >
                <div className="weight-layout">
                  {Array.isArray(weightData) &&
                    weightData.map((value, _idx) => {
                      return (
                        <div
                          key={`weight-key-${_idx}`}
                          className="weight-item"
                          onClick={() => handleWeightType(value)}
                        >
                          {value}
                        </div>
                      );
                    })}
                </div>
              </StyledMenu>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid item>
            <Typography className={s_content.label}>
              {`${t("CollectionEditDlg.contents")}`}
            </Typography>
          </Grid>
          <Grid item xs style={{ paddingTop: "10px" }}>
            <TextField1
              multiline
              value={contents || ""}
              minRows={10}
              maxRows={10}
              inputProps={{ maxLength: 500 }}
              placeholder={t("CollectionEditDlg.notePlaceholder")}
              onChange={(e) => setContents(e.target.value)}
              style={{ borderRadius: '5px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={handleSaveOk}
          onCancelConfirmDlg={handleSaveCancel}
          onCloseConfirmDlg={handleSaveCancel}
        />
      )}
      {yearMenuOpen && (
        <StyledMenu
          anchorEl={yearMenuRef}
          open={yearMenuOpen}
          onClose={() => setYearMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="year-layout">
            {Array.isArray(yearData) &&
              yearData.map((value, _idx) => {
                return (
                  <div
                    key={`year-key-${_idx}`}
                    className="year-item"
                    onClick={() => handleYear(value)}
                  >
                    {value}
                  </div>
                );
              })}
          </div>
        </StyledMenu>
      )}
      {monthMenuOpen && (
        <StyledMenu
          anchorEl={monthMenuRef}
          open={monthMenuOpen}
          onClose={() => setMonthMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="month-layout">
            {monthData.map((value, _idx) => {
              return (
                <div
                  key={`month-key-${_idx}`}
                  className="month-item"
                  onClick={() => handleMonth(value)}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </StyledMenu>
      )}
      {dayMenuRef && (
        <StyledMenu
          anchorEl={dayMenuRef}
          open={dayMenuOpen}
          onClose={() => setDayMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="day-layout">
            {dayData.map((value, _idx) => {
              return (
                <div
                  key={`day-key-${_idx}`}
                  className="day-item"
                  onClick={() => handleDay(value)}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </StyledMenu>
      )}
      <Dialog
        // disableEscapeKeyDown
        maxWidth="sm"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            return;
          }
          handleCancel();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="calendar-data-dialog-title"
      >
        <DialogTitle
          className={s_header.titlebar}
          id="calendar-data-dialog-title"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={2}>
              <Typography className={s_header.title}>항목 관리</Typography>
            </Grid>            
            <Grid item xs={8} container direction="row" alignItems="center">
              {props.selected && (
                <Typography className={s_header.title}>
                  {props.title}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleCancel}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 15px 15px" }}
          >
            {collectionRender()}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {props.selected && (
                <Grid xs item style={{ paddingTop: "20px" }}>
                  <Button1
                    className={s_content.btn}
                    onClick={handleDelete}
                    style={{ marginRight: "20px" }}
                  >
                    <Typography className={s_content.btn_label}>
                      {props.deleteName}
                    </Typography>
                  </Button1>
                </Grid>
              )}

              <Grid item style={{ paddingTop: "20px" }}>
                <Button1
                  className={s_content.btn}
                  onClick={handleSave}
                  style={{ marginRight: "20px", backgroundColor: "#7EC5F8" }}
                >
                  <Typography className={s_content.btn_label}>
                    {props.okName}
                  </Typography>
                </Button1>
              </Grid>

              <Grid item style={{ paddingTop: "20px" }}>
                <Button1 className={s_content.btn} onClick={handleCancel}>
                  <Typography className={s_content.btn_label}>
                    {props.cancelName}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Backdrop className={s_content.backdrop} open={isLoding}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item style={{ marginTop: "10px" }}>
              <p>{t("CollectionEditDlg.loding")}</p>
            </Grid>
          </Grid>
        </Backdrop>
      </Dialog>
    </>
  );
}

export default CollectionEditDlg;
