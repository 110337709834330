import React, { useEffect, useState, useRef } from "react";

import { shallowEqual, useSelector } from "react-redux";

import { useIsAuthenticated } from "@azure/msal-react";

import { Grid, Button, Menu } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";

import { MessageDialog, ConfirmDialog } from "../../../components";
import { CalendarListDlg, ReductionEditDlg } from "../components";
import { DrawerWidth } from "../../../define";
import { getUserCheck } from "../../../utils/Utils";
import { sendBackend } from "../../../utils/backend";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import moment from "moment-timezone";
import "moment/min/locales.min";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
    marginLeft: `-${DrawerWidth}px`,
  },
  contentShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${DrawerWidth}px)`,
    marginLeft: 0,
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledMenu = withStyles({
  paper: {
    color: "#212529",
    margin: 0,
    padding: ".1rem 0",
    overflow: "overlay",
    fontSize: "1rem",
    borderTop: "none",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.175)",
    listStyle: "none",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    backgroundColor: "rgba(255, 255, 255, 0.8)",

    "& .MuiMenu-list": {
      padding: "0 !important",
      width: "100% !important",
    },

    "& .MuiMenuItem-root": {
      clear: "both",
      width: "100%",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "block",
      padding: 0,
      textAlign: "inherit",
      fontWeight: 400,
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#16181b",
        backgroundColor: "rgba(233, 236, 239, 0.8)",
      },
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
      display: "block",
      padding: "4px 10px",
    },

    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },

    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 5px",
      fontSize: "16px",
      fontFamily: "Noto Sans",
    },
  },
})((props) => (
  <Menu
    keepMounted
    disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    background: "#f3f3f3",
    borderRadius: 2,
    position: "relative",
    border: "1px solid #dfdfdf",
    margin: "0px",
    padding: "0px",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const getPeriodData = (datetime) => {
  const period = [];
  const start_end = [];
  for (let loop1 = 0; loop1 < 6; loop1++) {
    let week = [];
    if (loop1 === 0) {
      let weekday = datetime.weekday();
      if (weekday === 0) datetime.add(-7, "days");
      else datetime.add(weekday * -1, "days");
    }

    if (loop1 === 0) start_end.push(datetime.format("YYYY-MM-DD"));

    for (let loop2 = 0; loop2 < 7; loop2++) {
      week.push(datetime.format("DD"));
      datetime.add(1, "days");
    }

    if (loop1 === 5) start_end.push(datetime.format("YYYY-MM-DD"));
    period.push(week);
  }
  return { period, start_end };
};

let r_user;
let r_headerPage;
let apiCallTime;
function ReductionCalender(props) {
  const classes = useStyles();
  const calendarRef = useRef();
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();

  const unknown = t("DataManagement.unknown");

  const [calendarData, setCalendarData] = useState(undefined);
  const [selectedData, setSelectedData] = useState(undefined);

  const [yearData, setYearData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(undefined);

  const [yearMenuRef, setYearMenuRef] = useState(undefined);
  const yearMenuOpen = Boolean(yearMenuRef);

  const [openCalendarDataDlg, setOpenCalendarDataDlg] = useState(false);
  const [calendarDataDlgTitle, setCalendarDataDlgTitle] = useState("");
  const calendarDataDlgDeleteName = t("MessageBox.delete");
  const calendarDataDlgSaveName = t("MessageBox.save");
  const calendarDataDlgCancelName = t("MessageBox.cancel");

  const [openCalenderListDlg, setOpenCalendarListDlg] = useState(false);
  const [calendarListDlgTitle, setCalendarListDlgTitle] = useState(undefined);
  const calendarListDlgCloseName = t("MessageBox.close");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const [delayTimer, setDelayTimer] = useState(false);
  const [delayDatas, setDelayDatas] = useState([]);
  const [progressData, setProgressData] = useState({
    open: false,
    content: "",
  });

  r_user = useSelector((state) => state.user, shallowEqual);
  r_headerPage = useSelector((state) => state.headerPage, shallowEqual);

  useEffect(() => {
    if (!calendarRef || !calendarRef.current) return;

    const startDate = moment("2009-06-01");
    const nowDate = moment();
    const startYear = parseInt(startDate.format("YYYY"));
    const nowYear = parseInt(nowDate.format("YYYY"));

    const newYearData = [nowYear + 1];
    for (let loop1 = 0; loop1 <= nowYear - startYear; loop1++) {
      newYearData.push(nowYear - loop1);
    }

    setYearData(newYearData);
    const api = calendarRef.current.getApi();
    const datetime = moment(api.getDate().toISOString());

    setSelectedYear(datetime.format("YYYY"));
    handleSearch();
  }, [calendarRef]);

  useEffect(() => {
    if (!calendarRef || !calendarRef.current) return;

    setTimeout(() => {
      const calendar = calendarRef.current.getApi();
      calendar.updateSize();
    }, 500);
  }, [props.openSidebar]);

  useEffect(() => {
    if (!r_headerPage.waitingAppData && delayTimer) {
      setDelayTimer(false);
      const newArray = delayDatas;
      while (newArray.length > 0) {
        const item = newArray.pop();
        if (item.key === "handleSearch") {
          handleSearch(item.params);
        }
      }
      setDelayDatas(newArray);
    }
  }, [r_headerPage, delayTimer, delayDatas]);

  const handleSearch = (params) => {
    if (!r_user) return;

    if (!r_user) return;

    if (!calendarRef || !calendarRef.current) return;

    const calendar = calendarRef.current.getApi();
    const datetime = calendar.getDate().toISOString();

    const startDate = moment(datetime).local().add(-1, "months");
    const endDate = moment(datetime).local().add(2, "months");

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/list";
    const queryData = {
      tokenUser: r_user,
      kindType: 1,
      startDate: startDate.format("YYYY-MM-01"),
      endDate: endDate.format("YYYY-MM-01"),
    };

    onOpenProgress(t("DataManagement.backdrop.searching"));

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "handleSearch", params });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return onCloseProgress();

      if (!isAuthenticated || !getUserCheck()) return onCloseProgress();

      if (success && res && res.data) {
        if (res.data.last_edit) {
          const editor = res.data.last_edit.editor;
          const updatedTime = moment
            .utc(res.data.last_edit.logged_date)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");

          if (props.handleEditor) props.handleEditor(editor);
          if (props.handleUpdatedTime) props.handleUpdatedTime(updatedTime);
        } else {
          if (props.handleEditor) props.handleEditor(unknown);
          if (props.handleUpdatedTime) props.handleUpdatedTime(unknown);
        }

        if (Array.isArray(res.data.list)) {
          const _calendarData = res.data.list.map((item) => {
            item.id = `${item.material_code}-${item.plastic_code}-${item.apply_date}`;
            item.title = item.contents;
            item.start = item.apply_date;
            return item;
          });

          setCalendarData(_calendarData);
        }
      }

      onCloseProgress();
    });
    apiCallTime = runTime;
  };

  const handleChange = (params) => {
    if (!r_user) return;

    const data = { ...params.event.extendedProps };

    if (!data.material_code || !data.plastic_code) return;
    data.apply_date = params.event.startStr;
    data.old_date = params.oldEvent.startStr;

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/save";
    const queryData = { tokenUser: r_user, kindType: 1, ...data };

    onOpenProgress(t("DataManagement.backdrop.saving"));

    if (r_headerPage.waitingAppData) {
      setDelayTimer(true);
      const newArray = delayDatas;
      newArray.push({ key: "handleChange", params });
      setDelayDatas(newArray);
      return;
    }

    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return onCloseProgress();
      if (!isAuthenticated || !getUserCheck()) return onCloseProgress();
      handleSearch();
    });
    apiCallTime = runTime;
  };

  const onOpenProgress = (content) => {
    setProgressData({ open: true, content: content });
  };

  const onCloseProgress = () => {
    setProgressData({ open: false, content: "" });
  };

  const handleYear = (newValue) => {
    if (!calendarRef || !calendarRef.current) return;
    const calendar = calendarRef.current.calendar;
    const datetime = moment.utc(calendar.getDate().toISOString());

    setYearMenuRef(undefined);
    setSelectedYear(newValue);
    calendar.gotoDate(`${newValue}-${datetime.local().format("MM-DD")}`);
  };

  const handlePrev = () => {
    if (!calendarRef || !calendarRef.current) return;

    const calendar = calendarRef.current.calendar;
    calendar.prev();

    const datetime = moment.utc(calendar.getDate().toISOString());
    setSelectedYear(datetime.local().format("YYYY"));

    handleSearch();
  };

  const handleNext = () => {
    if (!calendarRef || !calendarRef.current) return;

    const calendar = calendarRef.current.calendar;
    calendar.next();

    const datetime = moment.utc(calendar.getDate().toISOString());
    setSelectedYear(datetime.local().format("YYYY"));

    handleSearch();
  };

  const handleToDay = () => {
    if (!calendarRef || !calendarRef.current) return;

    const calendar = calendarRef.current.calendar;
    calendar.today();

    const datetime = moment.utc(calendar.getDate().toISOString());
    setSelectedYear(datetime.local().format("YYYY"));

    handleSearch();
  };

  const handleCalendarDlg = (e) => {
    document.activeElement.blur();
    const selected = e.event ? e.event.extendedProps : undefined;

    if (!calendarRef || !calendarRef.current) return;
    const calendar = calendarRef.current.calendar;
    const datetime = moment.utc(calendar.getDate().toISOString());

    setSelectedData(selected);
    setCalendarDataDlgTitle(
      selected ? selected.apply_date : datetime.local().format("YYYY-MM-01")
    );
    setOpenCalendarDataDlg(true);
  };

  const handleCalendarSave = () => {
    setOpenCalendarDataDlg(false);
    setCalendarData(undefined);
    handleSearch();
  };

  const handleCalendarCancel = () => {
    setOpenCalendarDataDlg(false);
  };

  const handleCalendarListDlg = (e) => {
    const datetime = moment.utc(e.date.toISOString());
    setCalendarListDlgTitle(datetime.local().format("YYYY-MM-DD"));
    setOpenCalendarListDlg(true);
    return "none";
  };

  const handleCalendarListClose = () => {
    setOpenCalendarListDlg(false);
    setCalendarData(undefined);
    handleSearch();
  };

  const onCloseMessageDlg = () => {
    setOpenMessageDlg(false);
    setMessageDlgTitle("");
    setMessageDlgContent("");
  };

  const onOkConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  const onCancelConfirmDlg = () => {
    setOpenConfirmDlg(false);
    setConfirmDlgTitle("");
    setConfirmDlgContent("");
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ height: "100%", padding: "0px 8px" }}
    >
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCancelConfirmDlg}
        />
      )}

      {openCalendarDataDlg && (
        <ReductionEditDlg
          selected={selectedData}
          isOpen={openCalendarDataDlg}
          title={calendarDataDlgTitle}
          deleteName={calendarDataDlgDeleteName}
          okName={calendarDataDlgSaveName}
          cancelName={calendarDataDlgCancelName}
          handleSave={handleCalendarSave}
          handleCancel={handleCalendarCancel}
        />
      )}

      {openCalenderListDlg && (
        <CalendarListDlg
          isOpen={openCalenderListDlg}
          title={calendarListDlgTitle}
          kindType={1}
          cancelName={calendarListDlgCloseName}
          handleClose={handleCalendarListClose}
        />
      )}

      {yearMenuOpen && (
        <StyledMenu
          anchorEl={yearMenuRef}
          open={yearMenuOpen}
          onClose={() => setYearMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="year-layout">
            {Array.isArray(yearData) &&
              yearData.map((value, _idx) => {
                return (
                  <div
                    key={`year-key-${_idx}`}
                    className="year-item"
                    onClick={() => handleYear(value)}
                  >
                    {value}
                  </div>
                );
              })}
          </div>
        </StyledMenu>
      )}
      <Grid item xs style={{ padding: "0px 10px 15px" }}>
        <FullCalendar
          ref={calendarRef}
          events={calendarData}
          height={"100%"}
          plugins={[dayGridPlugin, interactionPlugin]}
          editable={true}
          droppable={true}
          dayMaxEventRows={true}
          initialView={"dayGridMonth"}
          headerToolbar={{
            start: "handleYear todayEvent",
            center: "prevEvent title nextEvent",
            end: "addEvent",
          }}
          windowResizeDelay={10}
          locale={"ko"}
          titleFormat={{ month: "long" }}
          customButtons={{
            handleYear: {
              text: `${selectedYear} ▼`,
              click: (e) => setYearMenuRef(e.currentTarget),
            },
            addEvent: {
              text: "+ 항목 추가",
              click: handleCalendarDlg,
            },
            prevEvent: {
              icon: "chevron-left",
              click: handlePrev,
            },
            nextEvent: {
              icon: "chevron-right",
              click: handleNext,
            },
            todayEvent: {
              text: "오늘",
              click: handleToDay,
            },
          }}
          moreLinkClick={handleCalendarListDlg}
          moreLinkContent={(e) => `${e.shortText} 더보기`}
          eventClick={handleCalendarDlg}
          eventDrop={handleChange}
        />
      </Grid>
      <Backdrop className={classes.backdrop} open={progressData.open}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{progressData.content}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </Grid>
  );
}

export default ReductionCalender;
