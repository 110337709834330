import { createElement, PureComponent } from "react";
import chart from "./chart";
import "./index.css";

class OrgChart extends PureComponent {
  static defaultProps = {
    id: "org-chart",
    downloadPdfId: "download-pdf",
    zoomInId: "zoom-in",
    zoomOutId: "zoom-out",
  };

  componentDidMount() {
    const { id, downloadPdfId, zoomInId, zoomOutId, ...options } = this.props;

    chart({
      id: `#${id}`,
      downloadPdfId: `#${downloadPdfId}`,
      zoomInId: zoomInId,
      zoomOutId: zoomOutId,
      ...options,
    });
  }

  render() {
    const { id } = this.props;

    return createElement("div", { id });
  }
}

export default OrgChart;
