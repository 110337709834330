import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";

import { PathName } from "../../define";

import { useDispatch } from "react-redux";

import { sendBackend } from "../../utils/backend";

import { resetAppData } from "../../utils/Utils";

function Logout() {
  const dispatch = useDispatch();
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const apiPath = "v1/auth/logout";
    const queryData = {};

    sendBackend(true, apiPath, queryData, (success) => {
      resetAppData(dispatch, false);
      setFinished(true);
    });
  }, []);

  if (!finished) {
    return <></>;
  } else {
    return <Redirect to={PathName.Main} />;
  }
}

export default Logout;
