import { ACTION_TYPE_MENU_INFO } from "../actions/type";
import produce from "immer";

const initialState = { data: [] };

export default function r_menuInfo(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPE_MENU_INFO:
        if (!Array.isArray(action.status.data)) {
          draft.data = [];
        } else {
          draft.data = action.status.data;
        }
        break;
      default:
        break;
    }
  });
}
