import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { Backdrop, TextField, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../utils/Utils";
import { sendBackend } from "../../../utils/backend";
import { MessageDialog, ConfirmDialog } from "../../../components";
import { PlasticEditDlg } from ".";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const TextField1 = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    padding: "5px 10px 3px",
    "& .MuiInputBase-root": {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(TextField);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#calendar-data-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

let r_user;
let apiCallTime;
function PlasticListDlg(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();
  const { t } = useTranslation();

  const [gridApi, setGridApi] = useState(null);
  const [listData, setListData] = useState(undefined);
  const defaultColDef = { wrapText: true, autoHeight: true };

  const [selected, setSelected] = useState(undefined);
  const [newPlastic, setNewPlastic] = useState(undefined);

  const [openPlasticEditDlg, setOpenPlasticEditDlg] = useState(false);
  const [plasticEditDlgTitle, setPlasticEditDlgTitle] = useState(undefined);
  const plasticEditDlgSaveName = t("MessageBox.save");
  const plasticEditDlgCancelName = t("MessageBox.cancel");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgType, setConfirmDlgType] = useState(-1);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const [isLoding, setIsLoding] = useState(false);

  const cellRenderer = (data) => {
    let strVal = data;
    let eGui = document.createElement("div");
    eGui.title = `${strVal}`;
    eGui.innerHTML = `${strVal}`;
    return eGui;
  };

  const plasticColDef = [
    {
      headerName: "",
      field: "checked",
      sortable: true,
      resizable: true,
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: t("PlasticListDlg.plastic_code"),
      field: "plastic_code",
      sortable: true,
      resizable: true,
      width: 120,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => cellRenderer(params.data.plastic_code),
    },
    {
      headerName: t("PlasticListDlg.plastic_type"),
      field: "plastic_type",
      sortable: true,
      resizable: true,
      width: 360,
      cellRenderer: (params) => cellRenderer(params.data.plastic_type),
    },
  ];

  r_user = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    if (!props.title) return props.handleClose();

    handleSearch();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    if (gridApi) window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [gridApi]);

  const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    let strA = isEmpty(valueA) ? "" : valueA.toString();
    strA = strA.toLowerCase();
    let strB = isEmpty(valueB) ? "" : valueB.toString();
    strB = strB.toLowerCase();

    return strA.localeCompare(strB, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const handleSearch = () => {
    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/plastic";
    const queryData = {
      tokenUser: r_user,
      kindType: props.kindType,
      apply_date: props.title,
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);

      if (success && res && res.data)
        if (res.data.plastic_list) setListData(res.data.plastic_list);

      setIsLoding(false);
    });

    apiCallTime = runTime;
  };

  const handleAdd = () => {
    gridApi.deselectAll();
    setSelected(undefined);
    setNewPlastic(undefined);
    setPlasticEditDlgTitle(t("PlasticListDlg.add"));
    setOpenPlasticEditDlg(true);
  };

  const handleEdit = () => {
    let _selected = gridApi.getSelectedRows();

    if (!Array.isArray(_selected) || _selected.length < 1) {
      setMessageDlgContent(t("PlasticListDlg.msg.selected"));
      setOpenMessageDlg(true);
      return;
    } else if (_selected.length > 1) {
      setMessageDlgContent(t("PlasticListDlg.msg.selectedOne"));
      setOpenMessageDlg(true);
      return;
    }

    gridApi.deselectAll();
    setSelected(_selected.shift());
    setNewPlastic(undefined);
    setPlasticEditDlgTitle(t("PlasticListDlg.edit"));
    setOpenPlasticEditDlg(true);
  };

  const handleDelete = () => {
    let _selected = gridApi.getSelectedRows();

    if (!Array.isArray(_selected) || _selected.length < 1) {
      setMessageDlgContent(t("PlasticListDlg.msg.selected"));
      setOpenMessageDlg(true);
      return;
    }

    setSelected(_selected);
    setConfirmDlgTitle(t("PlasticListDlg.delete"));
    setConfirmDlgContent(t("PlasticListDlg.msg.delete"));
    setOpenConfirmDlg(true);
  };

  const handleSearchGrid = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const onRowDoubleClicked = (event) => {
    gridApi.deselectAll();
    setSelected(event.data);
    setNewPlastic(undefined);
    setPlasticEditDlgTitle(t("PlasticListDlg.edit"));
    setOpenPlasticEditDlg(true);
  };

  const handleChecked = (event) => {
    event.node.setSelected(!event.node.selected);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContent("");
    setOpenMessageDlg(false);
  };

  const handlePlasticSave = (_plastic) => {
    setNewPlastic(_plastic);
    setConfirmDlgTitle(t("PlasticListDlg.save"));
    setConfirmDlgContent(t("PlasticListDlg.msg.save"));
    setOpenConfirmDlg(true);
    setPlasticEditDlgTitle(false);
  };

  const handlePlasticCancel = () => {
    setOpenPlasticEditDlg(false);
  };

  const onOkConfirmDlg = () => {
    setOpenConfirmDlg(false);

    if (!selected && !newPlastic) return;

    const runTime = new Date().getTime();
    const apiPath = `v1/datamanagement/${
      Array.isArray(selected) ? "deletePlastic" : "updatePlastic"
    }`;
    const queryData = {
      tokenUser: r_user,
      selected,
      plastic: newPlastic,
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);
      if (res && res.data)
        if (res.data.code === 100) {
          setMessageDlgContent(t("PlasticListDlg.msg.already"));
          setOpenMessageDlg(true);
          return setIsLoding(false);
        }

      setIsLoding(false);
      setTimeout(() => handleSearch());
    });

    apiCallTime = runTime;
  };

  const onCancelConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };

  const onCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCloseConfirmDlg}
        />
      )}

      {openPlasticEditDlg && (
        <PlasticEditDlg
          isOpen={openPlasticEditDlg}
          plastic={selected}
          title={plasticEditDlgTitle}
          okName={plasticEditDlgSaveName}
          cancelName={plasticEditDlgCancelName}
          handleSave={handlePlasticSave}
          handleClose={handlePlasticCancel}
        />
      )}

      <Dialog
        // disableEscapeKeyDown
        maxWidth="sm"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="calendar-data-dialog-title"
      >
        <DialogTitle
          className={s_header.titlebar}
          id="calendar-data-dialog-title"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={10} container direction="row" alignItems="center">
              <Typography className={s_header.title}>{props.title}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "0px 15px 15px", minHeight: "400px" }}
          >
            <Grid item xs>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                style={{ height: "100%" }}
              >
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                    <Grid item style={{ paddingTop: "10px" }}>
                      <Button1 onClick={handleAdd}>
                        <AddIcon />
                        <Typography
                          className={s_content.label}
                          style={{ paddingLeft: "5px" }}
                        >
                          {t("PlasticListDlg.add")}
                        </Typography>
                      </Button1>
                    </Grid>
                    <Grid item style={{ padding: "10px 10px 0px" }}>
                      <Button1 onClick={handleEdit}>
                        <EditIcon />
                        <Typography
                          className={s_content.label}
                          style={{ paddingLeft: "5px" }}
                        >
                          {t("PlasticListDlg.edit")}
                        </Typography>
                      </Button1>
                    </Grid>
                    <Grid item style={{ padding: "10px 0px 0px" }}>
                      <Button1 onClick={handleDelete}>
                        <DeleteIcon />
                        <Typography
                          className={s_content.label}
                          style={{ paddingLeft: "5px" }}
                        >
                          {t("PlasticListDlg.delete")}
                        </Typography>
                      </Button1>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ paddingTop: "10px" }}>
                  <TextField1
                    placeholder={t("PlasticListDlg.search")}
                    onChange={handleSearchGrid}
                    style={{ marginBottom: '5px', borderRadius: "5px" }}
                  />
                </Grid>
                <Grid item xs>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={listData}
                      rowStyle={{ cursor: "pointer" }}
                      rowSelection="multiple"
                      defaultColDef={defaultColDef}
                      tooltipShowDelay={500}
                      // headerHeight={30}
                      // rowHeight={42}
                      suppressRowClickSelection="true"
                      // suppressRowHoverHighlight="true"
                      suppressCellSelection="true"
                      enableCellTextSelection="true"
                      suppressMovableColumns="true"
                      onGridReady={(params) => setGridApi(params.api)}
                      localeText={{ noRowsToShow: " " }}
                      onColumnResized={(params) => params.api.resetRowHeights()}
                      onCellClicked={handleChecked}
                      onRowDoubleClicked={onRowDoubleClicked}
                    >
                      {Array.isArray(plasticColDef) &&
                        plasticColDef.map((item) => {
                          return (
                            <AgGridColumn
                              key={item.headerName}
                              headerName={item.headerName}
                              headerTooltip={item.headerName}
                              field={item.field}
                              hide={item.hide}
                              // type={item.type}
                              width={item.width}
                              flex={item.flex}
                              // tooltipField={item.tooltipField}
                              sortable={item.sortable}
                              resizable={item.resizable}
                              filter={false}
                              // valueGetter={item.valueGetter}
                              cellClass={item.cellClass}
                              cellStyle={item.cellStyle}
                              cellRenderer={item.cellRenderer}
                              comparator={gridComparator}
                              headerCheckboxSelection={
                                item.headerCheckboxSelection
                              }
                              checkboxSelection={item.checkboxSelection}
                            ></AgGridColumn>
                          );
                        })}
                    </AgGridReact>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item style={{ paddingTop: "20px" }}>
                <Button1 className={s_content.btn} onClick={handleClose}>
                  <Typography className={s_content.btn_label}>
                    {props.cancelName}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Backdrop className={s_content.backdrop} open={isLoding}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item style={{ marginTop: "10px" }}>
              <p>{t("PlasticListDlg.loding")}</p>
            </Grid>
          </Grid>
        </Backdrop>
      </Dialog>
    </>
  );
}

export default PlasticListDlg;
