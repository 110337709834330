import { isEmpty } from "../../../utils/Utils";

export const onClearTable = (instance) => {
  if (isEmpty(instance)) return;
  let tableColumns = instance.getSettings().columns;
  if (!isEmpty(tableColumns)) {
    for (let loop1 = 0; loop1 < tableColumns.length; loop1++) {
      let tableColumn = tableColumns[loop1];
      if (!isEmpty(tableColumn)) {
        tableColumn.readOnly = false;
      }
    }
    instance.updateSettings({ columns: tableColumns });
  }
  // instance.clear();
  instance.deselectCell();
  instance.loadData([]);
  instance.updateSettings({
    colWidths: undefined,
    rowHeights: undefined,
    columns: undefined,
    data: undefined,
    cells: undefined,
    autoColumnSize: undefined,
    fixedRowsTop: 0,
    fixedColumnsLeft: 0,
    mergeCells: false,
    contextMenu: undefined,
    allowInsertRow: false,
    allowRemoveRow: false,
    maxRows: Infinity,
    maxCols: Infinity,
    readOnly: true,
    undo: false,
  });

  const plugin = instance.getPlugin("UndoRedo");
  plugin.doneActions.length = 0;
  plugin.undoneActions.length = 0;
};
