import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

import CloseIcon from "@material-ui/icons/Close";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";

import Avatar from "@material-ui/core/Avatar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { DrawerWidth } from "../../../define";
import { isEmpty } from "../../../utils/Utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: DrawerWidth,
    flexShrink: 0,
  },
  paper: {
    top: "40px",
    width: DrawerWidth,
    height: "calc(100% - 40px)",
    boxSizing: "border-box",
    // top: "58px",
    // width: drawerWidth,
    // height: `calc(100% - 58px)`,
    // zIndex: "1036",
    // border: "1px solid #aeaeae",
  },
}));

function LeftSidebar(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [collapseType, setCollapseType] = useState(-1);
  const [menuInfo, setMenuInfo] = useState([]);

  const nowMenu = props.nowMenu;

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.open, setOpen]);

  useEffect(() => {
    let newMenuInfo = [];
    let newCollapseType = -1;
    if (Array.isArray(props.menuDatas)) {
      for (let i = 0; i < props.menuDatas.length; i++) {
        let menuData = props.menuDatas[i];

        let newMenuDatas = [];
        let idx = newMenuInfo.findIndex(
          (item) => item.group_pkey === menuData.group_pkey
        );
        if (idx === -1) {
          newMenuInfo.push({
            group_pkey: menuData.group_pkey,
            group_name: menuData.group_name,
            menuDatas: newMenuDatas,
          });
        } else {
          newMenuDatas = newMenuInfo[idx].menuDatas;
        }

        newMenuDatas.push(menuData);
      }

      if (nowMenu) {
        newCollapseType = newMenuInfo.findIndex(
          (item) => item.group_pkey === nowMenu.group_pkey
        );
      }
    }
    setCollapseType(newCollapseType);
    setMenuInfo(newMenuInfo);
  }, [props.menuDatas]);

  const onCloseSidebar = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      props.setOpen(false);
    }
  };

  const handleClick = (index) => {
    if (collapseType !== index) {
      setCollapseType(index);
    } else {
      setCollapseType(-1);
    }
  };

  const handleItemClick = (menuData) => {
    if (props.onChangeSidebar) {
      props.onChangeSidebar(menuData);
    }
  };

  return (
    <Drawer
      className={classes.root}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: "100%", minHeight: "34px", padding: "10px 0.5rem" }}
      >
        <Grid item>
          <IconButton size="small" onClick={onCloseSidebar}>
            <CloseIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </Grid>
      </Grid>
      {menuInfo.length === 0 && <Divider />}
      {menuInfo.length > 0 &&
        menuInfo.map((menuItem, index) => {
          const menuName = menuItem.group_name;

          return (
            <div key={menuName}>
              {index === 0 && <Divider className="sidebar-divider" />}
              <List className="sidebar-list" component="nav">
                <ListItem
                  button
                  className="sidebar-list-item"
                  onClick={() => handleClick(index)}
                >
                  <Avatar className="sidebar-list-item-icon" variant="square">
                    {menuItem.iconName}
                  </Avatar>
                  <Typography
                    className="sidebar-list-item-text"
                    title={menuName}
                  >
                    {menuName}
                  </Typography>
                  {collapseType === index ? (
                    <ExpandMoreIcon style={{ fontSize: "18px" }} />
                  ) : (
                    <ChevronRightIcon style={{ fontSize: "18px" }} />
                  )}
                </ListItem>
                <Collapse
                  in={collapseType === index}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className="collapse-sidebar-list" component="div">
                    {menuItem.menuDatas.map((menuData) => {
                      const fileName = menuData.fileName;

                      let isSelected = false;
                      if (!isEmpty(nowMenu)) {
                        if (menuData.menu_pkey === nowMenu.menu_pkey) {
                          isSelected = true;
                        }
                      }

                      return (
                        <ListItem
                          button
                          key={`${menuName}${fileName}`}
                          className="collapse-sidebar-list-item"
                          style={{
                            background: isSelected ? "transparent" : "",
                          }}
                          onClick={() => handleItemClick(menuData)}
                        >
                          <FiberManualRecordIcon className="collapse-sidebar-list-item-icon" />
                          <Typography
                            className="collapse-sidebar-list-item-text"
                            style={{
                              color: isSelected ? "#00b5ef" : "",
                              fontWeight: isSelected ? "bold" : "",
                            }}
                            title={fileName}
                          >
                            {fileName}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </List>
              <Divider className="sidebar-divider" />
            </div>
          );
        })}
    </Drawer>
  );
}

export default LeftSidebar;
