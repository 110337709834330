import React, { useState } from "react";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Drawer, Typography } from "@material-ui/core";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { Button, IconButton } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import ExcelJS from "exceljs";

import { getLocale } from "../../../../utils/Utils";

import moment from "moment-timezone";

import "moment/min/locales.min";

import { DrawerWidth } from "../../../../define";

import { useTranslation } from "react-i18next";

moment.locale(getLocale());

const useStyles = makeStyles((theme) => ({
  root: {
    width: DrawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: DrawerWidth,
    boxSizing: "border-box",
    padding: "14px",
  },
  title: {
    fontSize: "24px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
  },
  content: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#565656",
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#00b5ef",
    "&:hover": {
      backgroundColor: "#00a0ef",
    },
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    borderRadius: "3px",
    padding: "6px 9px",
  },
}))(Button1);

const FontTypography = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    letterSpacing: "normal",
    fontFamily: `"Source Sans Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
}))(Typography);

let fileInputRef;
function RightSidebar(props) {
  const classes = useStyles();

  if (!fileInputRef) {
    fileInputRef = React.createRef();
  }

  const { t } = useTranslation();

  const [gridLoading, setGridLoading] = useState(false);

  const onCloseSidebar = () => {
    if (props.onCloseSidebar) {
      props.onCloseSidebar();
    }
  };

  const getTitleText = () => {
    switch (props.sidebarType) {
      case "add":
        return t("UserManagement.GroupView.addMember");
      case "update":
        return t("UserManagement.GroupView.updateMembers");
      case "delete":
        return t("UserManagement.GroupView.deleteMember");
      default:
        return "";
    }
  };

  const handleDownload = () => {
    switch (props.sidebarType) {
      case "add":
        break;
      case "update":
        break;
      case "delete":
        break;
      default:
        return;
    }

    const workbook = new ExcelJS.Workbook();

    workbook.creator = "BizViu";
    workbook.lastModifiedBy = "BizViu System";
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("Sheet1");

    worksheet.columns = [
      {
        header: t("UserManagement.GroupView.format_group"),
        key: "groupName",
        width: 40,
        style: { alignment: { vertical: "middle", wrapText: true } },
      },
      {
        header: t("UserManagement.GroupView.format_id"),
        key: "mail",
        width: 35,
        style: { alignment: { vertical: "middle", wrapText: true } },
      },
    ];

    let rowData = { mail: "", groupName: "" };
    rowData.mail = `${t("UserManagement.GroupView.format_example")}) ${t(
      "UserManagement.GroupView.exam_id"
    )}`;
    rowData.groupName = `${t("UserManagement.GroupView.format_example")}) ${t(
      "UserManagement.GroupView.exam_group"
    )}`;
    worksheet.addRow(rowData);

    workbook.xlsx
      .writeBuffer()
      .then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${getTitleText()}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        console.error(err);
        props.onOpenMessageDlg(t("UserManagement.failureDownload"));
      });
  };

  const handleUpload = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChangeFile = (event) => {
    setGridLoading(true);
    const file = event.target.files[0];
    let reader = new FileReader();
    let workbook = new ExcelJS.Workbook();

    reader.onloadend = (e) => {
      let data = e.target.result;
      workbook.xlsx
        .load(data)
        .then((xlsxData) => {
          setGridLoading(false);
          onLoadFile(xlsxData);
        })
        .catch(() => {
          props.onOpenMessageDlg(t("DataManagement.fileInvalid"));
          setGridLoading(false);
        });
    };
    reader.readAsArrayBuffer(file);
    fileInputRef.current.value = "";
  };

  const getExcelValue = (cellData) => {
    let textValue = "";
    if (cellData && cellData.value) {
      switch (cellData.type) {
        // case ExcelJS.ValueType.Null:
        //   break;
        // case ExcelJS.ValueType.Merge:
        //   break;
        case ExcelJS.ValueType.Number:
          textValue = cellData.value.toString();
          break;
        case ExcelJS.ValueType.String:
          textValue = cellData.value.toString();
          break;
        case ExcelJS.ValueType.Date:
          // textValue = moment(cellData.value).format("YYYY-MM-DD'T'HH:mm:ss.SSS'Z'");
          textValue = moment(cellData.value).format("YYYY-MM-DD HH:mm:ss.SSS");
          break;
        case ExcelJS.ValueType.Hyperlink:
          if (cellData.value.text) {
            textValue = cellData.value.text.toString();
          }
          break;
        case ExcelJS.ValueType.Formula:
          if (cellData.value.result) {
            textValue = cellData.value.result.toString();
          }
          break;
        case ExcelJS.ValueType.SharedString:
          if (cellData.value.text) {
            textValue = cellData.value.text.toString();
          } else if (cellData.value.result) {
            textValue = cellData.value.result.toString();
          } else {
            textValue = cellData.value.toString();
          }
          break;
        case ExcelJS.ValueType.RichText:
          if (Array.isArray(cellData.value.richText)) {
            for (let i = 0; i < cellData.value.richText.length; i++) {
              let strTemp = cellData.value.richText[i].text;
              if (strTemp) {
                textValue += strTemp.toString();
              }
            }
          }
          break;
        case ExcelJS.ValueType.Boolean:
          textValue = cellData.value.toString();
          break;
        // case ExcelJS.ValueType.Error:
        //   break;
        default:
          break;
      }
    }
    return textValue.trim();
  };

  const onLoadFile = (xlsxData) => {
    let dataColumns = [
      {
        colName: t("UserManagement.GroupView.format_group"),
        dataKey: "group",
      },
      {
        colName: t("UserManagement.GroupView.format_id"),
        dataKey: "user",
      },
    ];

    const fileDatas = [];
    const columns = [];
    const errColumns = [];
    const worksheet = xlsxData.worksheets[0];
    const rowCount = worksheet.rowCount;
    for (let i = 1; i <= rowCount; i++) {
      const rowData = worksheet.getRow(i);

      if (i === 1) {
        for (let k = 1; k <= worksheet.columnCount; k++) {
          const cellData = rowData.getCell(k);

          let cellValue = "";
          if (cellData && cellData.value) {
            if (typeof cellData.value !== "string") {
              cellValue = cellData.value.toString().trim();
            } else {
              cellValue = cellData.value.trim();
            }
          }

          let idx = dataColumns.findIndex((item) => item.colName === cellValue);
          if (idx !== -1) {
            columns.push({
              colNum: k,
              colName: cellValue,
              dataKey: dataColumns[idx].dataKey,
            });
          } else {
            errColumns.push({
              colNum: k,
              colName: cellValue ? cellValue : "",
            });
          }
        }
        continue;
      }

      if (i === 2) {
        if (columns.length !== dataColumns.length) {
          break;
        }
        continue;
      }

      let fileData = {};
      let wrongRow = [false, false];
      for (let k = 0; k < columns.length; k++) {
        const colNum = columns[k].colNum;
        const dataKey = columns[k].dataKey;

        const cellData = rowData.getCell(colNum);
        let cellValue = getExcelValue(cellData);
        cellValue = cellValue ? cellValue : "";

        if (!cellValue) {
          wrongRow[k] = true;
        }

        if (dataKey === "group") {
          fileData[dataKey] = { group_pkey: "", name: cellValue };
          continue;
        } else if (dataKey === "user") {
          fileData[dataKey] = { id: "", mail: cellValue };
          continue;
        }

        fileData[dataKey] = cellValue;
      }

      if (wrongRow[0] && wrongRow[1]) {
        continue;
      }

      fileData.rowNumber = i;
      fileDatas.push(fileData);
    }

    if (columns.length !== dataColumns.length) {
      props.onOpenMessageDlg(t("DataManagement.columnNotMatch"));
      return;
    }

    if (fileDatas.length === 0) {
      props.onOpenMessageDlg(t("UserManagement.noUploadData"));
      return;
    }

    // let fileLen = rowCount - 2;
    // let dataLen = fileDatas.length;

    props.onFileUpload(props.sidebarType, fileDatas);
  };

  return (
    <>
      <Drawer
        className={classes.root}
        anchor="right"
        open={props.isOpen}
        classes={{
          paper: classes.paper,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.title}>
                {getTitleText()}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton1 size="small" onClick={onCloseSidebar}>
                <CloseIcon style={{ fontSize: "28px" }} />
              </IconButton1>
            </Grid>
          </Grid>

          <Grid item style={{ marginTop: "80px" }}>
            <Typography className={classes.content}>
              1. {t("UserManagement.GroupView.downloadTemplate")}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "5px", marginTop: "8px" }}>
            <ColorButton onClick={handleDownload}>
              <FontTypography>
                {t("UserManagement.GroupView.download")}
              </FontTypography>
            </ColorButton>
          </Grid>

          <Grid item style={{ marginTop: "30px" }}>
            <Typography className={classes.content}>
              2. {t("UserManagement.GroupView.fileUpload")}
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "5px", marginTop: "8px" }}>
            <ColorButton onClick={handleUpload}>
              <FontTypography>
                {t("UserManagement.GroupView.fileUpload")}
              </FontTypography>
            </ColorButton>
          </Grid>

          <Grid item>
            <input
              type="file"
              name="fileInput"
              accept=".xlsx"
              ref={fileInputRef}
              onChange={onChangeFile}
              style={{ display: "none" }}
            />
          </Grid>
        </Grid>
      </Drawer>

      <Backdrop className={classes.backdrop} open={gridLoading}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{t("DataManagement.importingFile")}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
}

export default RightSidebar;
