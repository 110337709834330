import { ACTION_TYPE_USER } from "../actions/type";
import produce from "immer";

const initialState = {
  id: "",
  mail: "",
  displayName: "",
  groups: [],
};

export default function r_user(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case ACTION_TYPE_USER:
        draft.id = action.status.id ? action.status.id : "";
        draft.mail = action.status.mail ? action.status.mail : "";
        draft.displayName = action.status.displayName
          ? action.status.displayName
          : "";
        draft.groups = Array.isArray(action.status.groups)
          ? action.status.groups
          : [];
        break;
      default:
        break;
    }
  });
}
