import * as d3 from "d3";

export default renderLines;

function renderLines(config = {}) {
  const {
    gNode,
    links,
    treeRoot,
    sourceNode,
    nodeWidth,
    nodeHeight,
    animationDuration,
  } = config;

  const eventNode = sourceNode || treeRoot;

  const link = gNode.selectAll("path.link").data(links, (d) => d.target.id);

  const angle = d3
    .line()
    .x((d) => d.x)
    .y((d) => d.y);

  const linkEnter = link
    .enter()
    .append("path")
    .attr("class", "link")
    .attr("fill", "none")
    .attr("stroke", "#b7b7b7")
    .attr("stroke-width", "4px")
    .attr("d", (d) => {
      const linePoints = [
        {
          x: eventNode.x0 + parseInt(nodeWidth / 2),
          y: eventNode.y0 + nodeHeight + 2,
        },
        {
          x: eventNode.x0 + parseInt(nodeWidth / 2),
          y: eventNode.y0 + nodeHeight + 2,
        },
        {
          x: eventNode.x0 + parseInt(nodeWidth / 2),
          y: eventNode.y0 + nodeHeight + 2,
        },
        {
          x: eventNode.x0 + parseInt(nodeWidth / 2),
          y: eventNode.y0 + nodeHeight + 2,
        },
      ];

      return angle(linePoints);
    });

  link
    .merge(linkEnter)
    .transition()
    .duration(animationDuration)
    .attr("d", (d) => {
      let lineYGap = (d.target.y - (d.source.y + nodeHeight)) / 1.4;
      const linePoints = [
        {
          x: d.source.x + parseInt(nodeWidth / 2),
          y: d.source.y + nodeHeight,
        },
        {
          x: d.source.x + parseInt(nodeWidth / 2),
          y: d.target.y - lineYGap,
        },
        {
          x: d.target.x + parseInt(nodeWidth / 2),
          y: d.target.y - lineYGap,
        },
        {
          x: d.target.x + parseInt(nodeWidth / 2),
          y: d.target.y,
        },
      ];

      return angle(linePoints);
    });

  link
    .exit()
    .transition()
    .duration(animationDuration)
    .attr("d", (d) => {
      const linePoints = [
        {
          x: eventNode.x + parseInt(nodeWidth / 2),
          y: eventNode.y + nodeHeight + 2,
        },
        {
          x: eventNode.x + parseInt(nodeWidth / 2),
          y: eventNode.y + nodeHeight + 2,
        },
        {
          x: eventNode.x + parseInt(nodeWidth / 2),
          y: eventNode.y + nodeHeight + 2,
        },
        {
          x: eventNode.x + parseInt(nodeWidth / 2),
          y: eventNode.y + nodeHeight + 2,
        },
      ];

      return angle(linePoints);
    })
    .remove();
}
