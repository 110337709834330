import React, { useState, useEffect } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";

import { PathName } from "../../define";

import { shallowEqual, useSelector, useDispatch } from "react-redux";

import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";

import { Grid, Backdrop, CircularProgress } from "@material-ui/core";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

import { useTranslation } from "react-i18next";

import { resetAppData } from "../../utils/Utils";

import "./index.scss";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { instance } = useMsal();

  const location = useLocation();
  const history = useHistory();

  const r_user = useSelector((state) => state.user, shallowEqual);

  const [showProgress, setShowProgress] = useState(false);

  if (document && document.body) {
    const body = document.body;
    if (body.className !== "hold-transition login-page") {
      body.style.height = "";
      body.className = "hold-transition login-page";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }
  }, []);

  useEffect(() => {
    if (r_user && r_user.data && r_user.data.id) {
      resetAppData(dispatch, false);
    }
  }, [r_user]);

  const handleLogin = (isPopup) => {
    if (!isPopup || isMobile) {
      instance
        .loginRedirect(loginRequest)
        .then((res) => {
          // setShowProgress(false);
          // history.replace(location.pathname);
          // // window.location.reload();
        })
        .catch((err) => {
          console.error(err && err.message ? err.message : err);
          window.location.reload();
        });
      setShowProgress(false);
      return;
    }

    instance
      .loginPopup(loginRequest)
      .then((res) => {
        // setShowProgress(false);
        // history.replace(location.pathname);
      })
      .catch((err) => {
        console.error(err && err.message ? err.message : err);
        setShowProgress(false);
      });
    setShowProgress(true);
  };

  return (
    <>
      <div className="login-box">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <Link to={PathName.Main}>
              <img
                className="login-logo"
                src="/img/atomy_logo_new.png"
                alt="BizViu"
              />
            </Link>
            <div>
              <span className="app-name">
                <span className="app-name-bold"></span>
              </span>
            </div>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{t("Login.title")}</p>

            <div className="row">
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary btn-block"
                  onClick={() => handleLogin(true)}
                >
                  <i className="fab fa-microsoft mr-1" />
                  {t("Login.login")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Backdrop className={classes.backdrop} open={showProgress}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{t("Login.backdrop.contents")}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
}

export default Login;
