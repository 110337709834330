import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { Backdrop, TextField, CircularProgress, Menu } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";

import { isEmpty } from "../../../utils/Utils";
import { sendBackend } from "../../../utils/backend";
import { MessageDialog, ConfirmDialog } from "../../../components";
import { CollectionEditDlg, ReductionEditDlg } from ".";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#565656",
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "1px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const Button2 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    borderRadius: 2,
    position: "relative",
    border: "1px solid #cccccc",
    margin: "0px",
    padding: "4px 0px 0px",
    "&.Mui-disabled": {
      backgroundColor: "#cccccc",
    },
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const TextField1 = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    padding: "5px 10px 3px",
    "& .MuiInputBase-root": {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(TextField);

const StyledMenu = withStyles({
  paper: {
    color: "#212529",
    margin: 0,
    padding: ".1rem 0",
    overflow: "overlay",
    fontSize: "1rem",
    borderTop: "none",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.175)",
    listStyle: "none",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    backgroundColor: "rgba(255, 255, 255, 0.8)",

    "& .MuiMenu-list": {
      padding: "0 !important",
      width: "100% !important",
    },

    "& .MuiMenuItem-root": {
      clear: "both",
      width: "100%",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "block",
      padding: 0,
      textAlign: "inherit",
      fontWeight: 400,
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#16181b",
        backgroundColor: "rgba(233, 236, 239, 0.8)",
      },
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
      display: "block",
      padding: "4px 10px",
    },

    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },

    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 5px",
      fontSize: "16px",
      fontFamily: "Noto Sans",
    },
  },
})((props) => (
  <Menu
    keepMounted
    disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#calendar-data-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const textRenderer = (value) => {
  let strVal = `${value}`;
  let element = document.createElement("div");
  element.title = strVal;
  element.innerHTML = strVal;
  element.style.overflow = "hidden";
  element.style.textOverflow = "ellipsis";
  return element;
};

const numberRenderer = (value, weightType) => {
  if (value) {
    switch (weightType) {
      case "kg(kilogram)":
        value = value / 1000;
        break;
      case "t(tonne)":
        value = value / 1000000;
        break;
      default:
        break;
    }
  }

  let strVal = `${value.toLocaleString()}`;
  let element = document.createElement("div");
  element.title = strVal;
  element.innerHTML = strVal;
  element.style.overflow = "hidden";
  element.style.textOverflow = "ellipsis";
  return element;
};

const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  let strA = isEmpty(valueA) ? "" : valueA.toString();
  strA = strA.toLowerCase();
  let strB = isEmpty(valueB) ? "" : valueB.toString();
  strB = strB.toLowerCase();

  return strA.localeCompare(strB, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

let r_user;
let apiCallTime;
function CalendarListDlg(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();
  const { t } = useTranslation();

  const [gridApi, setGridApi] = useState(null);
  const [listData, setListData] = useState(undefined);

  const weightData = ["g(gram)", "kg(kilogram)", "t(tonne)"];

  const [selected, setSelected] = useState(undefined);
  const [selectedWeight, setSelectedWeight] = useState(weightData[0]);

  const [weightMenuRef, setWeightMenuRef] = useState(undefined);
  const weightMenuOpen = Boolean(weightMenuRef);

  const collectionWeight = t("CalendarListDlg.collection");
  const [weightHeaderName, setWeightHeaderName] = useState(
    collectionWeight.replace("x", selectedWeight.split("(")[1].split(")")[0])
  );

  const [openCalendarDataDlg, setOpenCalendarDataDlg] = useState(false);
  const calendarDataDlgDeleteName = t("MessageBox.delete");
  const calendarDataDlgSaveName = t("MessageBox.save");
  const calendarDataDlgCancelName = t("MessageBox.cancel");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const [isLoding, setIsLoding] = useState(false);

  const reductionColDef = [
    {
      headerName: "",
      field: "checked",
      sortable: true,
      resizable: true,
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: t("CalendarListDlg.materialCode"),
      field: "material_code",
      sortable: true,
      resizable: true,
      filter: false,
      width: 100,
      comparator: gridComparator,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("CalendarListDlg.materialName"),
      field: "material_name",
      sortable: true,
      resizable: true,
      filter: false,
      width: 200,
      comparator: gridComparator,
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("CalendarListDlg.plastic_code"),
      field: "plastic_code",
      sortable: true,
      resizable: true,
      filter: false,
      width: 120,
      comparator: gridComparator,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("CalendarListDlg.plasticType"),
      field: "plastic_type",
      sortable: true,
      resizable: true,
      filter: false,
      width: 200,
      comparator: gridComparator,
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("CalendarListDlg.infancy"),
      field: "infancy",
      sortable: true,
      resizable: true,
      filter: false,
      width: 150,
      comparator: gridComparator,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("CalendarListDlg.reduction"),
      field: "reduction",
      sortable: true,
      resizable: true,
      filter: false,
      width: 150,
      comparator: gridComparator,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => textRenderer(params.value),
    },
    {
      headerName: t("ReductionEditDlg.contents"),
      field: "contents",
      sortable: true,
      resizable: true,
      filter: false,
      width: 260,
      comparator: gridComparator,
      cellRenderer: (params) => textRenderer(params.value),
    },
  ];

  const collectionColDef = [
    {
      headerName: "",
      field: "checked",
      sortable: true,
      resizable: true,
      width: 50,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: weightHeaderName,
      field: "weight",
      sortable: true,
      resizable: true,
      width: 200,
      comparator: gridComparator,
      cellClass: "ag-cell-wrapper-center",
      cellRenderer: (params) => numberRenderer(params.value, selectedWeight),
    },
    {
      headerName: t("CollectionEditDlg.contents"),
      field: "contents",
      sortable: true,
      resizable: true,
      width: 970,
      comparator: gridComparator,
      cellRenderer: (params) => textRenderer(params.value),
    },
  ];

  r_user = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    if (!props.title) return props.handleCancel();

    handleSearch();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    if (gridApi) window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) return;

    setTimeout(() => {
      gridApi.refreshHeader();
      gridApi.redrawRows();
    });
  }, [selectedWeight]);

  const handleSearch = () => {
    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/list";
    const queryData = {
      tokenUser: r_user,
      kindType: props.kindType,
      apply_date: props.title,
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);

      if (success && res && res.data)
        if (res.data.list) setListData(res.data.list);

      setIsLoding(false);
    });

    apiCallTime = runTime;
  };

  const handleAdd = () => {
    gridApi.deselectAll();
    setSelected(undefined);
    setTimeout(() => setOpenCalendarDataDlg(true));
  };

  const handleEdit = () => {
    let _selected = gridApi.getSelectedRows();

    if (!Array.isArray(_selected) || _selected.length < 1) {
      setMessageDlgContent(t("CalendarListDlg.msg.selected"));
      setOpenMessageDlg(true);
      return;
    } else if (_selected.length > 1) {
      setMessageDlgContent(t("CalendarListDlg.msg.selectedOne"));
      setOpenMessageDlg(true);
      return;
    }

    gridApi.deselectAll();
    setSelected(_selected.shift());
    setTimeout(() => setOpenCalendarDataDlg(true));
  };

  const handleDelete = () => {
    let _selected = gridApi.getSelectedRows();

    if (!Array.isArray(_selected) || _selected.length < 1) {
      setMessageDlgContent(t("CalendarListDlg.msg.selected"));
      setOpenMessageDlg(true);
      return;
    }

    setConfirmDlgContent(t("CalendarListDlg.msg.delete"));
    setOpenConfirmDlg(true);
  };

  const handleWeightType = (newValue) => {
    setWeightMenuRef(undefined);
    setSelectedWeight(newValue);
    setWeightHeaderName(
      collectionWeight.replace("x", newValue.split("(")[1].split(")")[0])
    );
  };

  const handleSearchGrid = (e) => {
    gridApi.setQuickFilter(e.target.value);
  };

  const onRowDoubleClicked = (event) => {
    gridApi.deselectAll();
    setSelected(event.data);
    setTimeout(() => setOpenCalendarDataDlg(true));
  };

  const handleChecked = (event) => {
    event.node.setSelected(!event.node.selected);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContent("");
    setOpenMessageDlg(false);
  };

  const handleCalendarSave = () => {
    handleSearch();
    setOpenCalendarDataDlg(false);
  };

  const handleCalendarCancel = () => {
    setOpenCalendarDataDlg(false);
  };

  const onOkConfirmDlg = () => {
    setOpenConfirmDlg(false);

    let _selected = gridApi.getSelectedRows();

    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/delete";
    const queryData = {
      tokenUser: r_user,
      kindType: props.kindType,
      apply_date: props.title,
      selected: _selected,
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);

      setIsLoding(false);
      setTimeout(() => handleSearch());
    });

    apiCallTime = runTime;
  };

  const onCancelConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };

  const onCloseConfirmDlg = () => {
    setOpenConfirmDlg(false);
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}
      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={onOkConfirmDlg}
          onCancelConfirmDlg={onCancelConfirmDlg}
          onCloseConfirmDlg={onCloseConfirmDlg}
        />
      )}

      {openCalendarDataDlg && (
        <>
          {props.kindType ? (
            <ReductionEditDlg
              isOpen={openCalendarDataDlg}
              selected={selected}
              title={props.title}
              deleteName={calendarDataDlgDeleteName}
              okName={calendarDataDlgSaveName}
              cancelName={calendarDataDlgCancelName}
              handleSave={handleCalendarSave}
              handleCancel={handleCalendarCancel}
            />
          ) : (
            <CollectionEditDlg
              isOpen={openCalendarDataDlg}
              selected={selected}
              title={props.title}
              deleteName={calendarDataDlgDeleteName}
              okName={calendarDataDlgSaveName}
              cancelName={calendarDataDlgCancelName}
              handleSave={handleCalendarSave}
              handleCancel={handleCalendarCancel}
            />
          )}
        </>
      )}

      <Dialog
        // disableEscapeKeyDown
        maxWidth="lg"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            return;
          }
          handleClose();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="calendar-data-dialog-title"
      >
        <DialogTitle
          className={s_header.titlebar}
          id="calendar-data-dialog-title"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={10} container direction="row" alignItems="center">
              <Typography className={s_header.title}>{props.title}</Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            style={{ padding: "0px 15px 15px", minHeight: "400px" }}
          >
            <Grid item xs>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                style={{ height: "100%" }}
              >
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs>
                    <Grid container direction="row" alignItems="center">
                      <Grid item style={{ paddingTop: "10px" }}>
                        <Button1 onClick={handleAdd}>
                          <AddIcon />
                          <Typography
                            className={s_content.label}
                            style={{ paddingLeft: "5px" }}
                          >
                            {t("CalendarListDlg.add")}
                          </Typography>
                        </Button1>
                      </Grid>
                      <Grid item style={{ padding: "10px 10px 0px" }}>
                        <Button1 onClick={handleEdit}>
                          <EditIcon />
                          <Typography
                            className={s_content.label}
                            style={{ paddingLeft: "5px" }}
                          >
                            {t("CalendarListDlg.edit")}
                          </Typography>
                        </Button1>
                      </Grid>
                      <Grid item style={{ padding: "10px 0px 0px" }}>
                        <Button1 onClick={handleDelete}>
                          <DeleteIcon />
                          <Typography
                            className={s_content.label}
                            style={{ paddingLeft: "5px" }}
                          >
                            {t("CalendarListDlg.delete")}
                          </Typography>
                        </Button1>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs style={{ paddingTop: "10px" }}>
                    <Grid container direction="row" alignItems="center">
                      {/* <Grid item>
                        <Button2
                          onClick={(e) => setWeightMenuRef(e.currentTarget)}
                          style={{ height: "32px", width: "120px" }}
                        >
                          <span
                            className="text-font"
                            style={{
                              fontSize: "14px",
                              fontWeight: "bold",
                              color: "#262626",
                              textTransform: "none",
                            }}
                          >
                            {selectedWeight} ▼
                          </span>
                        </Button2>
                        <StyledMenu
                          anchorEl={weightMenuRef}
                          open={weightMenuOpen}
                          onClose={() => setWeightMenuRef(undefined)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          disableScrollLock={true}
                          style={{ marginTop: "0px" }}
                        >
                          <div className="weight-layout">
                            {Array.isArray(weightData) &&
                              weightData.map((value, _idx) => {
                                return (
                                  <div
                                    key={`weight-key-${_idx}`}
                                    className="weight-item"
                                    onClick={() => handleWeightType(value)}
                                  >
                                    {value}
                                  </div>
                                );
                              })}
                          </div>
                        </StyledMenu>
                      </Grid> */}
                      <Grid item xs style={{ paddingLeft: "10px" }}>
                        <TextField1
                          placeholder={t("CalendarListDlg.search")}
                          onChange={handleSearchGrid}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs style={{ paddingTop: "10px" }}>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      rowData={listData}
                      rowStyle={{ cursor: "pointer" }}
                      rowSelection="multiple"
                      columnDefs={
                        props.kindType ? reductionColDef : collectionColDef
                      }
                      tooltipShowDelay={500}
                      headerHeight={40}
                      suppressMovableColumns="true"
                      suppressRowClickSelection="true"
                      enableCellTextSelection="true"
                      suppressCellSelection="true"
                      onGridReady={(params) => setGridApi(params.api)}
                      localeText={{ noRowsToShow: " " }}
                      onColumnResized={(params) => {
                        params.api.resetRowHeights();
                      }}
                      onCellClicked={handleChecked}
                      onRowDoubleClicked={onRowDoubleClicked}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item style={{ paddingTop: "20px" }}>
                <Button1 className={s_content.btn} onClick={handleClose}>
                  <Typography className={s_content.btn_label}>
                    {props.cancelName}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Backdrop className={s_content.backdrop} open={isLoding}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item style={{ marginTop: "10px" }}>
            <p>{t("CalendarListDlg.loding")}</p>
          </Grid>
        </Grid>
      </Backdrop>
    </>
  );
}

export default CalendarListDlg;
