/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param endpoint
 * @param token
 */
export async function callMsGraph(endpoint, token) {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(endpoint, options).then((response) => response.json());
}
