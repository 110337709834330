import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { CircularProgress } from "@material-ui/core";

import "./index.scss";

function Error(props) {
  const { viewType } = props;

  const { t } = useTranslation();

  if (document && document.body) {
    const body = document.body;
    if (
      body.className !== "hold-transition sidebar-collapse layout-navbar-fixed"
    ) {
      body.style.height = "";
      body.className = "hold-transition sidebar-collapse layout-navbar-fixed";
    }
  }

  useEffect(() => {
    if (document && document.body) {
      const body = document.body;
      body.oncontextmenu = () => {
        return false;
      };
    }
  }, []);

  const getIconColor = () => {
    switch (viewType) {
      case "Blank.Wrong":
      case "Report.Wrong":
      case "Report.OnlyDeskTop":
      case "Report.OnlyMobile":
        return "#ffc107";
    }
    return "#dc3545";
  };

  const getTitleText = () => {
    let title = "";
    switch (viewType) {
      case "Blank.Wrong":
        title = t("Blank.Wrong.title");
        break;
      case "Blank.Error":
        title = t("Blank.Error.title");
        break;
      case "Report.Wrong":
        title = t("Report.Wrong.title");
        break;
      case "Report.OnlyDeskTop":
        title = t("Report.OnlyDeskTop.title");
        break;
      case "Report.OnlyMobile":
        title = t("Report.OnlyMobile.title");
        break;
      case "Report.Error":
        title = t("Report.Error.title");
        break;
      default:
        title = t("NotFound.title");
        break;
    }
    return title;
  };

  const getMessageText = () => {
    let message = "";
    switch (viewType) {
      case "Blank.Wrong":
        message = t("Blank.Wrong.message");
        break;
      case "Blank.Error":
        message = t("Blank.Error.message");
        break;
      case "Report.Wrong":
        message = t("Report.Wrong.message");
        break;
      case "Report.OnlyDeskTop":
        message = t("Report.OnlyDeskTop.message");
        break;
      case "Report.OnlyMobile":
        message = t("Report.OnlyMobile.message");
        break;
      case "Report.Error":
        message = t("Report.Error.message");
        break;
      default:
        message = t("NotFound.message");
        break;
    }

    if (props.errorCode) {
      message = `${message} (Code: ${props.errorCode})`;
    }

    return message;
  };

  const getRender = () => {
    const contentsRender = (
      <div className="error-page">
        <h2 className="headline text-danger">
          <i
            className="fa fa-exclamation-triangle"
            style={{ fontSize: "80px", color: getIconColor() }}
          ></i>
        </h2>

        <div className="error-content">
          <p className="error-content-title">{getTitleText()}</p>
          <p>{getMessageText()}</p>
        </div>
      </div>
    );

    switch (viewType) {
      case "Loading":
        return (
          <div className="content-wrapper">
            <section className="content loading-section">
              <div>
                <CircularProgress style={{ color: "#AAAAAA" }} />
              </div>
            </section>
          </div>
        );
      // case "Blank.Wrong":
      // case "Blank.Error":
      case "Report.Wrong":
        if (props.errorCode && props.errorCode === 10000) {
          return (
            <div className="content-wrapper">
              <section className="content">{contentsRender}</section>
            </div>
          );
        }
        return contentsRender;
      // case "Report.OnlyDeskTop":
      // case "Report.OnlyMobile":
      case "Report.Error":
        return contentsRender;
      default:
        return (
          <div className="content-wrapper">
            <section className="content">{contentsRender}</section>
          </div>
        );
    }
  };

  return getRender();
}

export default Error;
