import React, { useEffect, useState, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";

import { Menu, Backdrop, TextField, CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";

import { useTranslation } from "react-i18next";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./grid.css";

import { PlasticListDlg } from ".";
import { isEmpty } from "../../../utils/Utils";
import { sendBackend } from "../../../utils/backend";
import { MessageDialog, ConfirmDialog } from "../../../components";

import moment from "moment-timezone";
import "moment/min/locales.min";

const headerStyles = makeStyles((theme) => ({
  titlebar: {
    position: "relative",
    backgroundColor: "#7ec5f8",
    minHeight: "36px",
    maxHeight: "36px",
    cursor: "move",
    padding: "0 15px 0",
  },
  title: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    fontWeight: "bold",
    color: "#ffffff",
  },
  content: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
    padding: "20px 0px 20px",
  },
}));

const contentStyles = makeStyles((theme) => ({
  btn: {
    width: "97px",
    height: "33px",
    color: "#ffffff",
    backgroundColor: "#999999",
    "&:hover": {
      color: "#ffffff",
      backgroundColor: "#565656",
    },
  },
  btn_label: {
    fontSize: "16px",
    fontFamily: "Noto Sans",
    color: "#ffffff",
  },
  label: {
    fontSize: "18px",
    fontFamily: "Noto Sans",
    color: "#565656",
  },
  searchPaper: {
    margin: 0,
    border: "1px solid #ced4da",
    borderRadius: "10px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderTopWidth: 0,
  },
  searchListBox: {
    maxHeight: "232px",
  },
  searchLabel: { fontSize: "12px", fontFamily: "Noto Sans", color: "#565656" },
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const Button1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

const IconButton1 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      padding: "0px",
      margin: "0px",
    },
    outline: "none !important",
  },
}))(IconButton);

const TextField1 = withStyles((theme) => ({
  root: {
    width: "100%",
    border: "1px solid #ccc",
    padding: "4px 10px 2px",
    "& .MuiInputBase-root": {
      fontFamily: "Noto Sans",
      fontSize: "16px",
      fontWeight: "bold",
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:hover:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(TextField);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#00b5ef",
    "&:hover": {
      backgroundColor: "#00a0ef",
    },
    "&:disabled": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    borderRadius: "3px",
    padding: "3px 6px",
  },
}))(Button1);

const StyledMenu = withStyles({
  paper: {
    color: "#212529",
    margin: 0,
    padding: ".1rem 0",
    overflow: "overlay",
    fontSize: "1rem",
    borderTop: "none",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.175)",
    listStyle: "none",
    textAlign: "left",
    borderLeft: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    borderBottom: "1px solid #ddd",
    borderRadius: "3px",
    backgroundClip: "padding-box",
    backgroundColor: "rgba(255, 255, 255, 0.8)",

    "& .MuiMenu-list": {
      padding: "0 !important",
      width: "100% !important",
    },

    "& .MuiMenuItem-root": {
      clear: "both",
      width: "100%",
      border: 0,
      cursor: "pointer",
      margin: 0,
      display: "block",
      padding: 0,
      textAlign: "inherit",
      fontWeight: 400,
      whiteSpace: "nowrap",

      "&:hover": {
        color: "#16181b",
        backgroundColor: "rgba(233, 236, 239, 0.8)",
      },
    },

    "& .MuiFormControlLabel-root": {
      margin: 0,
      display: "block",
      padding: "4px 10px",
    },

    "& .MuiRadio-root": {
      padding: 0,
    },

    "& .MuiSvgIcon-root": {
      fontSize: "16px",
    },

    "& .MuiFormControlLabel-label": {
      margin: "0 0 0 5px",
      fontSize: "16px",
      fontFamily: "Noto Sans",
    },
  },
})((props) => (
  <Menu
    keepMounted
    disableScrollLock={true}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const Button2 = withStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: "0px",
    },
    outline: "none !important",

    width: "100%",
    height: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "2px",
    position: "relative",
    border: "1px solid #cccccc",
    margin: "0px",
    padding: "3px 0px 1px",
    "&.Mui-disabled": {
      backgroundColor: "#cccccc",
    },
  },
  endIcon: {
    marginLeft: "2px",
  },
}))(Button);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#calendar-data-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const textRenderer = (value) => {
  let strVal = `${value}`;
  let element = document.createElement("div");
  element.title = strVal;
  element.innerHTML = strVal;
  element.style.overflow = "hidden";
  element.style.textOverflow = "ellipsis";
  return element;
};

const gridComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  let strA = isEmpty(valueA) ? "" : valueA.toString();
  strA = strA.toLowerCase();
  let strB = isEmpty(valueB) ? "" : valueB.toString();
  strB = strB.toLowerCase();

  return strA.localeCompare(strB, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

let r_user;
let apiCallTime;
function ReductionEditDlg(props) {
  const s_header = headerStyles();
  const s_content = contentStyles();
  const { t } = useTranslation();

  const search = t("ReductionEditDlg.search");
  const material_code_txt = t("ReductionEditDlg.material_code");
  const material_name_txt = t("ReductionEditDlg.material_name");
  const plastic_code_txt = t("ReductionEditDlg.plastic_code");
  const plastic_type_txt = t("ReductionEditDlg.plastic_type");
  const plastic_edit = t("ReductionEditDlg.plastic_type_edit");

  const material_list_txt = t("ReductionEditDlg.material_list");
  const plastic_list_txt = t("ReductionEditDlg.plastic_list");

  const infancy_txt = t("ReductionEditDlg.infancy");
  const reduction_txt = t("ReductionEditDlg.reduction");
  const contents_txt = t("ReductionEditDlg.contents");
  const contents_placeholder = t("ReductionEditDlg.contents_placeholder");

  const loading_txt = t("ReductionEditDlg.loading");

  const message_material = t("ReductionEditDlg.msg.material");
  const message_plastic = t("ReductionEditDlg.msg.plastic");
  const message_weight = t("ReductionEditDlg.msg.weight");
  const message_infancy = t("ReductionEditDlg.msg.infancy");
  const message_reduction = t("ReductionEditDlg.msg.reduction");
  const message_contents = t("ReductionEditDlg.msg.contents");
  const message_contents_placeholder = t(
    "ReductionEditDlg.msg.contents_placeholder"
  );
  const message_save = t("ReductionEditDlg.msg.save");

  const [selectedYear, setSelectedYear] = useState(undefined);
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedDay, setSelectedDay] = useState(undefined);

  const [yearData, setYearData] = useState([]);
  const monthData = Array.from({ length: 12 }, (_, index) =>
    `${index + 1}`.padStart(2, 0)
  );
  const dayData = Array.from({ length: 31 }, (_, index) =>
    `${index + 1}`.padStart(2, 0)
  );

  const [yearMenuRef, setYearMenuRef] = useState(undefined);
  const yearMenuOpen = Boolean(yearMenuRef);
  const [monthMenuRef, setMonthMenuRef] = useState(undefined);
  const monthMenuOpen = Boolean(monthMenuRef);
  const [dayMenuRef, setDayMenuRef] = useState(undefined);
  const dayMenuOpen = Boolean(dayMenuRef);

  const [searchMaterial, setSearchMaterial] = useState(undefined);
  const [searchPlasticType, setSearchPlasticType] = useState(undefined);

  const [infancyWeight, setInfancyWeight] = useState("");
  const [reductionWeight, setReductionWeight] = useState("");

  const [selectedMaterial, setSelectedMaterial] = useState(undefined);
  const [selectedPlasticType, setSelectedPlasticType] = useState(undefined);
  const [selectedPlasticWeight, setSelectedPlasticWeight] = useState(undefined);

  const [contents, setContents] = useState(undefined);

  const [materialList, setMaterialList] = useState([]);
  const [plasticList, setPlasticList] = useState([]);

  const [materialGridApi, setMaterialGridApi] = useState(undefined);
  const [plasticGridApi, setPlasticGridApi] = useState(undefined);

  const [isLoding, setIsLoding] = useState(false);

  const [openPlasticListDlg, setOpenPlasticListDlg] = useState(false);
  const plasticListDlgCloseName = t("MessageBox.close");

  const [openMessageDlg, setOpenMessageDlg] = useState(false);
  const [messageDlgTitle, setMessageDlgTitle] = useState("");
  const [messageDlgContent, setMessageDlgContent] = useState("");
  const messageDlgCloseName = t("MessageBox.confirm");

  const [openConfirmDlg, setOpenConfirmDlg] = useState(false);
  const [confirmDlgTitle, setConfirmDlgTitle] = useState("");
  const [confirmDlgContent, setConfirmDlgContent] = useState("");
  const confirmDlgOkName = t("MessageBox.yes");
  const confirmDlgCancelName = t("MessageBox.no");
  const confirmDlgCloseName = t("MessageBox.close");

  const materialColumnDefs = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: false,
      },
      {
        headerName: material_code_txt,
        headerTooltip: material_code_txt,
        field: "material_code",
        width: 90,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => {
          if (props.selected)
            if (params.value === props.selected.material_code)
              params.node.setSelected(true);

          return textRenderer(params.value);
        },
      },
      {
        headerName: material_name_txt,
        headerTooltip: material_name_txt,
        field: "material_name",
        width: 278,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.value),
      },
    ],
    []
  );

  const plasticColumnDef = useMemo(
    () => [
      {
        headerName: "",
        field: "Check",
        width: 50,
        sortable: false,
        resizable: true,
        checkboxSelection: true,
        headerCheckboxSelection: false,
      },
      {
        headerName: plastic_code_txt,
        headerTooltip: plastic_code_txt,
        field: "plastic_code",
        width: 120,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => {
          if (props.selected)
            if (params.value === props.selected.plastic_code)
              params.node.setSelected(true);

          return textRenderer(params.value);
        },
      },
      {
        headerName: plastic_type_txt,
        headerTooltip: plastic_type_txt,
        field: "plastic_type",
        width: 260,
        sortable: true,
        resizable: true,
        filter: false,
        comparator: gridComparator,
        cellRenderer: (params) => textRenderer(params.value),
      },
    ],
    []
  );

  r_user = useSelector((state) => state.user, shallowEqual);

  useEffect(() => {
    const startDate = moment("2009-06-01");
    const nowDate = moment();
    const startYear = parseInt(startDate.format("YYYY"));
    const nowYear = parseInt(nowDate.format("YYYY"));

    const newYearData = [nowYear + 1];
    for (let loop1 = 0; loop1 <= nowYear - startYear; loop1++) {
      newYearData.push(nowYear - loop1);
    }

    setYearData(newYearData);
    if (props.selected) {
      setSelectedMaterial({
        material_code: props.selected.material_code,
        material_name: props.selected.material_name,
      });
      setSelectedPlasticType({
        plastic_code: props.selected.plastic_code,
        plastic_type: props.selected.plastic_type,
      });
    }

    if (props.title) {
      const datetime = props.title.split("-");
      setSelectedYear(datetime[0]);
      setSelectedMonth(datetime[1]);
      setSelectedDay(datetime[2]);
      return;
    }

    setSelectedYear(nowYear);
    setSelectedMonth(nowDate.format("MM"));
    setSelectedDay(nowDate.format("DD"));
  }, []);

  const getMaterialList = (params) => {
    if (!params) return;

    const api = params.api;
    const apiPath = "v1/datamanagement/material";
    const queryData = {
      tokenUser: r_user,
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (success && res && res.data) {
        if (res.data.material_list) setMaterialList(res.data.material_list);

        setMaterialGridApi(api);
      }
      setIsLoding(false);
    });
  };

  const getPlasticList = ({ params, material, apply_date }) => {
    const api = params ? params.api : undefined;
    const runTime = new Date().getTime();
    const apiPath = "v1/datamanagement/plastic";
    const queryData = { tokenUser: r_user, material, apply_date };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime && !api) return setIsLoding(false);

      if (success && res && res.data) {
        if (Array.isArray(res.data.plastic_list))
          setPlasticList(res.data.plastic_list);

        const reduction_list = res.data.reduction_list;
        if (Array.isArray(reduction_list) && reduction_list.length > 0) {
          const plastic_weight = {};
          res.data.reduction_list.forEach((item) => {
            plastic_weight[item.plastic_type] = {};
            plastic_weight[item.plastic_type].infancy = item.infancy;
            plastic_weight[item.plastic_type].reduction = item.reduction;
            plastic_weight[item.plastic_type].contents = item.contents;
          });

          setSelectedPlasticWeight(plastic_weight);

          const plastic =
            material && material.plastic_type ? material : selectedPlasticType;
          if (Object.keys(plastic_weight).length && plastic) {
            const item = plastic_weight[plastic.plastic_type];
            if (item) {
              setInfancyWeight(item.infancy);
              setReductionWeight(item.reduction);
              setContents(item.contents ? item.contents : "");
            }
          }
        } else {
          setSelectedPlasticWeight(undefined);
          setInfancyWeight("");
          setReductionWeight("");
          setContents("");
        }

        if (params && !plasticGridApi) setPlasticGridApi(api);
      }
      setIsLoding(false);
    });
    apiCallTime = runTime;
  };

  const handleYear = (newValue) => {
    setYearMenuRef(undefined);
    setSelectedYear(newValue);
    if (selectedMaterial) {
      setTimeout(() =>
        getPlasticList({
          material: selectedMaterial,
          apply_date: `${newValue}-${selectedMonth}-${selectedDay}`,
        })
      );
    }
  };

  const handleMonth = (newValue) => {
    setMonthMenuRef(undefined);
    setSelectedMonth(newValue);
    if (selectedMaterial) {
      setTimeout(() =>
        getPlasticList({
          material: selectedMaterial,
          apply_date: `${selectedYear}-${newValue}-${selectedDay}`,
        })
      );
    }
  };

  const handleDay = (newValue) => {
    setDayMenuRef(undefined);
    setSelectedDay(newValue);
    if (selectedMaterial) {
      setTimeout(() =>
        getPlasticList({
          material: selectedMaterial,
          apply_date: `${selectedYear}-${selectedMonth}-${newValue}`,
        })
      );
    }
  };

  const handleSave = () => {
    if (!selectedMaterial || !selectedMaterial.material_code) {
      setMessageDlgContent(message_material);
      setOpenMessageDlg(true);
      return;
    }

    if (!selectedPlasticType) {
      setMessageDlgContent(message_plastic);
      setOpenMessageDlg(true);
      return;
    }

    const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;

    if (!infancyWeight || !weight_regexp.test(infancyWeight)) {
      setMessageDlgContent(message_infancy);
      setOpenMessageDlg(true);
      return;
    }

    if (reductionWeight || contents) {
      if (!reductionWeight || !weight_regexp.test(reductionWeight)) {
        setMessageDlgContent(message_reduction);
        setOpenMessageDlg(true);
        return;
      }

      if (!contents) {
        setMessageDlgContent(message_contents);
        setOpenMessageDlg(true);
        return;
      }
    }

    setConfirmDlgTitle(t("ReductionEditDlg.save"));
    setConfirmDlgContent(message_save);
    setOpenConfirmDlg(true);
  };

  const handleSaveOk = () => {
    const isSave = confirmDlgTitle === t("ReductionEditDlg.save");

    const data = {
      material_code: selectedMaterial.material_code,
      plastic_code: selectedPlasticType.plastic_code,
      infancy: parseFloat(infancyWeight),
      reduction: parseFloat(reductionWeight),
      apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      contents,
    };

    const runTime = new Date().getTime();
    const apiPath = `v1/datamanagement/${isSave ? "save" : "delete"}`;
    const queryData = {
      tokenUser: r_user,
      kindType: true,
      ...data,
      selected: [data],
    };

    setIsLoding(true);
    sendBackend(false, apiPath, queryData, (success, res) => {
      if (runTime !== apiCallTime) return setIsLoding(false);

      setIsLoding(false);
      setTimeout(() => {
        setOpenConfirmDlg(false);
        props.handleSave();
      });
    });

    apiCallTime = runTime;
  };

  const handleSaveCancel = () => {
    setOpenConfirmDlg(false);
  };

  const handleCancel = () => {
    props.handleCancel();
  };

  const onCloseMessageDlg = () => {
    setMessageDlgContent("");
    setOpenMessageDlg(false);
  };

  const handleMaterial = (e) => {
    if (e && e.target) {
      setSearchMaterial(e.target.value);
      if (materialGridApi) materialGridApi.setQuickFilter(e.target.value);
    }
  };

  const handlePlasticType = (e) => {
    if (e && e.target) setSearchPlasticType(e.target.value);
    if (plasticGridApi) plasticGridApi.setQuickFilter(e.target.value);
  };

  const handleInfancy = (e) => {
    const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    if (e && e.target && e.target.value) {
      const weight = e.target.value;
      if (!weight_regexp.test(weight)) {
        setMessageDlgContent(message_weight);
        setOpenMessageDlg(true);
        return;
      }
      setInfancyWeight(e.target.value);
    } else setInfancyWeight();
  };

  const handleReduction = (e) => {
    const weight_regexp = /(^\d+$)|(^\d{1,}.\d{0,2}$)/;
    if (e && e.target && e.target.value) {
      const weight = e.target.value;
      if (!weight_regexp.test(weight)) {
        setMessageDlgContent(message_weight);
        setOpenMessageDlg(true);
        return;
      }
      setReductionWeight(e.target.value);
    } else setReductionWeight();
  };

  const handleMaterialClicked = (e) => {
    const selected = e.node.isSelected();
    e.node.setSelected(!selected);

    if (!selected) setSelectedMaterial(e.node.data);
    else setSelectedMaterial(undefined);

    const material = !selected ? e.node.data : undefined;
    if (!material) {
      setInfancyWeight(0);
      setReductionWeight(0);
      setContents("");
    }
    setTimeout(() =>
      getPlasticList({
        material,
        apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      })
    );
  };

  const handleMaterialSelection = (e) => {
    const selectedRows = e.api.getSelectedRows();

    const material = selectedRows.length > 0 ? selectedRows[0]:undefined
    setSelectedMaterial(material);

    if (!material) {
      setInfancyWeight(0);
      setReductionWeight(0);
      setContents("");
    }
    setTimeout(() =>
      getPlasticList({
        material,
        apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
      })
    );
  }

  const handlePlasticTypeClicked = (e) => {
    const selected = e.node.isSelected();
    e.node.setSelected(!selected);

    if (!selected) setSelectedPlasticType(e.node.data);
    else setSelectedPlasticType(undefined);

    const plastic = !selected ? e.node.data : undefined;
    if (plastic && selectedPlasticWeight) {
      if (selectedPlasticWeight[plastic.plastic_type]) {
        const infancy = selectedPlasticWeight[plastic.plastic_type].infancy;
        const reduction = selectedPlasticWeight[plastic.plastic_type].reduction;
        const _contents = selectedPlasticWeight[plastic.plastic_type].contents;

        setInfancyWeight(infancy ? infancy : "");
        setReductionWeight(reduction ? reduction : "");
        setContents(_contents ? _contents : "");
        return;
      }
    }

    setInfancyWeight("");
    setReductionWeight("");
    setContents("");
  };

  const handlePlasticTypeSelection = (e) => {
    const selectedRows = e.api.getSelectedRows();

    const plastic = selectedRows.length > 0 ? selectedRows[0]:undefined
    setSelectedPlasticType(plastic);

    if (plastic && selectedPlasticWeight) {
      if (selectedPlasticWeight[plastic.plastic_type]) {
        const infancy = selectedPlasticWeight[plastic.plastic_type].infancy;
        const reduction = selectedPlasticWeight[plastic.plastic_type].reduction;
        const _contents = selectedPlasticWeight[plastic.plastic_type].contents;

        setInfancyWeight(infancy ? infancy : "");
        setReductionWeight(reduction ? reduction : "");
        setContents(_contents ? _contents : "");
        return;
      }
    }

    setInfancyWeight("");
    setReductionWeight("");
    setContents("");
  }

  const handlePlasticListClose = () => {
    setOpenPlasticListDlg(false);
    setTimeout(() =>
      getPlasticList(
        getPlasticList({
          material: selectedMaterial,
          apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
        })
      )
    );
  };

  const handleDelete = () => {
    setConfirmDlgTitle(t("ReductionEditDlg.delete"));
    setConfirmDlgContent(t("ReductionEditDlg.msg.delete"));
    setOpenConfirmDlg(true);
  };

  const reductionRender = () => {
    return (
      <Grid item container direction="column" alignItems="stretch">
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          style={{ padding: "20px 10px 10px" }}
        >
          <Grid item>
            <Typography className={s_content.label}>
              {`${t("CollectionEditDlg.apply_date")}:`}
            </Typography>
          </Grid>
          <Grid item style={{ paddingLeft: "20px" }}>
            <Button2
              onClick={(e) => setYearMenuRef(e.currentTarget)}
              style={{
                width: "80px",
                height: "32px",
                backgroundColor: "#fff",
                borderRadius: "5px",
              }}
            >
              <span
                className="text-font"
                style={{
                  fontSize: "18px",
                  color: "#262626",
                  lineHeight: "0px",
                }}
              >
                {selectedYear}
              </span>
            </Button2>
          </Grid>
          <Grid item style={{ padding: "0px 10px" }}>
          <Typography className={s_content.label}>{`년`}</Typography>
          </Grid>
          <Grid item>
            <Button2
              onClick={(e) => setMonthMenuRef(e.currentTarget)}
              style={{
                width: "60px",
                height: "32px",
                backgroundColor: "#fff",
                borderRadius: "5px",
              }}
            >
              <span
                className="text-font"
                style={{
                  fontSize: "18px",
                  color: "#262626",
                  lineHeight: "0px",
                }}
              >
                {selectedMonth}
              </span>
            </Button2>
          </Grid>
          <Grid item style={{ padding: "0px 10px" }}>
          <Typography className={s_content.label}>{`월`}</Typography>
          </Grid>
          <Grid item>
            <Button2
              onClick={(e) => setDayMenuRef(e.currentTarget)}
              style={{
                width: "60px",
                height: "32px",
                backgroundColor: "#fff",
                borderRadius: "5px",
              }}
            >
              <span
                className="text-font"
                style={{
                  fontSize: "18px",
                  color: "#262626",
                  lineHeight: "0px",
                }}
              >
                {selectedDay}
              </span>
            </Button2>
          </Grid>
          <Grid item style={{ padding: "0px 10px" }}>
            <Typography className={s_content.label}>{`일`}</Typography>
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="stretch">
          <Grid item xs style={{ paddingTop: "10px", minWidth: "320px" }}>
            <Grid container direction="column" alignItems="stretch">
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ minHeight: "34px" }}
              >
                <Grid item style={{ minWidth: "120px", paddingLeft: "10px" }}>
                  <Typography className={s_content.label}>
                    {`${material_list_txt}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs style={{ padding: "6px 10px 0px"}}>
                <div className="search-layout">
                  <div className="input-group" style={{ marginBottom: '5px', borderRadius: "5px", overflow: "hidden" }}>
                    <input
                      type={"text"}
                      value={searchMaterial || ""}
                      className="form-control"
                      placeholder={search}
                      onChange={handleMaterial}
                      style={{borderRadius: "5px"}}                      
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs style={{ padding: "0px 10px", minHeight: "260px" }}>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    rowData={materialList}
                    rowStyle={{ cursor: "pointer" }}
                    rowSelection="single"
                    columnDefs={materialColumnDefs}
                    tooltipShowDelay={500}
                    headerHeight={40}
                    suppressMovableColumns="true"
                    suppressRowClickSelection="true"
                    enableCellTextSelection="true"
                    suppressCellSelection="true"
                    onGridReady={(params) => getMaterialList(params)}
                    localeText={{ noRowsToShow: " " }}
                    onColumnResized={(params) => {
                      params.api.resetRowHeights();
                    }}
                    onCellClicked={handleMaterialClicked}
                    onSelectionChanged={handleMaterialSelection}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ paddingTop: "10px", minWidth: "320px" }}>
            <Grid container direction="column" alignItems="stretch">
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                style={{ minHeight: "34px" }}
              >
                <Grid item style={{ minWidth: "120px", paddingLeft: "10px" }}>
                  <Typography className={s_content.label}>
                    {plastic_list_txt}
                  </Typography>
                </Grid>
                <Grid item style={{ paddingRight: "10px" }}>
                  <ColorButton onClick={() => setOpenPlasticListDlg(true)}>
                    <Typography style={{ fontSize: "16px" }}>
                      {plastic_edit}
                    </Typography>
                  </ColorButton>
                </Grid>
              </Grid>
              <Grid item xs style={{ padding: "6px 10px 0px" }}>
                <div className="search-layout">
                  <div className="input-group" style={{ marginBottom: '5px', borderRadius: "5px", overflow: "hidden" }}>
                    <input
                      type={"text"}
                      value={searchPlasticType || ""}
                      maxLength={100}
                      className="form-control"
                      placeholder={search}
                      onChange={handlePlasticType}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs style={{ padding: "0px 10px", minHeight: "260px" }}>
                <div
                  className="ag-theme-alpine"
                  style={{ height: "100%", width: "100%" }}
                >
                  <AgGridReact
                    rowData={plasticList}
                    rowStyle={{ cursor: "pointer" }}
                    rowSelection="single"
                    columnDefs={plasticColumnDef}
                    tooltipShowDelay={500}
                    headerHeight={40}
                    suppressMovableColumns="true"
                    suppressRowClickSelection="true"
                    enableCellTextSelection="true"
                    suppressCellSelection="true"
                    onGridReady={(params) =>
                      getPlasticList({
                        params,
                        material: props.selected,
                        apply_date: `${selectedYear}-${selectedMonth}-${selectedDay}`,
                      })
                    }
                    localeText={{ noRowsToShow: " " }}
                    onColumnResized={(params) => {
                      params.api.resetRowHeights();
                    }}
                    onCellClicked={handlePlasticTypeClicked}
                    onSelectionChanged={handlePlasticTypeSelection}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" alignItems="stretch">
          <Grid item style={{ padding: "0px 10px" }}>
            <Grid container direction="column">
              <Grid item style={{ padding: "20px 10px 0px" }}>
                <Typography className={s_content.label}>
                  {`${infancy_txt}`}
                </Typography>
              </Grid>
              <Grid item container direction="row" alignItems="center">
                <Grid item style={{ padding: "10px 10px 0px" }}>
                  <div className="search-layout" style={{ width: "200px", position: 'relative' }}>
                    <div className="input-group">
                      <input
                        type={"text"}
                        value={infancyWeight || ""}
                        disabled={
                          selectedMaterial && selectedPlasticType ? false : true
                        }
                        className="form-control"
                        maxLength={10}
                        style={{ textAlign: "right", paddingRight: '82px', borderRadius: "5px" }}
                        onChange={handleInfancy}
                      />
                      <span style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
                        g(gram)
                      </span>
                      <div style={{ position: 'absolute', right: '70px', top: '10%', width: '1px', height: '80%', backgroundColor: '#ccc', zIndex: 10 }}>                        
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid item style={{ padding: "8px 10px 0px" }}>
                <Typography className={s_content.label}>
                  {`${reduction_txt}`}
                </Typography>
              </Grid>
              <Grid item style={{ padding: "10px 10px 0px" }}>
                <div className="search-layout"  style={{ width: "200px", position: 'relative' }}>
                  <div className="input-group">
                    <input
                      type={"text"}
                      value={reductionWeight || ""}
                      disabled={
                        selectedMaterial && selectedPlasticType ? false : true
                      }
                      className="form-control"
                      maxLength={10}
                      style={{ textAlign: "right", paddingRight: '82px', borderRadius: "5px" }}
                      onChange={handleReduction}
                    />
                    <span style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', zIndex: 10 }}>
                      g(gram)
                    </span>
                    <div style={{ position: 'absolute', right: '70px', top: '10%', width: '1px', height: '80%', backgroundColor: '#ccc', zIndex: 10 }}>                      
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ padding: "10px 0px 0px" }}>
            <Grid container direction="column" alignItems="stretch">
              <Grid item style={{ padding: "10px 10px 0px" }}>
                <Typography className={s_content.label}>
                  {`${contents_txt}`}
                </Typography>
              </Grid>
              <Grid item style={{ padding: "10px 10px 0px" }}>
                <TextField1
                  multiline
                  value={contents || ""}
                  minRows={5}
                  maxRows={5}
                  inputProps={{ maxLength: 500 }}
                  disabled={
                    selectedMaterial && selectedPlasticType ? false : true
                  }
                  placeholder={contents_placeholder}
                  onChange={(e) => setContents(e.target.value)}
                  style={{ borderRadius: "5px" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      {openMessageDlg && (
        <MessageDialog
          isOpen={openMessageDlg}
          title={messageDlgTitle}
          content={messageDlgContent}
          closeName={messageDlgCloseName}
          onCloseMessageDlg={onCloseMessageDlg}
        />
      )}

      {openConfirmDlg && (
        <ConfirmDialog
          isOpen={openConfirmDlg}
          title={confirmDlgTitle}
          content={confirmDlgContent}
          okName={confirmDlgOkName}
          cancelName={confirmDlgCancelName}
          closeName={confirmDlgCloseName}
          onOkConfirmDlg={handleSaveOk}
          onCancelConfirmDlg={handleSaveCancel}
          onCloseConfirmDlg={handleSaveCancel}
        />
      )}

      {openPlasticListDlg && (
        <PlasticListDlg
          isOpen={openPlasticListDlg}
          title={plastic_edit}
          cancelName={plasticListDlgCloseName}
          handleClose={handlePlasticListClose}
        />
      )}

      {yearMenuOpen && (
        <StyledMenu
          anchorEl={yearMenuRef}
          open={yearMenuOpen}
          onClose={() => setYearMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="year-layout">
            {Array.isArray(yearData) &&
              yearData.map((value, _idx) => {
                return (
                  <div
                    key={`year-key-${_idx}`}
                    className="year-item"
                    onClick={() => handleYear(value)}
                  >
                    {value}
                  </div>
                );
              })}
          </div>
        </StyledMenu>
      )}
      {monthMenuOpen && (
        <StyledMenu
          anchorEl={monthMenuRef}
          open={monthMenuOpen}
          onClose={() => setMonthMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="month-layout">
            {monthData.map((value, _idx) => {
              return (
                <div
                  key={`month-key-${_idx}`}
                  className="month-item"
                  onClick={() => handleMonth(value)}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </StyledMenu>
      )}
      {dayMenuRef && (
        <StyledMenu
          anchorEl={dayMenuRef}
          open={dayMenuOpen}
          onClose={() => setDayMenuRef(undefined)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          disableScrollLock={true}
          style={{ marginTop: "0px" }}
        >
          <div className="day-layout">
            {dayData.map((value, _idx) => {
              return (
                <div
                  key={`day-key-${_idx}`}
                  className="day-item"
                  onClick={() => handleDay(value)}
                >
                  {value}
                </div>
              );
            })}
          </div>
        </StyledMenu>
      )}

      <Dialog
        // disableEscapeKeyDown
        maxWidth="md"
        fullWidth={true}
        open={props.isOpen}
        onClose={(event, reason) => {
          if (reason === "escapeKeyDown") {
            return;
          }
          handleCancel();
        }}
        PaperComponent={PaperComponent}
        aria-labelledby="calendar-data-dialog-title"
      >
        <DialogTitle
          className={s_header.titlebar}
          id="calendar-data-dialog-title"
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            style={{ height: "36px" }}
          >
            <Grid item xs={2}>
              <Typography className={s_header.title}>항목 추가</Typography>
            </Grid>
            <Grid item xs={8} container direction="row" alignItems="center">
              {/* {props.selected && (
                <Typography className={s_header.title}>
                  {props.title}
                </Typography>
              )} */}
            </Grid>
            <Grid
              item
              xs={2}
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <IconButton1
                size="small"
                aria-label="close"
                onClick={handleCancel}
                style={{ color: "#ffffff" }}
              >
                <CloseIcon />
              </IconButton1>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: "0px 10px 10px" }}
          >
            {reductionRender()}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {props.selected && (
                <Grid xs item>
                  <Button1
                    className={s_content.btn}
                    onClick={handleDelete}
                    style={{ marginLeft: "10px" }}
                  >
                    <Typography className={s_content.btn_label}>
                      {props.deleteName}
                    </Typography>
                  </Button1>
                </Grid>
              )}
              <Grid item style={{ padding: "10px 0px" }}>
                <Button1 
                  className={s_content.btn}
                  onClick={handleSave}
                  style={{backgroundColor: "#7EC5F8"}}
                >
                  <Typography className={s_content.btn_label}>
                    {props.okName}
                  </Typography>
                </Button1>
              </Grid>

              <Grid item style={{ padding: "10px 10px 10px 20px" }}>
                <Button1 className={s_content.btn} onClick={handleCancel}>
                  <Typography className={s_content.btn_label}>
                    {props.cancelName}
                  </Typography>
                </Button1>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Backdrop className={s_content.backdrop} open={isLoding}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <CircularProgress color="inherit" />
            </Grid>
            <Grid item style={{ marginTop: "10px" }}>
              <p>{loading_txt}</p>
            </Grid>
          </Grid>
        </Backdrop>
      </Dialog>
    </>
  );
}

export default ReductionEditDlg;
