import * as d3 from "d3";

import renderLines from "./renderLines";
import exportOrgChartPdf from "./exportOrgChartPdf";

import userSvg from "../../../../assets/user.svg";
import userLevelUpSvg from "../../../../assets/user_level_up.svg";
import waveSvg from "../../../../assets/wave.svg";

function render(config) {
  const {
    gNode,
    tree,
    treeRoot,
    sourceNode,
    nodeWidth,
    nodeHeight,
    nodeWSpacing,
    nodeHSpacing,
    nodeBorderWidth,
    nodeBorderRadius,
    animationDuration,
    onConfigChange,
    getCodeName,
    getKeepCount,
    onlyLeft,
    onlyRight,
    getChildrenPV,
    getScoreColor,
    getScoreDetail,
    onClickNode,
    downloadPdfId,
  } = config;

  const nodes = treeRoot.descendants().reverse();
  const links = treeRoot.links();

  config.links = links;

  tree(treeRoot);

  renderLines(config);

  const node = gNode.selectAll("g").data(nodes, (d) => d.id);

  const eventNode = sourceNode || treeRoot;

  const nodeEnter = node
    .enter()
    .append("g")
    .attr("class", "org-chart-node")
    .attr("transform", (d) => `translate(${eventNode.x0},${eventNode.y0})`)
    .on("click", onClickNode);

  const foreignObject = nodeEnter
    .append("foreignObject")
    .attr("class", "foreign-object-node")
    .attr("width", nodeWidth + nodeWSpacing)
    .attr("height", nodeHeight + nodeHSpacing)
    .attr("x", 0)
    .attr("y", -nodeHSpacing);

  foreignObject
    .append("xhtml:div")
    // .attr("id", (d) => `n-${d.id}`)
    .html((d) => {
      const person = d.data.person;
      let userImage = person.max_level_up > 0 ? userLevelUpSvg : userSvg;

      let cur_level = getCodeName(d, false);
      let max_level = getCodeName(d, true);
      let keepCount = getKeepCount(d);

      let left_pv = getChildrenPV(d, true);
      left_pv = left_pv.split(":");
      let left_title = `${left_pv[0]}: ${left_pv[1]}\n${left_pv[2]}: ${left_pv[3]}`;
      let left_style = "";
      if (onlyLeft && d.id === treeRoot.id) {
        left_style = `style="color: rgba(255, 255, 255, 0.3);"`;
      }

      let right_pv = getChildrenPV(d, false);
      right_pv = right_pv.split(":");
      let right_title = `${right_pv[0]}: ${right_pv[1]}\n${right_pv[2]}: ${right_pv[3]}`;
      let right_style = "";
      if (onlyRight && d.id === treeRoot.id) {
        right_style = `style="color: rgba(255, 255, 255, 0.3);"`;
      }

      let scoreColor = getScoreColor(d, "#c9c9c9");
      let scoreDetail = getScoreDetail(d);
      let scoreRender = "";
      if (scoreDetail) {
        scoreRender = `<div class="score-detail-layout">
            <div class="score-detail" style="color: ${scoreColor};">${scoreDetail}</div>
          </div>`;
      }

      let connect = person.connect;
      let waveRender = "";
      if (!connect && d.id !== treeRoot.id) {
        waveRender = `<div class="wave-image-layout">
            <image class="wave-image" src="${waveSvg}"></image>
          </div>`;
      }

      return `<div>
          ${waveRender}
          ${scoreRender}
          <div class="person-layout">
            <div class="top-layout">
              <image class="user-image" src="${userImage}"></image>
              <div class="title-layout">
                <div class="cust-no" title="${person.id}">${person.id}</div>
                <div class="cust-name" title="${person.cust_name}">${person.cust_name}</div>
              </div>
            </div>
            <div class="bottom-layout">
              <div class="basic-layout">
                <div class="cur-level" title="${cur_level}">${cur_level}</div>
                <div class="max-level" title="${max_level}">${max_level}</div>
                <div class="keep-count" title="${keepCount}">${keepCount}</div>
              </div>
              <div class="pv-layout">
                <div class="left-pv" ${left_style} title="${left_title}">
                  <div class="pv-item1">${left_pv[0]}</div>
                  <div class="pv-item2">${left_pv[1]}</div>
                  <div class="pv-item3">(${left_pv[3]})</div>
                </div>
                <div class="divider"></div>
                <div class="right-pv" ${right_style} title="${right_title}">
                  <div class="pv-item1">${right_pv[0]}</div>
                  <div class="pv-item2">${right_pv[1]}</div>
                  <div class="pv-item3">(${right_pv[3]})</div>
                </div>
              </div>
            </div>
          </div>
        </div>`;
    });

  nodeEnter
    .append("rect")
    .attr("id", (d) => `r-${d.id}`)
    .attr("class", "score-border")
    .attr("width", nodeWidth)
    .attr("height", nodeHeight)
    .attr("fill", "transparent")
    .attr("stroke", (d) => getScoreColor(d, "#c9c9c9"))
    .attr("stroke-width", nodeBorderWidth)
    .attr("rx", nodeBorderRadius)
    .attr("ry", nodeBorderRadius);

  node
    .merge(nodeEnter)
    .transition()
    .duration(animationDuration)
    .attr("transform", (d) => `translate(${d.x},${d.y})`);

  node
    .exit()
    .transition()
    .duration(animationDuration)
    .attr("transform", (d) => `translate(${eventNode.x},${eventNode.y})`)
    .remove();

  let viewLeft = 0;
  let viewRight = 0;
  let viewTop = 0;
  let viewBottom = 0;

  let nodeLeftX = -70;
  let nodeRightX = 70;
  let nodeY = 200;
  nodes.forEach((d) => {
    d.x0 = d.x;
    d.y0 = d.y;

    viewLeft = d.x < viewLeft ? d.x : viewLeft;
    viewRight = d.x + nodeWidth > viewRight ? d.x + nodeWidth : viewRight;
    viewTop = d.y < viewTop ? d.y : viewTop;
    viewBottom = d.y + nodeHeight > viewBottom ? d.y + nodeHeight : viewBottom;

    nodeLeftX = d.x < nodeLeftX ? d.x : nodeLeftX;
    nodeRightX = d.x > nodeRightX ? d.x : nodeRightX;
    nodeY = d.y > nodeY ? d.y : nodeY;
  });

  config.viewLeft = viewLeft;
  config.viewRight = viewRight;
  config.viewTop = viewTop;
  config.viewBottom = viewBottom;

  config.nodeLeftX = nodeLeftX * -1;
  config.nodeRightX = nodeRightX;
  config.nodeY = nodeY;

  d3.select(downloadPdfId).on("click", (e) => {
    exportOrgChartPdf(config);
  });
  onConfigChange(config);
}
export default render;
