import Handsontable from "handsontable";

export default class CalendarEditor extends Handsontable.editors.TextEditor {
  prepare(row, col, prop, td, value, cellProperties) {
    super.prepare(row, col, prop, td, value, cellProperties);
  }

  beginEditing(newInitialValue, event) {
    super.beginEditing(newInitialValue, event);
  }
}
